import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class VehicleOtherInfoStore {
  offlineDataNewObj = [];
  trackingDataNewObj = [];
  histTrackingClickPolylineObj = [];
  timeWisePolylineArray = [];
  offlineData = [];
  idleAndStopPointsArray = [];
  vehiclesHistData = [];
  vehicleSummaryData = [];
  vehiclesTriggerData = [];
  vehicleTrackingData = [];
  fuelRefillData = [];
  graphData = [];
  fuelRefillLatLong = [];

  totalCount = null;
  total = 0;

  pgcount = 1;
  offset = 0;
  postData = {};

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehiclesHistData: observable,
      offlineDataNewObj: observable,
      vehicleSummaryData: observable,
      offlineData: observable,
      vehiclesTriggerData: observable,
      trackingDataNewObj: observable,
      histTrackingClickPolylineObj: observable,
      idleAndStopPointsArray: observable,
      timeWisePolylineArray: observable,
      fuelRefillData: observable,
      pgcount: observable,
      offset: observable,
      vehicleTrackingData: observable,
      graphData: observable,
      fuelRefillLatLong: observable,
      handleOfflineData: action,
      setOfflineData: action,
      getHistoryTrackingData: action,
      getHistoryTrackingData2: action,
      getVehicleHistoryData: action,
      getVehicleRuleTriggerData: action,
      getFuelRefillData: action,
    });

    this.rootStore = rootStore;
  }

  async getVehicleHistoryData({ companyId, vehicleId, fromDate, toDate }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      offset: -1,
      limit: 50,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));

    var response = await postApi
      .getClient()
      .post(
        "index.php?c=dashboard&a=getVehicleHistory",
        "postData=" + base64Input
      );

    this.vehiclesHistData = response.data.data;
    this.vehicleSummaryData = response.data.data.summaryData;
    this.vehicleTripData = response.data.data.tripData;
    this.loading = false;

    return response.data.data;
  }

  async setOfflineData(data) {
    // this.vehicleId = data.vehicleId;
    if (data.resetOffset === 1) {
      this.offset = 0;
    }
    var postData = {
      ...data,
      limit: 500,
      page: this.pgcount,
      offset: this.offset,
      SelRoutOption: "3",
      TripEventdata: 1,
      tableName: "mt90",
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=offlinedata", "postData=" + base64Input);

    this.postData = data;
    let totalCount = response?.data?.data?.totalcount;
    this.offlineData = response?.data?.data;

    // this.historyTrackingTableData = this.offlineDataNewObj

    if (this.totalCount === null) {
      this.totalCount = totalCount;
    }

    if (
      this.totalCount >= 10 ||
      (this.totalCount < 10 && this.totalCount >= 0)
    ) {
      if (await this.offlineData.vehicleposition) {
        await this.offlineData.vehicleposition?.forEach((val) => {
          // this.offlineLatLongArray.push(val);
          this.offlineDataNewObj.push([val.latitude, val.longitude]);
        });

        await this.handleOfflineData();
      }
    } else if (this.totalCount === 0) {
      console.log("end");
    }
  }

  async handleOfflineData() {
    this.totalCount = this.totalCount - 500;
    this.pgcount = ++this.pgcount;
    this.offset = this.offset + 500;
    await this.setOfflineData(this.postData);
  }

  async getFuelRefillData({ companyId, vehicleId, fromDate, toDate }) {
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=fuelrefill", "postData=" + base64Input);

    this.fuelRefillData = response.data.data.fuelrefill.fuelrefill;

    // if (this.fuelRefillData) {
    //   Object.keys(this.fuelRefillData).map((e, i) => {
    //     let sub = {
    //       name: this.fuelRefillData[i].timestamp,
    //       uv: parseFloat(this.fuelRefillData[i].refillfuellevel),
    //       // pv: parseFloat(this.fuelRefillData[i].refillfuel),
    //     };
    //     let sub2 = [
    //       this.fuelRefillData[i].latitude,
    //       this.fuelRefillData[i].longitude,
    //     ];
    //     this.graphData.push(sub);
    //     this.fuelRefillLatLong.push(sub2);
    //   });
    // }

    return response?.data?.data?.fuelrefill;
  }

  async getHistoryTrackingData({ companyId, vehicleId, fromDate, toDate }) {
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      SelRoutOption: 3,
      TripEventdata: 1,
      tableName: "mt90",
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getHistoryTrackingData",
        "postData=" + base64Input
      );

    this.vehicleTrackingData = response.data.data.vehicleposition;

    if (this.vehicleTrackingData) {
      await this.vehicleTrackingData?.forEach((val) => {
        // this.offlineLatLongArray.push(val);
        this.histTrackingClickPolylineObj.push([val.latitude, val.longitude]);
      });
      this.offlineDataNewObj = this.histTrackingClickPolylineObj;
    }
    return response.data.data;
  }

  async getHistoryTrackingData2({ companyId, vehicleId, fromDate, toDate }) {
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,
      SelRoutOption: 3,
      TripEventdata: 1,
      tableName: "mt90",
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getHistoryTrackingData",
        "postData=" + base64Input
      );

    this.vehicleTrackingData = response.data.data.vehicleposition;
    if (this.vehicleTrackingData) {
      await this.vehicleTrackingData?.forEach((val) => {
        // this.offlineLatLongArray.push(val);
        if (
          val.activity === "Idle" ||
          (Number(val.acc === 1) && Number(val.speed) === 0)
        ) {
          // this.drawIdleAndStopIcon(val, "Orange");
          this.idleAndStopPointsArray.push({
            vehicleObj: val,
            activity: "Orange",
          });
        } else if (val.activity === "Stop" || Number(val.acc) === 0) {
          // this.drawIdleAndStopIcon(val, "Red");
          this.idleAndStopPointsArray.push({
            vehicleObj: val,
            activity: "Red",
          });
        }
        if (val.latitude && val.longitude) {
          this.timeWisePolylineArray.push([val.latitude, val.longitude]);
        }
      });
    }
    return this.vehicleTrackingData.length;
  }

  async getVehicleRuleTriggerData({ companyId, vehicleId, fromDate, toDate }) {
    var postData = {
      companyId: companyId,
      ruleSelectorId: [
        "1",
        "2",
        "5",
        "6",
        "4",
        "7",
        "10",
        "8",
        "15",
        "16",
        "18",
        "23",
        "25",
        "24",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      vehicleId: [vehicleId],
      fromDate: fromDate,
      toDate: toDate,
      offset: 0,
      limit: 15,
      page: 1,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=ruletrigger", "postData=" + base64Input);

    this.vehiclesTriggerData = response.data.data.ruletrigger;
    this.ruleCount = response.data.data.TotalCount;
    return response.data.data;
  }
}

export default VehicleOtherInfoStore;
