import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Col,
  message,
  DatePicker,
  Radio,
  Form,
  Input,
  Row,
  Select,
  Upload,
  Tree,
  Space,
  Spin,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { makeObservable, observable, computed, action, flow } from "mobx";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import CompanyAutocomplete from "./CompanyAutocomplete";
import withRouter from "../withRouter";
import MenuSettingStore from "../store/MenuSettingStore";
import { Link } from "react-router-dom";
import config from "../config";
import CompanyStore from "../store/CompanyStore";
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

class MenuSettingAdd extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = {
      loading: false,
      responseMsg: "",
      checkedKeys: [],
      modules: [],
      update: 0,
      companyId: "",
      loadList: 0,
      offset: "",
      limit: "",
      searchField: "",
      roleId: -1,
      selectedModuleIds: [],
      newObjArray: [],
      newSelectedKeysArray: [],
      loadingSpin: false,
    };
    this.menuSettingStore = new MenuSettingStore(this);
    this.companyStore = new CompanyStore(this);
    this.findChild = this.findChild.bind(this);
  }

  onMenuSettingSubmit = async (fieldsValue) => {
    this.setState({ loading: true });
    const data = {
      ...fieldsValue,
      roleId: this.state.roleId,
      companyId: this.state.companyId,
      permission: this.state.checkedKeys,
    };

    const response = await this.menuSettingStore.addData(data);
    await this.props.response(response);

    this.setState({ loading: false });
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setMenuSettingStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin });
    }
    const roleId = this.props.roleId;
    const modules = await this.menuSettingStore.getModules(roleId);
    console.log(modules);
    this.setState({
      modules: modules.modules,
      checkedKeys: modules.checkedKeys,
    });
    if (roleId > 0) {
      await this.setState({ loadingSpin: true });
      const singleData = await this.companyStore.getCompanyDataById(
        modules.roleData.companyId
      );

      this.setState({ roleId: roleId, companyId: modules.roleData.companyId });

      this.formRef.current.setFieldsValue({
        rolename: modules.roleData.name,
        companyId: singleData,
      });
      console.log("call");
      await this.setState({ loadingSpin: false });
    }
  }
  async findChild(checkedKey, checked, modules) {
    //console.log(checkedKey)
    //console.log(modules)
    modules.forEach((data) => {
      if (data.key == checkedKey) {
        data.children.forEach((children) => {
          if (this.state.checkedKeys.includes(data.key)) {
            //add children checked
            if (!this.state.checkedKeys.includes(children.key)) {
              const newKeys = this.state.checkedKeys;
              newKeys.push(children.key);
              this.setState({ checkedKeys: newKeys });
              //console.log(this.state.checkedKeys)
              if (children.children.length > 0) {
                console.log(children.key);
                console.log(children);
                const arr = [];
                arr.push(children);
                this.findChild(children.key, checked, arr);
              }
            }
          } else {
            //remove children checked
            const newKeys2 = this.state.checkedKeys;
            const index2 = newKeys2.indexOf(children.key);
            // console.log(children.key)
            if (index2 != -1) {
              const x = newKeys2.splice(index2, 1);
              this.setState({ checkedKeys: newKeys2 });
            }
            //const x = newKeys2.splice(index2, 1);
            //this.setState({checkedKeys:newKeys})
          }
        });
      }
      if (data.children.length > 0) {
        //const children2 = [];
        //children2.push(children);
        this.findChild(checkedKey, checked, data.children);
      }
    });

    //return moduleIds;
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    if (!this.props.params.roleId) {
      this.context.viewStateStore.setMenuSettingStates({
        companyId: this.state.companyId,
      });
    }
    await this.formRef.current.setFieldsValue({ companyId: comp.value });
    await this.handleSearch();
  }

  async handleSearch() {
    await this.menuSettingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }
  onCheck = async (checkedKeysValue, e) => {
    let newCheckedKeys = [...checkedKeysValue.checked];

    if (!e.checked) {
      const removeChildren = (childNodes) => {
        childNodes.forEach((child) => {
          newCheckedKeys = newCheckedKeys.filter((key) => key !== child.key);
          if (child.children) {
            removeChildren(child.children);
          }
        });
      };

      if (e.node.children) {
        removeChildren(e.node.children);
      }
    }

    await this.setState({ checkedKeys: newCheckedKeys });

    let checkedKey = e.node.key;
    await this.findChild(checkedKey, e.checked, this.state.modules);
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <Form
            ref={this.formRef}
            // {...layout}
            name="nest-messages"
            layout="vertical"
            onFinish={this.onMenuSettingSubmit}
            style={{ maxWidth: "100%", padding: 0 }}
            validateMessages={validateMessages}
          >
            <Row className="overflowFormInputs">
              <Col span={12} className="formColumnClass">
                {(this.props.getUserData().isadmin === -1 ||
                  this.props.getUserData().isadmin === 3) && (
                  <Form.Item
                    name="companyId"
                    // label="Account"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_CUSTOMER_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      // allowClear
                      onChange={(value) => this.handleChange(value)}
                      style={{ width: "100%" }}
                      companyId={this.state.companyId}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="rolename"
                  // label="Role Name"
                  label={this.context.translate(
                    "_FLEET_ROLE_ROLELIST_ROLENAME_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12} className="formColumnClass">
                {/* <h4>Module Name</h4> */}
                <h4>
                  {this.context.translate("_FLEET_ROLE_ROLEDETAIL_MODULENAME_")}
                </h4>
                <Tree
                  checkable
                  // defaultExpandedKeys={this.state.checkedKeys}
                  // defaultSelectedKeys={this.state.checkedKeys}
                  // defaultCheckedKeys={this.state.checkedKeys}
                  // onClick={this.onCheck}
                  onCheck={this.onCheck}
                  checkedKeys={this.state.checkedKeys}
                  treeData={this.state.modules}
                  defaultExpandParent={true}
                  checkStrictly={true}
                />
              </Col>
              {/* </Row> */}
            </Row>
            <Space className="formBtnGroup">
              {/* <Link to="/companymenusetting">
                {" "}
                <Button ghost type="primary">
                  Back
                </Button>
              </Link> */}

              <Button
                className="formCancelButton"
                onClick={(e) => {
                  this.closeModal();
                  // this.formRef.current?.resetFields();
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_CLOSE_BTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </Space>
          </Form>
        </Spin>
      </div>
    );
  }
}
MenuSettingAdd.contextType = StoreContext;
export default observer(withRouter(MenuSettingAdd));
