import dayjs from "dayjs";
import React, { PureComponent } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  Bar,
  LineChart,
  ReferenceLine,
  Scatter,
  Cell,
} from "recharts";

export default class Example extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const fuelGraphData = this.props?.graphData?.map((item) => {
      return {
        name: dayjs(item.timestamp).format("MM-DD HH:mm:ss"),
        refillfuel: parseInt(item.refillfuel ? item.refillfuel : 0),
        fuel: parseFloat(item.fuel ? item.fuel : 0),
      };
    });

    const fuelGraphLineData = this.props.fuelGraphData.map((item) => {
      return {
        name: item.timestamp,
        fuel: parseFloat(item.fuel ? item.fuel : 0),
      };
    });

    return (
      <ResponsiveContainer height={200}>
        <ComposedChart
          width={500}
          height={400}
          data={fuelGraphData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          // onClick={(val) => {
          //   console.log(val, "val");
          //   this.props.mapRef.current.setView(
          //     [
          //       val.activePayload[0]?.payload?.latitude,
          //       val.activePayload[0]?.payload?.longitude,
          //     ],
          //     16
          //   );
          // }}
        >
          <Tooltip />

          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="refillfuel" barSize={50} fill="green">
            {/* {this.props?.graphData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.name === this.props.selectedFuelData?.timestamp
                    ? "red"
                    : "green"
                }
              />
            ))} */}
          </Bar>

          <Line
            type="monotone"
            dataKey="fuel"
            stroke="#8884d8"
            strokeWidth={4}
            // dot={<this.CustomizedDot categoryColors={props.categoryColors} />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
