export default {
  _FLEET_VEHICLE_HISTORY_: "تفاصيل سجل الأصولمركبة ",
  _FLEET_SHOW_BTN_: " عرض",
  _FLEET_CLEAR_BTN_: "مسح",
  _FLEET_CLOSE_BTN_: " اغلاق",
  _FLEET_RESET_BTN_: "إعادة تعيين",
  _FLEET_REPORT_EVENT_: "تنبيه/الحدث",
  _FLEET_REPORT_EVENT_NO_: "رقم التنبيه/الحدث",
  _FLEET_ALL_EVENT_: "كل التنبيهات/الحداث",
  _FLEET_START_EVENT_: "ابدأ التنبيه/الحدث",
  _FLEET_STOP_EVENT_: "توقف التنبيه/الحدث",
  _FLEET_START_TIME_MIN: "بداية الوقت (دقيقة)",
  _FLEET_STOP_TIME_MIN: "وقت  التوقف (دقيقة)",
  _FLEET_START_TIME_: "وقت البدء",
  _FLEET_STOP_TIME_: "وقت  التوقف",
  _FLEET_ACC_EVENT_: "تشغيل / إيقاف تنبيه(الحدث) للمحرك",
  _FLEET_STARTSTOP_EVENT_: "بدء /توقف",
  _FLEET_OTHER_EVENT_: "تنبيه/الحدث آخرى",
  _FLEET_SPEEDTIME_EVENT_: "السرعة والوقت",
  _FLEET_RECORD_DISPLAYMSG_: " عرض السجلات {0} - {1} من {2}",
  _FLEET_RECORD_EMPTYMSG_: "لا يوجد سجلات لعرضها",
  _FLEET_EVENT_REPORT_: "عرض التنبيه على الخريطة",
  _FLEET_RULETRIGGER_REPORT_: "الزناد القاعدة على الخريطة",
  _FLEET_GEOFENCE_REPORT_: "السياجات الجغرافية على الخريطة",
  _FLEET_CHAT_REPORT_: "موقع مركبةالأصول",
  _FLEET_CHAT_ALERT_: "موقع مركبةالأصول غير متوفر",
  _FLEET_LOGIN_SETLOAD_: "تسجيل الدخول",
  _FLEET_LOGIN_LANGUAGE_: "لغة",
  _FLEET_LOGIN_LANGUAGEDDL_ENGLISH_: "الإنجليزية",
  _FLEET_LOGIN_LOGINBTNDDL_SPANISH_: "الأسبانية",
  _FLEET_LOGIN_LOGINBTN_: "تسجيل الدخول",
  _FLEET_LOGIN_FRWDBTN_: " نسيت كلمة المرور",
  _FLEET_LOGIN_EMAILLBL_: " البريد الإلكتروني",
  _FLEET_LOGIN_PASSWORDLBL_: "كلمه السر",
  _FLEET_LOGIN_INVALIDERROR_: "اسم المستخدم أو كلمة المرور غير صحيحة",
  _FLEET_ALERT_: "تنبيه",
  _FLEET_VEHICLEPOSITION_ON_: "تشغيل",
  _FLEET_VEHICLEPOSITION_OFF_: "إيقاف",
  _FLEET_VEHICLEPOSITION_VALID_: "صالح",
  _FLEET_VEHICLEPOSITION_INVALID_: "غير صالح",
  _FLEET_VEHICLEPOSITION_OPEN_: "افتح",
  _FLEET_VEHICLEPOSITION_CLOSE_: " اغلاق",
  _FLEET_VEHICLEPOSITION_NA_: "غير متوفر",
  _FLEET_WELCOME_: "مرحباً",
  _REFUELS_: "refuels",
  _FLEET_LOGOUT_: "تسجيل الخروج",
  _FLEET_REQUIRED_ERROR_: "هذه الخانة مطلوبه",
  _FLEET_TELEPHONE_ERROR_:
    "ليس هاتف صالح يجب أن يكون. (123-123-1234 أو 123-4567 أو 1234567896)",
  _FLEET_EMAIL_ERROR_:
    "ينبغي أن يكون هذا الحقل عنوان البريد الإلكتروني في الشكل user@example.com",
  //_FLEET_DASHBORD_RULETRIGGER_TITLE_: 'شاشة القاعدة الزناد',
  _FLEET_DASHBORD_RULETRIGGER_TITLE_: "الإشعارات",
  _FLEET_DASHBORD_RULETRIGGER_NAME_: "اسم القاعدة",
  _FLEET_DASHBORD_RULETRIGGER_VEHICLE_: " رقم المركبة",
  _FLEET_DASHBORD_RULETRIGGER_TIME_: "الوقت",
  _FLEET_DASHBORD_RULETRIGGER_DELETE_: "حذف",
  _FLEET_DASHBORD_NAVIRIGHT_NEWPOI_: "نقطة الاهتمام الجديدة",
  _FLEET_DASHBORD_NEWPOI_TITLE_: "إنشاء نقطة الأهتمام",
  _FLEET_DASHBORD_NEWPOI_COMPANY_: "حساب",
  // _FLEET_DASHBORD_NEWPOI_COMPANY_: 'شركة',
  _FLEET_DASHBORD_NEWPOI_NAME_: "الاسم",
  _FLEET_CUSTOMER_: "العميل",
  _FLEET_DASHBORD_NEWPOI_CUSTOMER_: "حساب",
  _FLEET_DASHBORD_NEWPOI_ADDRESS_: "عنوان",
  _FLEET_DASHBORD_NEWPOI_TYPE_: "نوع",
  // _FLEET_DASHBORD_NEWPOI_TYPEDDL_CUSTOMER_: 'العميل',
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_CUSTOMER_: " حساب",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_FUEL_: " محطة بنزين",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_HOUSE_: "منزل",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_SHOP_: "متجر",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_: "مستودع",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_CEDIS_: "CEDIS",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_AIRPORT_: "مطار",
  _FLEET_DASHBORD_NEWPOI_PHONE_: "رقم الهاتف",
  _FLEET_DASHBORD_NEWPOI_PHOTO_: " إرفع الصورة",
  _FLEET_DASHBORD_NEWPOI_SAVEBTN_: " حفظ",
  _FLEET_DASHBORD_NEWPOI_REMOVEBTN_: "إزالة نقطة الاهتمام",
  _FLEET_DASHBORD_NEWPOI_REMOVETITLE_: "إزالة نقطة الاهتمام؟",
  _FLEET_DASHBORD_NEWPOI_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_DASHBORD_NEWPOI_REMOVEYES_: "نعم فعلا",
  _FLEET_DASHBORD_NEWPOI_SAVEMSG_: "يتم حفظ الملف",
  _FLEET_DASHBORD_NEWPOI_COMPMSG_: "هذا مجال مطلوب",
  _FLEET_DASHBORD_NEWPOI_NAMEMSG_: "هذا مجال مطلوب",
  _FLEET_DASHBORD_NEWPOI_ERRORMSG_:
    "الامتدادات المسموح بها صورة PNG JPG JPEG BMP والحجم يصل 1MB",
  _FLEET_DASHBORD_NAVIRIGHT_SHOWPOI_: "عرض نقطة الاهتمام",
  _FLEET_DASHBORD_SHOWPOI_TITLE_: "عرض جميع نقاط الاهتمام",
  // _FLEET_DASHBORD_SHOWPOI_COMPANY_: 'شركة',
  _FLEET_DASHBORD_SHOWPOI_COMPANY_: " حساب",
  _FLEET_DASHBORD_SHOWPOI_COMPMSG_: "هذا مجال مطلوب.",
  _FLEET_DASHBORD_SHOWPOI_SHOWBTN_: " عرض نقطة الاهتمام",
  _FLEET_DASHBORD_SHOWPOI_CANCELBTN_: "إلغاء",
  _FLEET_DASHBORD_NAVIRIGHT_CLEARPOI_: "مسح نقطة الاهتمام",
  _FLEET_DASHBORD_NAVIRIGHT_SEARCHPOI_: "بحث نقطة الاهتمام",
  _FLEET_DASHBORD_SEARCHPOI_TITLE_: "بحث نقطة الاهتمام",
  //_FLEET_DASHBORD_SEARCHPOI_COMPANY_: 'شركة',
  _FLEET_DASHBORD_SEARCHPOI_COMPANY_: "حساب",
  _FLEET_DASHBORD_SEARCHPOI_NAME_: "اسم نقطة الاهتمام",
  _FLEET_DASHBORD_SEARCHPOI_COMPMSG_: "هذا مجال مطلوب.",
  _FLEET_DASHBORD_SEARCHPOI_NAMEMSG_: "هذا مجال مطلوب.",
  _FLEET_DASHBORD_SEARCHPOI_SEARCHBTN_: "بحث",
  _FLEET_DASHBORD_SEARCHPOI_CANCELBTN_: "إلغاء",
  _FLEET_DASHBORD_NAVIRIGHT_IMPORTPOI_: "الاستيراد والتصدير من XML",
  _FLEET_DASHBORD_IMPORTPOI_TITLE_: "استيراد نقطة الاهتمام",
  //_FLEET_DASHBORD_IMPORTPOI_COMPANY_: 'شركة',
  _FLEET_DASHBORD_IMPORTPOI_COMPANY_: "حساب",
  _FLEET_DASHBORD_IMPORTPOI_FILE_: "اختر ملف",
  _FLEET_DASHBORD_IMPORTPOI_UPLOADBTN_: "ارفع",
  _FLEET_DASHBORD_IMPORTPOI_CANCELBTN_: "إلغاء",
  _FLEET_DASHBORD_IMPORTPOI_SAVEMSG_: "يتم حفظ الملف",
  _FLEET_DASHBORD_IMPORTPOI_ERRORFILE_:
    "امتدادات الملفات المسموح بها هي kml و xml و حجم يصل 1mb ",
  _FLEET_DASHBORD_NAVIRIGHT_DROWGEO_: "جيوفينس/الرسم الجغرافي",
  _FLEET_DASHBORD_DROWGEO_TITLE_: "جيوفينس/الرسم الجغرافي",
  // _FLEET_DASHBORD_DROWGEO_COMPANY_: 'شركة',
  _FLEET_DASHBORD_DROWGEO_COMPANY_: "حساب",
  _FLEET_DASHBORD_DROWGEO_ADDRESS_: "عنوان",
  _FLEET_DASHBORD_DROWGEO_ADDRESSMSG_: "لم يتم العثور على العنوان",
  _FLEET_DASHBORD_DROWGEO_NAME_: "الاسم",
  _FLEET_DASHBORD_DROWGEO_DISCARDBTN_: "تجاهل وتكوين جديد",
  _FLEET_DASHBORD_DROWGEO_SAVEBTN_: "حفظ",
  _FLEET_DASHBORD_DROWGEO_CANCEL_: "إلغاء",
  _FLEET_DASHBORD_DROWGEO_LOADMSG_: "يتم حفظ السياجات الجغرافية ...",
  _FLEET_DASHBORD_DROWGEO_ALERTSTATUS_: "الحالة",
  _FLEET_DASHBORD_DROWGEO_ALERTMSG_: "تم حفظ السياج الجغرافي بنجاح",
  _FLEET_DASHBORD_DROWGEO_ERRORMSG_: "ملء جميع الحقول التي تحمل علامة حمراء",
  _FLEET_DASHBORD_NAVIRIGHT_SHOWGEO_: "العرض الجغرافي/جيوفينس",
  _FLEET_DASHBORD_SHOWGEO_TITLE_: "قائمة السياج الجغرافي ",
  // _FLEET_DASHBORD_SHOWGEO_COMPANY_: 'شركة',
  _FLEET_DASHBORD_SHOWGEO_COMPANY_: "حساب",
  _FLEET_DASHBORD_SHOWGEO_NAME_: "الاسم",
  _FLEET_DASHBORD_SHOWGEO_ADDRESS_: "عنوان",
  _FLEET_DASHBORD_SHOWGEO_DELETE_: "حذف السياجات الجغرافية",
  _FLEET_DASHBORD_SHOWGEO_REMOVETITLE_: "إزالة السياجات الجغرافية؟",
  _FLEET_DASHBORD_SHOWGEO_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_DASHBORD_SHOWGEO_REMOVEYESBTN_: "نعم فعلا",
  _FLEET_DASHBORD_SHOWGEO_REMOVELODING_:
    "يتم إزالة البيانات السياجات الجغرافية",
  _FLEET_DASHBORD_SHOWGEO_REMOVEALERT_: "تنبيه",
  _FLEET_DASHBORD_NAVIRIGHT_IMPORTGEO_: " الاستيراد والتصدير من </br> XML",
  _FLEET_DASHBORD_IMPORTGEO_TITLE_: " الاستيراد والتصدير من XML",
  // _FLEET_DASHBORD_IMPORTGEO_COMPANY_: 'شركة',
  _FLEET_DASHBORD_IMPORTGEO_COMPANY_: "حساب",
  _FLEET_DASHBORD_IMPORTGEO_FILE_: "اختر ملف",
  _FLEET_DASHBORD_IMPORTGEO_UPLOADBTN_: "إرفع",
  _FLEET_DASHBORD_IMPORTGEO_CANCELBTN_: " إلغاء",
  _FLEET_DASHBORD_IMPORTGEO_SAVEMSG_: "يتم حفظ الملف",
  _FLEET_DASHBORD_IMPORTGEO_ERRORMSG_:
    "امتدادات الملفات المسموح بها هي kml و xml و حجم يصل 1mb ",
  _FLEET_DASHBORD_NAVIRIGHT_SEARCHVEHICLE_: " البحث عن السيارة",
  _FLEET_DASHBORD_SEARCHVEHICLE_TITLE_: " البحث عن السيارة",
  // _FLEET_DASHBORD_SEARCHVEHICLE_COMPANY_: 'شركة',
  _FLEET_DASHBORD_SEARCHVEHICLE_COMPANY_: "حساب",
  _FLEET_DASHBORD_SEARCHVEHICLE_VEHICLE_: "مركبةالأصول",
  _FLEET_DASHBORD_SEARCHVEHICLE_SEARCHBTN_: "بحث",
  _FLEET_DASHBORD_SEARCHVEHICLE_CANCELBTN_: "إلغاء",
  _FLEET_DASHBORD_SEARCHVEHICLE_COMPMSG_: "هذا مجال مطلوب.",
  _FLEET_DASHBORD_SEARCHVEHICLE_VEHICLEMSG_: "هذا مجال مطلوب.",
  _FLEET_DASHBORD_NAVIRIGHT_SEARCHADDRESS_: "بحث عنوان",
  _FLEET_DASHBORD_SEARCHADDRESS_TITLE_: "بحث عنوان",
  _FLEET_DASHBORD_SEARCHADDRESS_ADDRESS_: "أدخل العنوان",
  _FLEET_DASHBORD_SEARCHADDRESS_ADDRESSMSG_: "هذا مجال مطلوب.",
  _FLEET_DASHBORD_SEARCHADDRESS_SEARCHBTN_: "بحث",
  _FLEET_DASHBORD_SEARCHADDRESS_CANCELBTN_: "إلغاء",
  _FLEET_DASHBORD_NAVIRIGHT_DISTMEASURE_: "قياس المسافة",
  _FLEET_DASHBORD_DISTMEASURE_TITLE_: "قياس المسافة",
  _FLEET_DASHBORD_DISTMEASURE_DISTCALCEBTN_: "احسب المسافة",
  _FLEET_DASHBORD_DISTMEASURE_CANCELBTN_: "إلغاء",
  _FLEET_DASHBORD_NAVIRIGHT_CREATEROUTE_: "إنشاء أو تعيين رحلة",
  _FLEET_DASHBORD_CREATEROUTE_ROUTELIST_: "قائمة الطريق",
  _FLEET_DASHBORD_ROUTELIST_NAME_: "الاسم",
  _FLEET_DASHBORD_ROUTELIST_DISCRIPTION_: "الوصف",
  _FLEET_DASHBORD_ROUTELIST_TIME_: "الوقت",
  _FLEET_DASHBORD_ROUTELIST_EMPTYTEXT_: "لا يوجد الدروب المتاحة",
  // _FLEET_DASHBORD_CREATEROUTE_TAB_COMPANY_: 'شركة',
  _FLEET_DASHBORD_CREATEROUTE_TAB_COMPANY_: " حساب",
  _FLEET_DASHBORD_CREATEROUTE_TAB_ADDROUTEBTN_: "إضافة الطريق",
  _FLEET_DASHBORD_CREATEROUTE_TAB_LISTBTN_: "قائمة",
  _FLEET_DASHBORD_ROUTEADD_SAVEROUTBTN_: "حفظ الطريق",
  _FLEET_CREATEROUTE_ROUTEADD_SAVEBTN_: "حفظ",
  _FLEET_CREATEROUTE_ROUTEADD_CREATEPLANBTN_: "إنشاء خطة",
  _FLEET_CREATEROUTE_ROUTEADD_ROUTENAME_: "اسم الطريق ",
  _FLEET_CREATEROUTE_ROUTEADD_ROUTEDESCRIPTION_: "الوصف الطريق",
  _FLEET_CREATEROUTE_ROUTEADD_ZONEORGANIZER_: "منظم المنطقة ",
  _FLEET_CREATEROUTE_ROUTEADD_DESCRIPTION_: "الوصف",
  _FLEET_CREATEROUTE_ROUTEADD_COMPANY_: "اختر  حساب",
  // _FLEET_CREATEROUTE_ROUTEADD_COMPANY_: 'اختر الشركة',
  _FLEET_CREATEROUTE_ROUTEADD_COMMENT_: "تعليق",
  _FLEET_CREATEROUTE_ROUTEADD_VEHICLE_: "مركبةالأصول",
  _FLEET_CREATEROUTE_ROUTEADD_SRTDATE_: "تاريخ البدء",
  _FLEET_CREATEROUTE_ROUTEADD_ROUTEORGANIZER_: "منظم الطريق ",
  _FLEET_CREATEROUTE_ROUTEADD_VEHCHKBXLBL_:
    "مركبةالأصول متوقفة هنا في بداية الطريق",
  _FLEET_CREATEROUTE_ROUTEADD_TOOLTIP_STARTTIME_:
    "حدد الوقت الذي ستبدأ فيه السيارة الطريق!",
  _FLEET_CREATEROUTE_ROUTEADD_TOOLTIP_DISTANCE_:
    "تحديد المسافة إلى الموقع من الموقع السابق!",
  _FLEET_CREATEROUTE_ROUTEADD_TOOLTIP_ESTIMATETIME_:
    "تحديد وقت التقديري للوصول إلى الموقع من الموقع السابق!",
  _FLEET_CREATEROUTE_ROUTEADD_TOOLTIP_ESTIMATETIMESPEND_:
    "حدد الوقت الذي ستبدأ فيه السيارة الطريق!",
  _FLEET_CREATEROUTE_ROUTEADD_TOTALDISTANCE_: "المسافة الكلية",
  _FLEET_CREATEROUTE_ROUTEADD_TOTALTIME_: "الوقت الأجمالي",
  _FLEET_CREATEROUTE_ROUTEADD_ALERTSTATUS_: " الحالة",
  _FLEET_CREATEROUTE_ROUTEADD_ALERTMSG_: " حفظ الطريق بنجاح",
  _FLEET_CREATEROUTE_ROUTEADD_ERRORMSG_: "لم يتم حفظ البيانات",
  _FLEET_DASHBORD_ROUTEADD_SUGGROUTBTN_: "أقترح الطريق",
  _FLEET_DASHBORD_ROUTEADD_CANCELBTN_: "إلغاء",
  _FLEET_DASHBORD_ROUTEADD_SUGGROUTE_SETROUTEBTN_: "تعيين الطريق",
  _FLEET_DASHBORD_ROUTEADD_ADDRESS_ERRORMSG_:
    "يرجى المحاولة بعد مرور بعض الوقت",
  _FLEET_DASHBORD_NAVIRIGHT_CREATECHAT_: "إنشاء الدردشة",
  _FLEET_DASHBORD_CREATECHAT_TITLE_: "دردشة",
  //  _FLEET_DASHBORD_CREATECHAT_COMPANY_: 'شركة',
  _FLEET_DASHBORD_CREATECHAT_COMPANY_: "حساب",
  _FLEET_DASHBORD_NAVIRIGHT_SETTING_TITLE_: "ضبط",
  _FLEET_DASHBORD_SETTING_STATUS_: " الحالة",
  _FLEET_DASHBORD_SETTING_VEHICLE_: "اسم المركبات / الاسم المستعار",
  _FLEET_DASHBORD_SETTING_PLATE_: "لوحات",
  _FLEET_DASHBORD_SETTING_IMEI_: "IMEI/رقم التعقب",
  _FLEET_DASHBORD_SETTING_DATE_: "التاريخ والوقت",
  _FLEET_DASHBORD_SETTING_ADDRESS_: "عنوان",
  _FLEET_DASHBORD_SETTING_SPEED_: " السرعة(الكيلومتر)",
  _FLEET_DASHBORD_SETTING_ODOMETER_: "عداد المسافات",
  _FLEET_DASHBORD_SETTING_HEADING_: "عنوان",
  _FLEET_DASHBORD_SETTING_INGNITION_: "حالة المحرك",
  _FLEET_DASHBORD_SETTING_EVENT_: "التنبيه/الحدث",
  _FLEET_DASHBORD_SETTING_TEMPERATURE_: "درجة الحرارة",
  _FLEET_DASHBORD_SETTING_FUELLEVEL_: "مستوى الوقود)٪(",
  _FLEET_DASHBORD_SETTING_FUELLEVEL1_: "(%)وقود المستوى 1",
  _FLEET_DASHBORD_SETTING_FUELLEVEL2_: "(%)وقود المستوى 2",
  _FLEET_DASHBORD_SETTING_FUELLEVEL3_: "(%)وقود المستوى 3",
  _FLEET_DASHBORD_SETTING_FUELVOLUME_: "حجم الوقود (لتر)",
  _FLEET_DASHBORD_SETTING_FUELVOLUME1_: "حجم الوقود 1 (لتر)",
  _FLEET_DASHBORD_SETTING_FUELVOLUME2_: "حجم الوقود 2 (لتر)",
  _FLEET_DASHBORD_SETTING_FUELVOLUM3_: "حجم الوقود 3 (لتر)",
  _FLEET_DASHBORD_SETTING_FUELVOLUME4_: "حجم الوقود 4 (لتر)",
  _FLEET_DASHBORD_SETTING_FUELVOLUME3_: "حجم الوقود 3 (لتر)",
  _FLEET_DASHBORD_SETTING_GPS_: "GPS الحالة",
  _FLEET_DASHBORD_SETTING_GSM_: "GSM الإشارة",
  _FLEET_DASHBORD_SETTING_BATTERY_: "البطارية",
  _FLEET_DASHBORD_SETTING_DOOR_: "باب",
  _FLEET_DASHBORD_SETTING_ACC_: "ACC",
  _FLEET_DASHBORD_SETTING_SEATBELT_: "حزام المقعد",
  _FLEET_DASHBORD_SETTING_AC_: "AC",
  _FLEET_DASHBORD_SETTING_PANIC_: "الهلع ",
  _FLEET_DASHBORD_SETTING_ANALOG_: "المدخلات التناظرية",
  _FLEET_DASHBORD_SETTING_LATITUDE_: "خط العرض",
  _FLEET_DASHBORD_SETTING_LONGITUDE_: "خط الطول",
  _FLEET_DASHBORD_SETTING_MESSAGE_: "رسالة",
  _FLEET_DASHBORD_SETTING_EXTERNAL_: "بيانات إنذار الخارجي",
  _FLEET_DASHBORD_SETTING_SELECTALLBTN_: "اختر الكل",
  _FLEET_DASHBORD_SETTING_DELETEALLBTN_: "الغاء تحديد الكل",
  _FLEET_DASHBORD_SETTING_SAVEBTN_: "حفظ",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_TITLE_: "تفاصيل معلومات مركبةالأصول",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_PLATENO_: "رقم اللوحة ",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_BRAND_: "العلامة التجارية",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_MODEL_: "نموذج",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_TYPE_: "نوع",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_YEAR_: "عام",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_IMEI_: "IMEI/رقم التعقب",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_ADDRESS_: "عنوان منسق",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_ODOMETER_: " عداد المسافات",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_: "اسم السائق",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_: "رقم الجوال",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_SPEED_: "سرعة كم / ساعة",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_UPDATE_: "اخر تحديث",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_STOPTIME_: "وقت  التوقف",
  _FLEET_DASHBORD_INFORMATION_DAY_: "أيام",
  _FLEET_DASHBORD_INFORMATION_HOUR_: "ساعة",
  _FLEET_DASHBORD_INFORMATION_MIN_: "الدقائق",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_EVENT_: "نوع التنبيه",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_LATITUDE_: "خط العرض",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_LONGI_: "خط الطول",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_SERVICE_: "تم آخر خدمة",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_OIL_: "آخر تغيير الزيت ",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_TIRE_: "آخر تغيير الإطارات",
  _FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_: " اسم الأصول",
  _FLEET_DASHBORD_VEHICLE_HISTORY_TITLE_: "تتبع مركبةالأصول دون اتصال",
  //_FLEET_DASHBORD_VEHICLE_HISTORY_COMPANY_: 'شركة',
  _FLEET_DASHBORD_VEHICLE_HISTORY_COMPANY_: " حساب",
  _FLEET_DASHBORD_VEHICLE_HISTORY_VEHICLE_: " رقم الأصول",
  _FLEET_DASHBORD_VEHICLE_HISTORY_FROM_: "من",
  _FLEET_DASHBORD_VEHICLE_HISTORY_TO_: "إلى",
  _FLEET_DASHBORD_VEHICLE_HISTORY_SHOWBTN_: "عرض",
  _FLEET_DASHBORD_VEHICLE_HISTORY_ERROR_: "هذا مجال مطلوب.",
  _FLEET_DASHBORD_VEHICLE_HISTORY_SETLOAD_: "جاري جلب البيانات",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_: "موقع",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_SPEED_: " السرعة(الكيلومتر)",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_: " السائق",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_MOBILE_: " رقم الجوال",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_LASTREPORT_: "آخر تاريخ  ذكرت",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_NODRIVER_: "لا يوجد سائق",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_NOMOBILE_: "لا يوجد جوال",
  _FLEET_DASHBORD_VEHICLE_HISTORY_ALERTMSG_: "هذه الميزة غير متوفرة.",
  _FLEET_DASHBORD_VEHICLE_STREET_ALERT_: "تنبيه",
  _FLEET_DASHBORD_VEHICLE_STREET_ALERTMSG_: "عرض الشارع غير متوفر لهذا الموقع.",
  _FLEET_DASHBORD_VEHICLE_STREET_ERROR_: "هذه الميزة غير متوفرة.",
  _FLEET_DASHBORD_VEHICLE_COMMAND_TITLE_: "الرجاء إدخال الأمر",
  _FLEET_DASHBORD_VEHICLE_COMMAND_SELECT_: "اختر  الأمر",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_ON_: "تشغيل منع الحركة",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_OFF_: "ايقاف منع الحركة ",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_POSITION_: "الحصول على الموقع",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_DOORLOCK_: "قفل الباب",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_DOORUNLOCK_: "فتح الباب ",
  _FLEET_DASHBORD_VEHICLE_COMMAND_ERRORMSG_: "هذا مجال مطلوب.",
  _FLEET_DASHBORD_VEHICLE_COMMAND_SENDBTN_: "إرسال",
  _FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_: "حسنا",
  _FLEET_DASHBORD_VEHICLE_COMMAND_CANCELBTN_: " إلغاء",
  _FLEET_DASHBORD_VEHICLE_COMMAND_SUCCESSMGS_: "تم إرسال الأمر إلى الجهاز",
  _FLEET_DASHBORD_VEHICLE_COMMAND_UNSUCCESSMGS_: "تعذر إرسال الأمر إلى الجهاز",
  _FLEET_DASHBORD_LEFT_VEHLIST_NEXTREFRESH_: "التحديث القادم",
  _FLEET_DASHBORD_LEFT_VEHLIST_SETREFRESH_: "تعيين تحديث",
  _FLEET_DASHBORD_LEFT_VEHLIST_DEFAULT_: "افتراضي",
  _FLEET_DASHBORD_LEFT_VEHLIST_TIMER_: "لضبط الموقت يرجى تحديد المركبات",
  _FLEET_DASHBORD_MAP_LOCK_: " قفل الأمان",
  _FLEET_DASHBORD_MAP_UNLOCK_: "فتح",
  _FLEET_DASHBORD_MAP_LOCK_TOOLTIP_: "انقر لقفل الخريطة",
  _FLEET_DASHBORD_MAP_PHOTO_: "الصور",
  _FLEET_DASHBORD_MAP_PHOTO_TOOLTIP_: "انقر لمشاهدة الصور",
  _FLEET_DASHBORD_MAP_WEATHER_: "طقس",
  _FLEET_DASHBORD_MAP_WEATHER_TOOLTIP_: "انقر لرؤية الطقس",
  _FLEET_DASHBORD_MAP_TRAFFIC_: "حركة المرور",
  _FLEET_DASHBORD_MAP_TRAFFIC_TOOLTIP_: "انقر لرؤية حركة المرور",
  _FLEET_DASHBORD_MAP_MAP_: "خريطة",
  _FLEET_DASHBORD_MAP_MAP_TOOLTIP_: "خريطة",
  _FLEET_DASHBORD_MAP_SATELLITE_: "الأقمار الصناعية",
  _FLEET_DASHBORD_MAP_SATELLITE_TOOLTIP_: "الأقمار الصناعية",
  _FLEET_MANAGEUSER_TAB_: "إدارة المستخدم",
  _FLEET_MANAGEUSER_COMPANY_TAB_: " حساب",
  // _FLEET_MANAGEUSER_COMPANY_TAB_: 'شركة',
  _FLEET_COMPANY_COMPANYLIST_: "قائمة الشركة",
  _FLEET_COMPANY_COMPANYLIST_ADDBTN_: "إضافة",
  _NEW_EXPIRY_DATE_: "تاريخ انتهاء الصلاحية الجديد",
  // _FLEET_COMPANY_COMPANYLIST_COMPANYNAME_: 'اسم الشركة',
  _FLEET_COMPANY_COMPANYLIST_COMPANYNAME_: " اسم الحساب",
  _FLEET_COMPANY_COMPANYLIST_PHONE_: "الاتصال عبر الهاتف",
  _FLEET_COMPANY_COMPANYLIST_EMAIL_: "تواصل بالبريد الاكتروني",
  _FLEET_COMPANY_COMPANYLIST_STATUS_: "الحالة",
  _FLEET_COMPANY_COMPANYLIST_EDIT_: "تحرير ",
  _FLEET_COMPANY_COMPANYLIST_DELETE_: "حذف",
  _FLEET_COMPANY_COMPANYLIST_REMOVETITLE_: "إزالة الشركة؟",
  _FLEET_COMPANY_COMPANYLIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_COMPANY_COMPANYLIST_YESBTN_: "نعم فعلا",
  _FLEET_COMPANY_COMPANYLIST_DELETELODING_: "يتم إزالة بيانات الشركة",
  // _FLEET_COMPANY_COMPANYDETAIL_TITLE_: 'تفاصيل الشركة',
  _FLEET_COMPANY_COMPANYDETAIL_TITLE_: "تفاصيل الحساب",
  _FLEET_COMPANY_COMPANYDETAIL_BACKBTN_: "العودة",
  _FLEET_COMPANY_COMPANYDETAIL_SAVEBTN_: "حفظ",
  _FLEET_COMPANY_COMPANYDETAIL_CANCELBTN_: "إلغاء",
  _FLEET_COMPANY_COMPANYDETAIL_COMPANYNAME_: "اسم الحساب",
  //  _FLEET_COMPANY_COMPANYDETAIL_COMPANYNAME_: 'اسم الشركة',
  _FLEET_COMPANY_COMPANYDETAIL_ADDRESS_: "عنوان الحساب",
  // _FLEET_COMPANY_COMPANYDETAIL_ADDRESS_: 'عنوان الشركة',
  _FLEET_COMPANY_COMPANYDETAIL_EMAIL_: "تواصل بالبريد الاكتروني",
  _FLEET_COMPANY_COMPANYDETAIL_PHONE_: "الاتصال عبر الهاتف",
  _FLEET_COMPANY_COMPANYDETAIL_CITY_: "مدينة",
  _FLEET_COMPANY_COMPANYDETAIL_STATE_: "حالة",
  _FLEET_COMPANY_COMPANYDETAIL_COUNTRY_: "بلد",
  _FLEET_COMPANY_COMPANYDETAIL_TIME_: "وحدة زمنية",
  _FLEET_COMPANY_COMPANYDETAIL_RFC_: "RFC",
  _FLEET_COMPANY_COMPANYDETAIL_STATUS_: "الحالة",
  _FLEET_COMPANY_COMPANYDETAIL_SERVER_: "سرفر SMTP",
  _FLEET_COMPANY_COMPANYDETAIL_PORT_: "منفذ SMTP",
  _FLEET_COMPANY_COMPANYDETAIL_SELECT_: "تحديد",
  _FLEET_COMPANY_COMPANYDETAIL_SMPTEMAIL_: "SMTP عنوان البريد الإلكتروني",
  _FLEET_COMPANY_COMPANYDETAIL_SMPTPASSWD_: "SMTP كلمة المرور",
  _FLEET_COMPANY_COMPANYDETAIL_EXPIREDATE_: "تاريخ انتهاء الحساب",
  _FLEET_COMPANY_COMPANYDETAIL_EXPIREMONTHS_: "أشهر انتهاء الحساب",

  _FLEET_COMPANY_COMPANYDETAIL_UPLOAD_: "ارفع شعار الشركة",
  //_FLEET_COMPANY_COMPANYDETAIL_LOGO_: 'شعار الشركة',
  _FLEET_COMPANY_COMPANYDETAIL_LOGO_: "(شعار حسا( 65 * 150",
  _FLEET_COMPANY_COMPANYDETAIL_NOLOGO_: "لا يوجد الشعار",
  _FLEET_COMPANY_COMPANYDETAIL_NOIMAGE_: "لا يوجد صورة",
  _FLEET_COMPANY_COMPANYDETAIL_ERRORMSG_:
    "يرجى تعبئة البيانات الصحيحة في الحقول التي يتم وضع علامة حمراء.",
  _FLEET_COMPANY_COMPANYDETAIL_ERRORCOMPANY_: "هذا اسم الشركة موجود بالفعل",
  // _FLEET_COMPANY_COMPANYDETAIL_ERRORPHOTO_: 'يرجى تحميل صورة صالحة. يجب أن تكون أبعاد الصورة 189 * 65',
  _FLEET_COMPANY_COMPANYDETAIL_ERRORPHOTO_:
    "يرجى تحميل صورة صالحة. يجب أن تكون أبعاد الصورة 65 * 150",
  _FLEET_COMPANY_COMPANYDETAIL_ERRORUSER_: "اسم المستخدم هذا موجود مسبقا",
  _FLEET_COMPANY_USERDETAIL_TITLE_: "بيانات المستخدم",
  _FLEET_COMPANY_USERDETAIL_NAME_: "الاسم",
  _FLEET_COMPANY_USERDETAIL_USERNAME_: "اسم المستخدم",
  _FLEET_COMPANY_USERDETAIL_PASSWORD_: "كلمه السر",
  _FLEET_COMPANY_USERDETAIL_REPASSWORD_: "اعادة ادخال كلمة السر",
  _FLEET_COMPANY_USERDETAIL_PASSWDNOTMATCH_: "كلمة المرور غير مطابقة",
  _FLEET_MANAGEUSER_USER_TAB_: "المستعمل",
  _FLEET_USER_USERLIST_TITLE_: "قائمة المستخدمين",
  // _FLEET_USER_USERLIST_COMPANYDDL_: 'شركة',
  _FLEET_USER_USERLIST_COMPANYDDL_: "حساب",
  _FLEET_USER_USERLIST_ADDBTN_: "إضافة",
  _FLEET_USER_USERLIST_NAME_: "الاسم",
  _FLEET_USER_USERLIST_ADMINUSER_: "المشرف المستخدم",
  _FLEET_USER_USERLIST_ADDRESS_: "عنوان",
  _FLEET_USER_USERLIST_MOBILENO_: "رقم الجوال",
  _FLEET_USER_USERLIST_EMAIL_: "عنوان الايميل",
  _FLEET_USER_USERLIST_USERNAME_: "اسم المستخدم",
  _FLEET_USER_USERLIST_STATUS_: "الحالة",
  _FLEET_USER_USERLIST_STATUS_ACTIVE_: "النشاط",
  _FLEET_USER_USERLIST_STATUS_INACTIVE_: "غير نشط",
  _FLEET_USER_USERLIST_EDIT_: "تحرير مستخدم",
  _FLEET_USER_USERLIST_VEHICLE_: "تعيين مركبات",
  _FLEET_USER_USERLIST_DELETE_: "حذف المستخدم",
  _FLEET_USER_USERLIST_REMOVETITLE_: "إزالةالمستخدم؟",
  _FLEET_USER_USERLIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_USER_USERLIST_YESBTN_: "نعم فعلا",
  _FLEET_USER_USERLIST_DELETELODING_: "يتم إزالة بيانات المستخدم",
  _FLEET_USER_USERLIST_ASSIGNVEHICLE_TITLE_: "تعيين مركبات",
  _FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_: "العودة",
  _FLEET_USER_USERLIST_ASSIGNVEHICLE_SAVEBTN_: "حفظ",
  _FLEET_USER_USERLIST_ASSIGNVEHICLE_SELECTVEHICLE_: "اختر مركبات",
  _FLEET_USER_USERDETAIL_TITLE_: "بيانات المستخدم",
  _FLEET_USER_USERDETAIL_BACKBTN_: "العودة",
  _FLEET_USER_USERDETAIL_SAVEBTN_: "حفظ",
  _FLEET_USER_USERDETAIL_CANCELBTN_: "إلغاء",
  //_FLEET_USER_USERDETAIL_COMPANY_: 'شركة',
  _FLEET_USER_USERDETAIL_COMPANY_: "حساب",
  _FLEET_USER_USERDETAIL_NAME_: "الاسم",
  _FLEET_USER_USERDETAIL_MOBILE_: "رقم الجوال",
  _FLEET_USER_USERDETAIL_EMAIL_: "عنوان الايميل",
  _FLEET_USER_USERDETAIL_ADDRESS_: "عنوان",
  _FLEET_USER_USERDETAIL_USERNAME_: "اسم المستخدم",
  _FLEET_USER_USERDETAIL_PASSWORD_: "كلمه السر",
  _FLEET_USER_USERDETAIL_REPASSWORD_: "اعادة ادخال كلمة السر",
  _FLEET_USER_USERDETAIL_PASSWDNOTMATCH_: "كلمة المرور غير مطابقة.",
  _FLEET_USER_USERDETAIL_STATUS_: " الحالة",
  _FLEET_USER_USERDETAIL_STATUSDDL_SELECT_: "اختر الحالة",
  _FLEET_USER_USERDETAIL_STATUSDDL_ACTIVE_: " النشاط",
  _FLEET_USER_USERDETAIL_STATUSDDL_INACTIVE_: " غير نشط",
  _FLEET_USER_USERDETAIL_ROLE_: "اختر دور",
  _FLEET_USER_USERDETAIL_ROLE_EMPTY_: "وظيفة",
  _FLEET_USER_USERDETAIL_PHOTO_: "حمل الصورة",
  _FLEET_USER_USERDETAIL_ICON_: "أيقونة",
  _FLEET_USER_USERDETAIL_NOICON_: "لا يوجد أيقونة",
  _FLEET_USER_USERDETAIL_NOIMAGE_: "لا يوجد صورة",
  _FLEET_USER_USERDETAIL_SAVEMSG_: "حفظ بيانات المستخدم بنجاح",
  _FLEET_USER_USERDETAIL_ERRORMSG_:
    "يرجى تعبئة البيانات الصحيحة في الحقول التي يتم وضع علامة حمراء",
  _FLEET_USER_USERDETAIL_ERRORPHOTO_:
    "يرجى تحميل صورة صالحة. يجب أن تكون أبعاد الصورة 75 * 75",
  _FLEET_USER_USERDETAIL_ERRORUSER_: "اسم المستخدم هذا موجود مسبقا",
  _FLEET_MANAGEUSER_CHANGEPASSWORD_TAB_: " تغيير الرقم السري",
  _FLEET_CHANGEPASSWORD_TITLE_: "غير كلمة المرور الخاصة بك",
  _FLEET_CHANGEPASSWORD_SAVEBTN_: "حفظ",
  _FLEET_CHANGEPASSWORD_CANCELBTN_: "إلغاء",
  _FLEET_CHANGEPASSWORD_OLDPASSWD_: "كلمة المرور القديمة",
  _FLEET_CHANGEPASSWORD_NEWPASSWD_: "كلمة السر الجديدة",
  _FLEET_CHANGEPASSWORD_CONFIRMPASSWD_: "تأكيد كلمة المرور",
  _FLEET_CHANGEPASSWORD_PASSWDNOTMATCH_: "كلمة المرور غير مطابقة.",
  _FLEET_CHANGEPASSWORD_SETLOAD_: "يتم حفظ كلمة المرور الجديدة ...",
  _FLEET_CHANGEPASSWORD_SAVEMSG_: "حفظ بنجاح",
  _FLEET_CHANGEPASSWORD_ERRORMSG_:
    "يرجى تعبئة جميع الحقول التي تحمل علامة حمراء.",
  _FLEET_CHANGEPASSWORD_ERRORPASSWD_: "كلمة السر القديمة غير صحيحة",
  _FLEET_MANAGEUSER_GROUP_TAB_: "مجموعة",
  _FLEET_GROUP_GROUPLIST_TITLE_: "قائمة الأجهزة",
  //_FLEET_GROUP_GROUPLIST_COMPANYDDL_: 'شركة',
  _FLEET_GROUP_GROUPLIST_COMPANYDDL_: " حساب",
  _FLEET_GROUP_GROUPLIST_ADDBTN_: " إضافة",
  _FLEET_GROUP_GROUPLIST_DEVICENAME_: "جهاز",
  _FLEET_GROUP_GROUPLIST_EDIT_: "تحرير جهاز",
  _FLEET_GROUP_GROUPLIST_DELETE_: "إزالة الجهاز",
  _FLEET_GROUP_GROUPLIST_REMOVETITLE_: "إزالة الجهاز؟",
  _FLEET_GROUP_GROUPLIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_GROUP_GROUPLIST_YESBTN_: "نعم فعلا",
  _FLEET_GROUP_GROUPLIST_DELETELODING_: "يتم إزالة بيانات الجهاز",
  _FLEET_GROUP_GROUPDETAIL_TITLE_: "تفاصيل الجهاز",
  _FLEET_GROUP_GROUPDETAIL_BACKBTN_: "العودة",
  _FLEET_GROUP_GROUPDETAIL_SAVEBTN_: "حفظ",
  _FLEET_GROUP_GROUPDETAIL_CANCELBTN_: "إلغاء",
  // _FLEET_GROUP_GROUPDETAIL_COMPANY_: 'شركة',
  _FLEET_GROUP_GROUPDETAIL_COMPANY_: " حساب",
  _FLEET_GROUP_GROUPDETAIL_PARENTGROUP_: "اختر المجموعة الرئيسي",
  _FLEET_GROUP_GROUPDETAIL_DEVICEGROUP_: "المجموعة الجهاز",
  _FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_: "تحديد مركبةالأصول",
  _FLEET_GROUP_GROUPDETAIL_SETLOAD_: "يتم حفظ بيانات مركبةالأصول ..",
  _FLEET_GROUP_GROUPDETAIL_SAVEMSG_: "حفظ بنجاح",
  _FLEET_GROUP_GROUPDETAIL_DEVICESAVEMSG_:
    "المجموعة جهاز تطبق على المركبات المحددة",
  _FLEET_GROUP_GROUPDETAIL_ERRORMSG_:
    "يرجى تعبئة البيانات الصحيحة في الحقول التي يتم وضع علامة حمراء",
  _FLEET_GROUP_GROUPDETAIL_ERRORGROP_: " اسم المجموعة جهاز موجود مسبقا.",
  _FLEET_MANAGEUSER_ROLE_TAB_: "العمل",
  _FLEET_ROLE_ROLELIST_TITLE_: "قائمة الدور",
  //_FLEET_ROLE_ROLELIST_COMPANYDDL_: 'شركة',
  _FLEET_ROLE_ROLELIST_COMPANYDDL_: "حساب",
  _FLEET_ROLE_ROLELIST_ADDBTN_: "إضافة",
  _FLEET_ROLE_ROLELIST_ROLENAME_: "اسم الدور",
  _FLEET_ROLE_ROLELIST_STATUS_: " الحالة",
  _FLEET_ROLE_ROLELIST_STATUS_ACTIVE_: "النشاط",
  _FLEET_ROLE_ROLELIST_STATUS_INACTIVE_: "غير نشط",
  _FLEET_ROLE_ROLELIST_EDIT_: "تحرير دور",
  _FLEET_ROLE_ROLELIST_DELETE_: "حذف دور",
  _FLEET_ROLE_ROLELIST_REMOVETITLE_: "إزالة دور؟",
  _FLEET_ROLE_ROLELIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_ROLE_ROLELIST_YESBTN_: "نعم فعلا",
  _FLEET_ROLE_ROLELIST_DELETELODING_: "يتم إزالة البيانات دور",
  _FLEET_ROLE_ROLEDETAIL_TITLE_: "تفاصيل الدور",
  _FLEET_ROLE_ROLEDETAIL_BACKBTN_: "العودة",
  _FLEET_ROLE_ROLEDETAIL_SAVEBTN_: "حفظ",
  _FLEET_ROLE_ROLEDETAIL_CANCELBTN_: "إلغاء",
  // _FLEET_ROLE_ROLEDETAIL_COMPANY_: 'اختر الشركة',
  _FLEET_ROLE_ROLEDETAIL_COMPANY_: "اختر  حساب",
  _FLEET_ROLE_ROLEDETAIL_ROLENAME_: "اسم الدور",
  _FLEET_ROLE_ROLEDETAIL_MODULENAME_: "اسم وحدة",
  _FLEET_ROLE_ROLEDETAIL_VIEW_: "عرض",
  _FLEET_ROLE_ROLEDETAIL_ADD_: " إضافة",
  _FLEET_ROLE_ROLEDETAIL_EDIT_: "تحرير ",
  _FLEET_ROLE_ROLEDETAIL_DELETE_: "حذف",
  _FLEET_ROLE_ROLEDETAIL_IMPORT_: "استيراد",
  _FLEET_ROLE_ROLEDETAIL_SETLOAD_: "يتم حفظ دور ...",
  _FLEET_ROLE_ROLEDETAIL_SAVEMSG_: "حفظ بنجاح",
  _FLEET_ROLE_ROLEDETAIL_ERRORMSG_: "ملء جميع الحقول بالحمرة",
  _FLEET_MANAGEVEHICLE_TAB_: "إدارة مركبةالأصول وسائق",
  _FLEET_MANAGEVEHICLE_VEHICLE_TAB_: "مركباتالأصول",
  _FLEET_VEHICLE_VEHICLELIST_TITLE_: "قائمة مركبةالأصول",
  _FLEET_VEHICLE_VEHICLELIST_COMPANYDDL_: "حساب",
  // _FLEET_VEHICLE_VEHICLELIST_COMPANYDDL_: 'شركة',
  _FLEET_VEHICLE_VEHICLELIST_ADDBTN_: "إضافة",
  _FLEET_VEHICLE_VEHICLELIST_VEHICLENO_: "رقم الأصول",
  _FLEET_VEHICLE_VEHICLELIST_DRIVER_: "السائق",
  _FLEET_VEHICLE_VEHICLELIST_NODRIVER_: "لا يوجد سائق",
  _FLEET_VEHICLE_VEHICLELIST_DEVICE_: "جهاز",
  _FLEET_VEHICLE_VEHICLELIST_NODEVICE_: "لا يوجد جهاز",
  _FLEET_VEHICLE_VEHICLELIST_IMEI_: "رقم التعقب",
  _FLEET_VEHICLE_VEHICLELIST_SIMNO_: "رقم الشريحة",
  _FLEET_VEHICLE_VEHICLELIST_STATUS_: "الحالة",
  _FLEET_VEHICLE_VEHICLELIST_STATUS_ACTIVE_: "النشاط",
  _FLEET_VEHICLE_VEHICLELIST_STATUS_INACTIVE_: "غير نشط",
  _FLEET_VEHICLE_VEHICLELIST_EDIT_: "تحرير مركبةالأصول",
  _FLEET_VEHICLE_VEHICLELIST_DELETE_: "حذف مركبةالأصول",
  _FLEET_VEHICLE_VEHICLELIST_REMOVETITLE_: "إزالة مركبةالأصول؟",
  _FLEET_GEOFENCE_GEOFENCELIST_REMOVETITLE_: "إزالة السياجات الجغرافية؟",
  _FLEET_VEHICLE_VEHICLELIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_VEHICLE_VEHICLELIST_YESBTN_: "نعم فعلا",
  _FLEET_VEHICLE_VEHICLELIST_DELETELODING_: "يتم إزالة بيانات مركبةالأصول",
  _FLEET_VEHICLE_VEHICLEDETAIL_TITLE_: "تفاصيل المركباتالأصول",
  _FLEET_VEHICLE_VEHICLEDETAIL_BACKBTN_: "العودة",
  _FLEET_VEHICLE_VEHICLEDETAIL_SAVEBTN_: "حفظ",
  _FLEET_VEHICLE_VEHICLEDETAIL_CANCELBTN_: "إلغاء",
  //_FLEET_VEHICLE_VEHICLEDETAIL_COMPANY_: 'شركة',
  _FLEET_VEHICLE_VEHICLEDETAIL_COMPANY_: "حساب",
  _FLEET_VEHICLE_VEHICLEDETAIL_PLATENO_: "رقم اللوحة ",
  _FLEET_VEHICLE_VEHICLEDETAIL_VEHICLENAME_: "اسم الأصول",
  _FLEET_VEHICLE_VEHICLEDETAIL_DRIVER_: "السائق",
  _FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_: "إضافة سائق جديد",
  _FLEET_VEHICLEDETAIL_ADDDRIVER_TITLE_: "إضافة سائق",
  _FLEET_VEHICLE_VEHICLEDETAIL_SIMNO_: " رقم الشريحة",
  _FLEET_VEHICLE_VEHICLEDETAIL_BRAND_: "العلامة التجارية",
  _FLEET_VEHICLE_VEHICLEDETAIL_MODEL_: "نموذج",
  _FLEET_VEHICLE_VEHICLEDETAIL_COLOR_: "اللون",
  _FLEET_VEHICLE_VEHICLEDETAIL_DEVICE_: " جهاز",
  _FLEET_VEHICLE_VEHICLEDETAIL_IMEI_: "IMEI/رقم التعقب",
  _FLEET_VEHICLE_VEHICLEDETAIL_VIN_: "رقم تعريف المركبة",
  _FLEET_VEHICLE_VEHICLEDETAIL_ODOMETER_: "قراءة عداد المسافات",
  _FLEET_VEHICLE_VEHICLEDETAIL_LASTSERVICE_: "تم آخر خدمة",
  _FLEET_VEHICLE_VEHICLEDETAIL_LASTOILCHANGE_: "آخر تغيير الزيت ",
  _FLEET_VEHICLE_VEHICLEDETAIL_LASTTIRE_: "آخر تغيير الإطارات",
  _FLEET_VEHICLE_VEHICLEDETAIL_OILCHANGE_: "تغيير الزيت (كم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TIRECHANGE_: "تغيير الإطارات (كم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_SERVICEEXPIRE_: "تنتهي خدمة (كم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_MANUFACTURE_: "سنة التصنيع ",
  _FLEET_VEHICLE_VEHICLEDETAIL_FUELCAPACITY_: "سعة الوقود",
  _FLEET_VEHICLE_VEHICLEDETAIL_FUELTANK_: "رقم خزان الوقود",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME1_: "خزان 1 الحجم",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME2_: "خزان 2 الحجم",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME3_: "خزان 3 حجم ",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_HORIZONTAL_: "مستطيل الأفقي",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_VERTICAL_: "اسطوانة عمودية",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_CYLINDRICAL_: "اسطوانة أفقية ",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1SHAPE_: "خزان 1 الشكل",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1LENGTH_: "خزان 1 طول (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1WIDTH_: "خزان 1 العرض (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1HEIGHT_: "خزان 1 ارتفاع (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1DIAMETER_: "خزان 1 القطر(سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_FID1_: "F Id 1",
  _FLEET_VEHICLE_VEHICLEDETAIL_INSTALLATIONSPACE1_: "مكان تركيب 1(سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2SHAPE_: "خزان 2 الشكل",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2LENGTH_: "خزان 2 طول (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2WIDTH_: "خزان 2 العرض (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2HEIGHT_: "خزان 2 ارتفاع (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2DIAMETER_: "خزان 2 القطر(سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_FID2_: "F Id 2",
  _FLEET_VEHICLE_VEHICLEDETAIL_INSTALLATIONSPACE2_: "مكان تركيب 2 (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3SHAPE_: "خزان 3 الشكل",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3LENGTH_: "خزان 3 طول (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3WIDTH_: "خزان 3 العرض (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3HEIGHT_: "خزان 3 ارتفاع (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3DIAMETER_: "خزان 3 القطر(سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_FID3_: "F Id 3",
  _FLEET_VEHICLE_VEHICLEDETAIL_INSTALLATIONSPACE3_: "مكان التركيب 3 (سم)",
  _FLEET_VEHICLE_VEHICLEDETAIL_FUELTYPE_: "نوع الوقود",
  _FLEET_VEHICLEDETAIL_FUELTYPEDDL_PETROL: "البنزين",
  _FLEET_VEHICLEDETAIL_FUELTYPE_DIESEL_: "الديزل",
  _FLEET_VEHICLE_VEHICLEDETAIL_STATUS_: "الحالة",
  _FLEET_VEHICLEDETAIL_STATUSDDL_ACTIVE_: "النشاط",
  _FLEET_VEHICLEDETAIL_STATUSDDL_INACTIVE_: "غير نشط",
  _FLEET_VEHICLE_VEHICLEDETAIL_UPLOADICON_: "ارفع أيقونة",
  _FLEET_VEHICLE_VEHICLEDETAIL_ICON_: "أيقونة",
  _FLEET_VEHICLE_VEHICLEDETAIL_NOICON_: "لا يوجد أيقونة",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_DOOR_: "باب",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT_: "حزام المقعد",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_ACC_: "ACC",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_PANIC_: "الهلع ",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL1_: "الإدخال الرقمي 1",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL2_: "الإدخال الرقمي 2",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL3_: "الإدخال الرقمي 3",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL4_: "الإدخال الرقمي 4",
  _FLEET_VEHICLE_VEHICLEDETAIL_SETLOAD_: "يتم حفظ بيانات مركبةالأصول",
  _FLEET_VEHICLE_VEHICLEDETAIL_SAVEMSG_: "حفظ بنجاح",
  _FLEET_VEHICLE_VEHICLEDETAIL_ERRORMSG_:
    "يرجى تعبئة البيانات الصحيحة في الحقول التي يتم وضع علامة حمراء.",
  //_FLEET_VEHICLE_VEHICLEDETAIL_ERRORPHOTO_: 'يرجى تحميل صورة صالحة. يجب أن تكون أبعاد الصورة 75 * 75',
  _FLEET_VEHICLE_VEHICLEDETAIL_ERRORPHOTO_:
    "يرجى تحميل صورة صالحة. يجب أن تكون أبعاد الصورة 150 * 150",
  _FLEET_VEHICLE_VEHICLEDETAIL_ERRORIMEI_:
    ".  موجود مسبقا  للمركبةالأصولIMEI رقم",
  _FLEET_MANAGEVEHICLE_DRIVER_TAB_: " السائق",
  _FLEET_DRIVER_DRIVERLIST_TITLE_: "قائمة سائق",
  // _FLEET_DRIVER_DRIVERLIST_COMPANYDDL_: 'شركة',
  _FLEET_DRIVER_DRIVERLIST_COMPANYDDL_: "حساب",
  _FLEET_DRIVER_DRIVERLIST_ADDBTN_: " إضافة",
  _FLEET_DRIVER_DRIVERLIST_DRIVERNAME_: "اسم السائق",
  _FLEET_DRIVER_DRIVERLIST_LICENCE_: "قم الرخصة",
  _FLEET_DRIVER_DRIVERLIST_MOBILE_: "رقم الجوال",
  _FLEET_DRIVER_DRIVERLIST_EDIT_: "تحرير سائق",
  _FLEET_DRIVER_DRIVERLIST_DELETE_: "حذف سائق",
  _FLEET_DRIVER_DRIVERLIST_REMOVETITLE_: "إزالة سائق؟",
  _FLEET_DRIVER_DRIVERLIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_DRIVER_DRIVERLIST_YESBTN_: "نعم فعلا",
  _FLEET_DRIVER_DRIVERLIST_DELETELODING_: "يتم إزالة البيانات سائق",
  _FLEET_DRIVER_DRIVERDETAIL_TITLE_: "تفاصيل السائق",
  _FLEET_DRIVER_DRIVERDETAIL_BACKBTN_: "العودة",
  _FLEET_DRIVER_DRIVERDETAIL_SAVEBTN_: "حفظ",
  _FLEET_DRIVER_DRIVERDETAIL_CANCELBTN_: "إلغاء",
  // _FLEET_DRIVER_DRIVERDETAIL_COMPANY_: 'شركة',
  _FLEET_DRIVER_DRIVERDETAIL_COMPANY_: "حساب",
  _FLEET_DRIVER_DRIVERDETAIL_LICENCENO_: "رقم الرخصة/رقم الهوية",
  _FLEET_DRIVER_DRIVERDETAIL_DRIVERNAME_: "اسم السائق",
  _FLEET_DRIVER_DRIVERDETAIL_ADDRESS_: "عنوان السائق",
  _FLEET_DRIVER_DRIVERDETAIL_UPLOAD_: "تحميل معرف الصورة ",
  _FLEET_DRIVER_DRIVERDETAIL_ICON_: "أيقونة",
  _FLEET_DRIVER_DRIVERDETAIL_NOIMAGE_: "لا يوجد صورة",
  _FLEET_DRIVER_DRIVERDETAIL_LICENSEEXPIRY_: "تاريخ انتهاء رخصة ",
  _FLEET_DRIVER_DRIVERDETAIL_MOBILE_: " رقم الجوال",
  _FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNAME_: "اسم الاتصال الطوارئ ",
  _FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNUMBER_: "رقم الاتصال في حالات الطوارئ",
  _FLEET_DRIVER_DRIVERDETAIL_BLOODGROUP_: "فصيلة الدم",
  _FLEET_DRIVER_DRIVERDETAIL_NOTES_: "ملاحظات",
  _FLEET_DRIVER_DRIVERDETAIL_SETLOAD_: "يتم حفظ البيانات سائق",
  _FLEET_DRIVER_DRIVERDETAIL_SAVEMSG_: "حفظ البيانات برنامج السائق بنجاح",
  _FLEET_DRIVER_DRIVERDETAIL_ERRORMSG_:
    "يرجى تعبئة البيانات الصحيحة في الحقول التي يتم وضع علامة حمراء.",
  _FLEET_DRIVER_DRIVERDETAIL_ERRORPHOTO_:
    "يرجى تحميل صورة صالحة. يجب أن تكون أبعاد الصورة 75 * 75",
  _FLEET_MANAGERULE_TAB_: "إدارة القواعد",
  _FLEET_MANAGERULE_RULEADMIN_TAB_: "مسؤول القاعدة",
  _FLEET_RULEADMIN_RULELIST_TITLE_: " قائمة القاعدة",
  // _FLEET_RULEADMIN_RULELIST_COMPANYDDL_: 'شركة',
  _FLEET_RULEADMIN_RULELIST_COMPANYDDL_: "حساب",
  _FLEET_RULEADMIN_RULELIST_ADDBTN_: "إضافة",
  _FLEET_RULEADMIN_RULELIST_RULEID_: "معرف القاعدة",
  _FLEET_RULEADMIN_RULELIST_DESCRIPTION_: "الوصف",
  _FLEET_RULEADMIN_RULELIST_CRONRULE_: "قاعدة كرون",
  _FLEET_RULELIST_CRONRULE_NO_: "لا",
  _FLEET_RULELIST_CRONRULE_5MIN_: "5 دقائق",
  _FLEET_RULELIST_CRONRULE_15MIN_: "15 دقيقة",
  _FLEET_RULELIST_CRONRULE_30MIN_: "30 دقيقة",
  _FLEET_RULELIST_CRONRULE_HOURLY_: "كل ساعة",
  _FLEET_RULELIST_CRONRULE_DAILY_: "اليومي",
  _FLEET_RULELIST_CRONRULE_WEEKLY_: "أسبوعي",
  _FLEET_RULEADMIN_RULELIST_ACTIVE_: "النشاط",
  _FLEET_RULEADMIN_RULELIST_EDIT_: "تحرير القاعدة",
  _FLEET_RULEADMIN_RULELIST_DELETE_: "حذف القاعدة",
  _FLEET_RULEADMIN_RULELIST_REMOVETITLE_: "إزالة القاعدة؟",
  _FLEET_RULEADMIN_RULELIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_RULEADMIN_RULELIST_YESBTN_: "نعم فعلا",
  _FLEET_RULEADMIN_RULELIST_DELETELODING_: "يتم إزالة بيانات القاعدة ..",
  _FLEET_RULEADMIN_RULEDETAIL_TITLE_: "تفاصيل القاعدة",
  _FLEET_RULEADMIN_RULEDETAIL_BACKBTN_: " العودة",
  _FLEET_RULEADMIN_RULEDETAIL_SAVEBTN_: " حفظ",
  _FLEET_RULEADMIN_RULEDETAIL_CANCELBTN_: "إلغاء",
  // _FLEET_RULEADMIN_RULEDETAIL_COMPANY_: 'شركة',
  _FLEET_RULEADMIN_RULEDETAIL_COMPANY_: " حساب",
  _FLEET_RULEADMIN_RULEDETAIL_RULENAME_: "اسم تنبيه ",
  _FLEET_RULEADMIN_RULEDETAIL_NOTIFICATIONEMAIL_: "البريد الإلكتروني الإشعارات",
  _FLEET_RULEADMIN_RULEDETAIL_NOTIFICATIONMOBILE_: " الجوال الإشعارات",
  _FLEET_RULEADMIN_RULEDETAIL_SYSTEMRULE_: "نظام القاعدة",
  _FLEET_RULEADMIN_RULEDETAIL_ACTIVE_: "النشاط",
  _FLEET_RULEDETAIL_ACTIVEDDL_NO_: "لا",
  _FLEET_RULEDETAIL_ACTIVEDDL_YES_: "نعم فعلا",
  _FLEET_RULEADMIN_RULEDETAIL_CRONRULE_: "قاعدة كرون",
  _FLEET_RULEDETAIL_CRONRULE_NO_: "لا",
  _FLEET_RULEDETAIL_CRONRULE_5MIN_: "5 دقائق",
  _FLEET_RULEDETAIL_CRONRULE_15MIN_: "15 دقيقة",
  _FLEET_RULEDETAIL_CRONRULE_30MIN_: "30 دقيقة",
  _FLEET_RULEDETAIL_CRONRULE_HOURLY_: "كل ساعة",
  _FLEET_RULEDETAIL_CRONRULE_DAILY_: "اليومي",
  _FLEET_RULEDETAIL_CRONRULE_WEEKLY_: "أسبوعي",
  _FLEET_RULEDETAIL_CRONRULE_RULETRIGGER_: "(تستخدم لقاعدة دورية مما اثار)",
  _FLEET_RULEADMIN_RULEDETAIL_DESCRIPTION_: "الوصف",
  _FLEET_RULEADMIN_RULEDETAIL_RULESELECTOR_: "محدد تنبيه",
  _FLEET_RULEADMIN_RULEDETAIL_RULESELECTORBTN_: "اختر محدد التنبيه",
  _FLEET_RULEADMIN_RULEDETAIL_TRIGGERACTION_: "تشغيل النشاط",
  _FLEET_RULEDETAIL_TRIGGERACTION_EMAIL_: "البريد الإلكتروني",
  _FLEET_RULEDETAIL_TRIGGERACTION_SAVE_: "حفظ / تنبيه",
  _FLEET_RULEDETAIL_TRIGGERACTION_QUEUE_: "طابور",
  _FLEET_RULEADMIN_RULEDETAIL_MININTERVAL_: "تنبيه الفاصل",
  _FLEET_RULEADMIN_RULEDETAIL_MININTERVALMIN_: "(الدقائق)",
  _FLEET_RULEADMIN_RULEDETAIL_PREDEFINEACTION_: "الإجراءات المحددة مسبقا",
  _FLEET_RULEDETAIL_PREDEFINEACTIONDDL_SELECT_: "تحديد",
  _FLEET_RULEDETAIL_PREDEFINEACTIONDDL_ON_: "إرسال أمر تشغيل منع الحركة",
  _FLEET_RULEDETAIL_PREDEFINEACTIONDDL_OFF_: "إرسال أمر ايقاف منع الحركة ",
  _FLEET_RULEADMIN_RULEDETAIL_EMAILSUBJECT_: "موضوع البريد الإلكتروني",
  _FLEET_RULEADMIN_RULEDETAIL_SELECTPARAMETER_: "اختر المعامل",
  _FLEET_RULEDETAIL_SELECTPARAMETER_VEHICLE_: " رقم الصنف",
  _FLEET_RULEDETAIL_SELECTPARAMETER_SPEED: " السرعة(الكيلومتر)",
  _FLEET_RULEDETAIL_SELECTPARAMETER_DISTANCE_: " مسافه",
  _FLEET_RULEDETAIL_SELECTPARAMETER_LOCATION_: "موقعك",
  _FLEET_RULEDETAIL_SELECTPARAMETER_TEMPERATURE_: "درجة الحرارة",
  _FLEET_RULEADMIN_RULEDETAIL_EMAILMESSAGE_: "رسالة الكترونية",
  _FLEET_RULEADMIN_RULEDETAIL_SMSMESSAGE_: "رسالة SMS",
  _FLEET_RULEADMIN_VEHICLEDETAIL_TITLE_: "اختيار رمز الحالة مركبةالأصول ",
  _FLEET_RULEADMIN_RULEDETAIL_VEHICLEID_: "بواسطة معرف مركبةالأصول",
  _FLEET_RULEADMIN_RULEDETAIL_VEHICLE_EMPTY_:
    "لا يوجد السيارات (حدد حسب المجموعات)",
  _FLEET_RULEADMIN_RULEDETAIL_VEHICLE_ERRORMSG_: "الرجاء اختيار مركبةالأصول",
  _FLEET_RULEADMIN_RULEDETAIL_GROUPID_: "بواسطة معرف مجموعة ",
  _FLEET_RULEADMIN_RULEDETAIL_GROUP_EMPTY_:
    "لا يوجد مجموعات (حدد حسب المركبات)",
  _FLEET_RULEADMIN_RULEDETAIL_GROUP_ERRORMSG_:
    "الرجاء اختيار معرف مركبةالأصول أو معرف المجموعة",
  _FLEET_RULEADMIN_RULEDETAIL_STATUSCODE_: "رمز الحالة",
  _FLEET_RULEDETAIL_STATUSCODEDDL_ALLCODE_: "كل رموز",
  _FLEET_RULEADMIN_RULEDETAIL_SETLOAD_: "يتم حفظ قاعدة بيانات الإدارة",
  _FLEET_RULEADMIN_RULEDETAIL_SAVEMSG_: "حفظ بنجاح",
  _FLEET_RULEADMIN_RULEDETAIL_ERRORMSG_:
    "يرجى تعبئة البيانات الصحيحة في الحقول التي يتم وضع علامة حمراء",
  _FLEET_RULEDETAIL_RULESELECTORWIN_TITLE_: "النافذة محدد تنبيه",
  _FLEET_RULEDETAIL_RULESELECTORWIN_ALERTTITLE_: "تأكيد اغلاق",
  _FLEET_RULEADMIN_RULESELECTORWIN_ALERTMSG_:
    "لديك تغييرات غير محفوظة في هذا الإطار. هل تريد إغلاق نافذة </ br> ووتفقد التغييرات؟",
  _FLEET_RULEADMIN_RULESELECTORWIN_YESBTN_: "نعم فعلا",
  _FLEET_RULESELECTORDETAIL_TITLE_: "تفاصيل محدد تنبيه",
  _FLEET_RULESELECTORDETAIL_SAVEBTN_: " حفظ",
  _FLEET_RULESELECTORDETAIL_ENABLEBTN_: "تمكين",
  _FLEET_RULESELECTORDETAIL_DISABLEBTN_: "تعطيل",
  _FLEET_RULESELECTORDETAIL_DESCRIPTION_: " الوصف",
  _FLEET_RULESELECTORDETAIL_ERRORMSG_: "هذا مجال مطلوب.",
  _FLEET_RULESELECTORDETAIL_RULE1_TITLE_: " فرملة مفاجئة قوية",
  _FLEET_RULESELECTORDETAIL_RULE1__SETSPEEDLIMIT_:
    "تعيين إسقاط الحد الأقصى للسرعة (كم / ساعة)",
  _FLEET_RULESELECTORDETAIL_RULE1_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عند تطبيق الفرامل المفاجئة من قبل السائق. يمكن للمستخدم تحديد الحد الأقصى لانخفاض للسرعة  كلم / ساعة. وسيتم تشغيل القاعدة عند انخفاض السرعة فجأة </ br> إلى الحد المحدد و سيتم إبلاغ المستخدم ",
  _FLEET_RULESELECTORDETAIL_RULE2_TITLE_: " نسبة السرعة الزائدة",
  _FLEET_RULESELECTORDETAIL_RULE2_SETACCERSPEEDLIMIT_:
    "تعيين الحد الأقصى للسرعة متسارعة (كم / ساعة)",
  _FLEET_RULESELECTORDETAIL_RULE2_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما يتم تطبيق التسارع المفاجئ من قبل السائق. يمكن للمستخدم تحديد الحد الأقصى للسرعة تسارع في كم / ساعة. وسيتم تشغيل القاعدة عندما تتسارع </ br> فجأة إلى السرعة المحددة و سيتم إبلاغ المستخدم نفسه.",
  _FLEET_RULESELECTORDETAIL_RULE3_TITLE_: "حزام المقعد",
  _FLEET_RULESELECTORDETAIL_RULE3_SETVEHICLESPEED_:
    "تعيين سرعة مركبةالأصول (كم / ساعة)",
  _FLEET_RULESELECTORDETAIL_RULE3_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما السيارة تتحرك بسرعة: 10 و السائق ما يربط الحزام",
  _FLEET_RULESELECTORDETAIL_RULE4_TITLE_: "العمل خارج ساعات",
  _FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_: "من الوقت",
  _FLEET_RULESELECTORDETAIL_RULE4_TOTIME_: "الي الوقت",
  _FLEET_RULESELECTORDETAIL_RULE4_SPEEDLIMIT_:
    "تعيين الحد الأقصى للسرعة (كم / ساعة)",
  _FLEET_RULESELECTORDETAIL_RULE4_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما يتجاوز السيارة من ساعات العمل المحددة من قبل المستخدم مع الحد الأقصى للسرعة المحددة. إذا لم يكن الحد الأقصى للسرعة مطلوبًا  فيمكن للمستخدم </ b> تعيين 0 عند الحد الأقصى للسرعة. ",
  _FLEET_RULESELECTORDETAIL_RULE5_TITLE_: "توقفت تقارير مركبة الأصول ",
  _FLEET_RULESELECTORDETAIL_RULE5_SETTIMELIMIT_: "تعيين الحد الزمني (ثانية) ",
  _FLEET_RULESELECTORDETAIL_RULE5_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما توقف السيارة الإبلاغ في فترة زمنية محددة",
  _FLEET_RULESELECTORDETAIL_RULE6_TITLE_: "توقفت خارج سياج الجغرافي",
  _FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_: "اختر السياجات الجغرافية",
  _FLEET_RULESELECTORDETAIL_RULE6_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عند إيقاف السيارة خارج السياجات الجغرافية لفترة زمنية محددة",
  _FLEET_RULESELECTORDETAIL_RULE7_TITLE_: "تجاوز السرعة",
  _FLEET_RULESELECTORDETAIL_RULE7_SETSPEEDLIMIT_:
    "تعيين الحد الأقصى للسرعة (كم / ساعة)",
  _FLEET_RULESELECTORDETAIL_RULE7_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما يتجاوز السيارة  الحد الأقصى للسرعة",
  _FLEET_RULESELECTORDETAIL_RULE8_TITLE_: "مدخلات تشغيل / إيقاف",
  _FLEET_RULESELECTORDETAIL_RULE8_INPUT_: "اختر  الإدخال",
  _FLEET_RULESELECTORDETAIL_RULE8_EVENT_: "اختر التنيه",
  _FLEET_RULESELECTORDETAIL_RULE8_EVENT_ON_: "تشغيل",
  _FLEET_RULESELECTORDETAIL_RULE8_EVENT_OFF_: "إيقاف",
  _FLEET_RULESELECTORDETAIL_RULE8_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عند تشغيل أي مساهمة أو إيقاف تشغيله",
  _FLEET_RULESELECTORDETAIL_RULE9_TITLE_: "رصد درجة الحرارة",
  _FLEET_RULESELECTORDETAIL_RULE9_SETTEMPERATURE_:
    "تعيين مستوى درجة الحرارة (الحد الأدنى)",
  _FLEET_RULESELECTORDETAIL_RULE9_MAXTEMP_: "درجة الحرارة القصوى",
  _FLEET_RULESELECTORDETAIL_RULE9_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما درجة الحرارة يتجاوز الحد الأقصى المحدد. سيقوم المستخدم بتعيين الحد الأدنى والحد الأقصى لدرجة الحرارة",
  _FLEET_RULESELECTORDETAIL_RULE10_TITLE_: "زيادة في الوقود",
  _FLEET_RULESELECTORDETAIL_RULE10_FUELINCREASE_:
    "تعيين زيادة مستوى (٪) الوقود ",
  _FLEET_RULESELECTORDETAIL_RULE10_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما يزيد مستوى الوقود بنسبة مئوية محددة",
  _FLEET_RULESELECTORDETAIL_RULE11_TITLE_: "قطرة وقود",
  _FLEET_RULESELECTORDETAIL_RULE11_FUELDROP_: "تعيين إسقاط مستوى (٪) الوقود",
  _FLEET_RULESELECTORDETAIL_RULE11_FUELDROPDDL_SENS1_: "معرف الحساس الوقود 1",
  _FLEET_RULESELECTORDETAIL_RULE11_FUELDROPDDL_SENS2_: "معرف الحساس الوقود 2",
  _FLEET_RULESELECTORDETAIL_RULE11_FUELDROPDDL_SENS3_: "معرف الحساس الوقود 3",
  _FLEET_RULESELECTORDETAIL_RULE11_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما ينخفض ​​استهلاك الوقود بنسبة مئوية محددة",
  _FLEET_RULESELECTORDETAIL_RULE12_TITLE_:
    "السياجات الجغرافية الوصول / المغادرة",
  _FLEET_RULESELECTORDETAIL_RULE12_GEOFENCE_: "اختر السياجات الجغرافية",
  _FLEET_RULESELECTORDETAIL_RULE12_EVENT_: "اختر التنبيه",
  _FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_INOUT_: "داخل خارج",
  _FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_IN_: "في داخل",
  _FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_OUT_: "في الخارج",
  _FLEET_RULESELECTORDETAIL_RULE12_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما يدخل أو يخرج مركبةالأصول من سياج الجغرافي المتعيين",
  _FLEET_RULESELECTORDETAIL_RULE13_TITLE_: "الرحلة المسافة المقطوعة",
  _FLEET_RULESELECTORDETAIL_RULE13_ASSIGNDISTANCE_: "تعيين المسافة (كم)",
  _FLEET_RULESELECTORDETAIL_RULE13_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة عندما السيارة قد سافر مسافة معينة",
  _FLEET_RULESELECTORDETAIL_RULE14_TITLE_: "تذكيرالخدمة",
  _FLEET_RULESELECTORDETAIL_RULE14_DESCRIPTION_:
    "يتم تشغيل هذه القاعدة مع انتهاء صلاحية الخدمة",
  _FLEET_RULESELECTORDETAIL_RULE15_TITLE_:
    "إرسال الموقع الأخير في فترات زمنية ثابتة",
  _FLEET_RULESELECTORDETAIL_RULE15_SELECTINTERVAL_:
    "تحديد الفاصل الزمني (ساعة)",
  _FLEET_RULESELECTORDETAIL_RULE15_DESCRIPTION_:
    "سترسل هذه القاعدة آخر موقع عبر البريد الإلكتروني في فترة زمنية محددة",
  _FLEET_REPORTS_TAB_: "تقارير",
  _FLEET_REPORTS_COMPANY_: "حساب",
  // _FLEET_REPORTS_COMPANY_: 'شركة',
  //  _FLEET_REPORTS_VEHICLENO_: 'عدد مركبة\الأصول',
  _FLEET_REPORTS_VEHICLENO_: " اسم الأصول",
  _FLEET_REPORTS_VEHICLETYPE_: "نوع المركبة",
  _FLEET_REPORTS_FROM_: "من",
  _FLEET_REPORTS_TO_: "إلى",
  _FLEET_REPORTS_SHOWBTN_: "عرض",
  _FLEET_REPORTS_EMAILBTN_: "البريد الإلكتروني",
  _FLEET_REPORTS_COMPLETEREPORT_TITLE_: "تقريربيانات التتبع",
  _FLEET_REPORTS_FUELREPORT_TITLE_: "تقرير مستوى الوقود",
  _FLEET_REPORTS_FUELREPORT_FUELVOLUME_: "حجم الوقود وعداد المسافات",
  _FLEET_REPORTS_FUELREPORT_TIME_: "الوقت",
  _FLEET_REPORTS_TEMPREPORT_TITLE_: "تقرير درجة الحرارة",
  _FLEET_REPORTS_TEMPREPORT_TEMPERATURE_: "درجة الحرارة",
  _FLEET_REPORTS_TEMPREPORT_TIME_: "الوقت",
  _FLEET_REPORTS_STARTSTOPREPORT_TITLE_: " تقريرتحرك",
  _FLEET_REPORTS_STARTSTOPREPORT_STOPEDTIME_: "وقت التوقف ",
  _FLEET_REPORTS_STARTSTOPREPORT_STARTDATETIME_: "وقت وتاريخ التحرك ",
  _FLEET_REPORTS_STARTSTOPREPORT_STARTADDRESS_: "نوان البداية ",
  _FLEET_REPORTS_STARTSTOPREPORT_STOPDATETIME_: " إيقاف  التاريخ / الوقت",
  _FLEET_REPORTS_STARTSTOPREPORT_STOPADDRESS_: " عنوان الوقوف",
  _FLEET_REPORTS_STARTSTOPREPORT_DISTTRAVEL_: "المسافة المقطوعة",
  _FLEET_REPORTS_STARTSTOPREPORT_ELAPSEDTIME_: "المدة الزمنية",
  _FLEET_REPORTS_STARTSTOPREPORT_FUELUSED_1: "الوقود المستخدم 1",
  _FLEET_REPORTS_STARTSTOPREPORT_FUELUSED_2: "الوقود المستخدم 2",
  _FLEET_REPORTS_STARTSTOPREPORT_FUELUSED_3: "الوقود المستخدم 3",
  _FLEET_REPORTS_DISTANCEREPORT_TITLE_: "تقرير مسافه اليومي",
  _FLEET_REPORTS_DISTANCEREPORT_STARTOD_: " بدء عداد المسافات",
  _FLEET_REPORTS_DISTANCEREPORT_STOPOD_: " إيقاف عداد المسافات",
  _FLEET_REPORTS_STOPREPORT_TITLE_: "تقرير الايقاف",
  _FLEET_REPORTS_STOPREPORT_ADDRESS_: "عنوان",
  _FLEET_REPORTS_STOPREPORT_NEARESTPOI_: "أقرب نقطة الاهتمام (إن وجدت)",
  _FLEET_REPORTS_STOPREPORT_ARRIVALTIME_: "وقت الوصول",
  _FLEET_REPORTS_STOPREPORT_DEPARTURETIME_: "وقت المغادرة",
  _FLEET_REPORTS_STOPREPORT_TIMESTOP_: "الوقت توقف",
  _FLEET_REPORTS_STOPREPORT_VIEWMAP_: "عرض على الخريطة",
  _FLEET_REPORTS_EVENTREPORT_TITLE_: "تقرير الاحداث",
  _FLEET_REPORTS_EVENTREPORT_DATETIME_: "تاريخ / الوقت",
  _FLEET_REPORTS_EVENTREPOR_ADDRESS_: "عنوان",
  _FLEET_REPORTS_EVENTREPOR_SPEED_: " السرعة(الكيلومتر",
  _FLEET_REPORTS_EVENTREPOR_ODOMETER_: " عداد المسافات",
  _FLEET_REPORTS_EVENTREPOR_EVENT_: "حدث",
  _FLEET_REPORTS_EVENTREPOR_IGNITION_: "وضع الاشتعال",
  _FLEET_REPORTS_EVENTREPOR_VIEWMAP_: "عرض على الخريطة",
  _FLEET_REPORTS_RULETRIGGERREPORT_TITLE_: "تقرير تخطي قواعد الجهاز",
  _FLEET_REPORTS_RULETRIGGERREPORT_DATETIME_: "تاريخ / الوقت",
  _FLEET_REPORTS_RULETRIGGERREPORT_ADDRESS_: "عنوان",
  _FLEET_REPORTS_RULETRIGGERREPORT_SPEED_: "السرعة(الكيلومتر",
  _FLEET_REPORTS_RULETRIGGERREPORT_ODOMETER_: "عداد المسافات",
  _FLEET_REPORTS_RULETRIGGERREPORT_EVENT_: "حدث",
  _FLEET_REPORTS_RULETRIGGERREPORT_IGNITION_: "وضع الاشتعال",
  _FLEET_REPORTS_RULETRIGGERREPORT_RULETRIGGER_: "تم تشغيل القاعدة",
  _FLEET_REPORTS_RULETRIGGERREPORT_VIEWMAP_: "عرض على الخريطة",
  _FLEET_REPORTS_FLEETREPORT_TITLE_: "تقرير ملخص المركبات",
  _FLEET_REPORTS_FLEETREPORT_VEHICLE_: "مركبةالأصول",
  _FLEET_REPORTS_FLEETREPORT_DATETIME_: "تاريخ / الوقت",
  _FLEET_REPORTS_FLEETREPORT_MAXSPEED_: " السرعة القصوى",
  _FLEET_REPORTS_FLEETREPORT_AVERAGESPEED_: "متوسط ​​السرعة",
  _FLEET_REPORTS_FLEETREPORT_DELTAMILAGE_:
    "عدد الكيلومترات دلتا في الفترة المختارة",
  _FLEET_REPORTS_FLEETREPORT_CURRENTODOMETER_: " عداد المسافة الحالية",
  _FLEET_REPORTS_FLEETREPORT_MAXRPM_:
    "دورة في الدقيقةالقصوى (من OBD أو CANBUS)",
  _FLEET_REPORTS_FLEETREPORT_AVERAGERPM_: "متوسط ​​RPM (من OBD أو CANBUS)",
  _FLEET_REPORTS_FLEETREPORT_FUELCONSUM_: "استهلاك الوقود (لتر)",
  _FLEET_REPORTS_FLEETREPORT_FUELECONOMY_:
    "الاقتصاد في استهلاك الوقود (كم / لتر)",
  _FLEET_REPORTS_FLEETREPORT_FATIGUEHOUR_: "ساعات التعب",
  _FLEET_REPORTS_FLEETREPORT_HARSHBREAK_: " الإنذارت فرامل القاسية",
  _FLEET_REPORTS_FLEETREPORT_HARSHACCELERATION_: " الإنذارت تسارع القاسي",
  _FLEET_REPORTS_GEOFENCEREPORT_TITLE_: " الحدود في / خارج التقرير",
  _FLEET_REPORTS_GEOFENCEREPORT_INTIME_: " في الوقت المناسب",
  _FLEET_REPORTS_GEOFENCEREPORT_OUTTIME_: "وقت الخروج",
  _FLEET_REPORTS_GEOFENCEREPORT_GEOPOI_: "السياجات الجغرافية أو نقطة الاهتمام",
  _FLEET_REPORTS_GEOFENCEREPORT_ELAPSETIME_:
    "الوقت المنقضي داخل السياج الجغرافي",
  _FLEET_REPORTS_GEOFENCEREPORT_TIMESTOP_: "الوقت توقف",
  _FLEET_REPORTS_COMMANDREPORT_TITLE_: "تقرير الأوامر المرسلة ",
  _FLEET_REPORTS_COMMANDREPORT_DATETIME_: "تاريخ / الوقت",
  _FLEET_REPORTS_COMMANDREPORT_COMMANDSENT_: "تم إرسال الأمر",
  _FLEET_REPORTS_COMMANDREPORT_DEVICECOMMAND_: "هل الجهاز تلقى الأمر؟",
  _FLEET_REPORTS_COMMANDREPORT_USER_: " المستعمل",
  _FLEET_REPORTS_COMMANDREPORT_INPUTSENT_: "تم ارسال المدخلات",
  _FLEET_REPORTS_COMMANDREPORT_REPLY_: "سلسلة الرد",
  _FLEET_REPORTS_OBDFAULTREPORT_TITLE_: "رموز خطأ OBD",
  _FLEET_REPORTS_OBDFAULTREPORT_VEHICLE_: "مركبةالأصول",
  _FLEET_REPORTS_OBDFAULTREPORT_LASTFAULTCODE_:
    "الوقت / التاريخ عندما تم استلام آخر رمز الخطأ",
  _FLEET_REPORTS_OBDFAULTREPORT_FAULTCODE_: "رمز خطأ",
  _FLEET_REPORTS_OBDFAULTREPORT_LINK_: "رابط**",
  _FLEET_REPORTS_MAINTENANCEREPORT_TITLE_: "تقرير أسطول الصيانة",
  _FLEET_REPORTS_MAINTENANCEREPORT_VEHICLE_: "مركبةالأصول",
  _FLEET_REPORTS_MAINTENANCEREPORT_LASTOILCHANGE_:
    "آخر تغيير الزيت  (التاريخ / الوقت والعداد المسافات)",
  _FLEET_REPORTS_MAINTENANCEREPORT_LASTTIRECHANGE_:
    "آخر تغيير الإطارات  (التاريخ / الوقت والعداد المسافات)",
  _FLEET_REPORTS_MAINTENANCEREPORT_LASTMAJORSERVICE_:
    "آخر خدمة الرئيسية (التاريخ / الوقت والعداد المسافات)",
  // _FLEET_REPORTS_MAINTENANCEREPORT_NEXTOILCHANGE_: 'تغيير الزيت المقبل (التاريخ / الوقت والعداد المسافات)',
  _FLEET_REPORTS_MAINTENANCEREPORT_NEXTOILCHANGE_:
    "تغيير تغيير الزيت التالي (كم)",
  //_FLEET_REPORTS_MAINTENANCEREPORT_NEXTTIRECHANGE_: 'تغيير الإطارات المقبل (التاريخ / الوقت والعداد المسافات)',
  _FLEET_REPORTS_MAINTENANCEREPORT_NEXTTIRECHANGE_:
    "تغيير الإطارات المقبل (كم)",
  //_FLEET_REPORTS_MAINTENANCEREPORT_NEXTMAJORSERVICE_: 'الخدمة التالية الرئيسية (التاريخ / الوقت والعداد المسافات)',
  _FLEET_REPORTS_MAINTENANCEREPORT_NEXTMAJORSERVICE_:
    " تغيير (كم) خدمة المقبلة الرئيسية",
  _FLEET_REPORTS_MAINTENANCEREPORT_CURRENTODOMETER_:
    "قيمة عداد المسافات الحالية",
  _FLEET_REPORTS_VEHICLEREPORT_TITLE_: "تقرير الاستمارة",
  _FLEET_REPORTS_VEHICLEREPORT_VEHICLE_: "مركبةالأصول",
  _FLEET_REPORTS_VEHICLEREPORT_DEVICE_: "معرف الجهاز",
  _FLEET_REPORTS_VEHICLEREPORT_PLATE_: "لوحات",
  _FLEET_REPORTS_VEHICLEREPORT_SERIALNO_: "رقم متسلسل",
  _FLEET_REPORTS_VEHICLEREPORT_BRAND_: "العلامة التجارية",
  _FLEET_REPORTS_VEHICLEREPORT_MODEL_: "نموذج",
  _FLEET_REPORTS_VEHICLEREPORT_YEAR_: "عام",
  _FLEET_REPORTS_VEHICLEREPORT_COLOR_: "اللون",
  _FLEET_REPORTS_VEHICLEREPORT_VEHICLETYPE_: " نوع المركبة",
  _FLEET_REPORTS_VEHICLEREPORT_FUELECONOMY_:
    "الاقتصاد في استهلاك الوقود (كم / لتر)",
  _FLEET_REPORTS_VEHICLEREPORT_VEHICLESTATUS_: "الحالة مركبةالأصول",
  _FLEET_REPORTS_VEHICLEREPORT_REGISTER_: "تاريخ التسجيل",
  _FLEET_REPORTS_GEOFENCELIST_TITLE_: "قائمة السياج الجغرافي",
  _FLEET_REPORTS_GEOFENCELIST_GEOFENCENAME_: " الاسم الجغرافي",
  _FLEET_REPORTS_GEONAME_: " الاسم الجغرافي",
  _FLEET_REPORTS_GEOFENCELIST_CREATIONDATE_: "تاريخ الإنشاء",
  _FLEET_REPORTS_GEOFENCELIST_VIEWMAP_: "عرض على الخريطة",
  _FLEET_REPORTS_CHATREPORT_TITLE_: "تقرير دردشة ",
  _FLEET_REPORTS_CHATREPORT_DATETIME_: "تاريخ / الوقت",
  _FLEET_REPORTS_CHATREPORT_MESSAGE_: "رسالة",
  _FLEET_REPORTS_CHATREPORT_RECEIVE_: "تم الاستلام",
  _FLEET_REPORTS_CHATREPORT_SENT_: "تم إرسال",
  _FLEET_REPORTS_CHATREPORT_LOCATION_: "موقع السيارة",
  _FLEET_REPORTS_CHATREPORT_User_: "المستخدم",
  _TRACKING_: "تتبع",
  _MAINTENANCE_: " الصيانة",
  _REPORTS_: "تقارير",
  _MANAGE_APP_: "دارة التطبيق",
  _MANAGE_ACCOUNTS_: " إدارة الحسابات",
  _DASHBOARD_: " لوحة القيادة",
  _HELP_AND_SUPPORT_: "تعليمات و دعم",
  _ROAD_ASSISTANCE_: "خدمة الطرق",
  _ADMIN_ACCESS_: " وصول المسؤول",
  _LIVE_TRACING_: "تتبع مباشر",
  _LIVE_TRAVERSE_: "إتباع مباشر",
  _DETAILED_TRACKING_: "تتبع  مفصلة",
  _HISTORY_TRACKING_: "تتبع التاريخ محفوظات",
  _MULTI_TRACKING_: " تتبع متعددة",
  _HEAT_MAP_: " خريطة بيانات الحرارة",
  _TRACK_DATA_REPORT_: "تقريربيانات التتبع",
  _TRAVEL_REPORT_: "تقريرتحرك",
  _STOPPAGE_REPORT_: " تقرير الايقاف",
  _TRIP_REPORT_: "تقرير رحلة",
  _EX_IDLE_REPORT_: "تقرير الخمول الزائد",
  _IDLE_REPORT_: "تقرير الخمول",
  _DISTANCE_REPORT_: "تقرير المسافة",
  _DETAILED_DISTANCE_REPORT_: "تقرير مفصل للمسافة",
  _ACCOUNT_: "حساب",
  _ASSET_NAME_: "اسم الأصول",
  _STUDENTS_NAME_: "اسم الطلاب",
  _FROM_: "من",
  _FROM_TIME_: "من الوقت",
  _TO_TIME_: "الي الوقت",
  _FILTER_: "فلتر",
  _SHOW_: "عرض",
  _EXPORT_: "إخراج",
  _EMAIL_: "البريد الإلكتروني",
  _NEW_TAB_: "علامة تبويب جديدة",
  _START_ODOMETER_: "بدء عداد المسافات",
  _STOP_ODOMETER_: "إيقاف عداد المسافات",
  _DISTANCE_TRAVELED_KMS_: "المسافة المقطوعة (كيلو متر)",
  _START_DATE_TIME_: "البدء التاريخ/ الوقت",
  _STOP_DATE_TIME_: "إيقاف  التاريخ / الوقت",
  _FLEET_REPORT_: "تقرير المركبات",
  _FLEET_SUMMARY_REPORT_: "تقرير ملخص المركبات",
  _FUEL_FILLED_REPORT_: "تقرير تعبئة البنزين",
  _MILEAGE_REPORT_: "تقرير الأميال",
  _MILEAGE_: "كيلومتر",
  _FUEL_PRICE_: " سعر البنزين",
  _FLEET_MANAGEMENT_REPORT_: "تقرير إدارة الأسطول",
  _VEHICLE_DIAGNOSTIC_REPORT_: "تقرير تشخيص السيارة",
  _ALERT_REPORT_: "تقرير التنبيه",
  _SPEED_VIOLATION_REPORT_: "تقرير انتهاك السرعة",
  _SPEED_KM_: "السرعة(الكيلومتر)",
  _GEOFENCE_OR_POI_IN_OUT_REPORT_: "الحدود في / خارج التقرير",
  _TEMP_REPORT_: "تقرير درجة الحرارة",
  _VEHICLE_NUMBER_: "رقم المركبة",
  _INTERVAL_: "فترة",
  _CRITICAL_DATA_: "البيانات الحرجة",
  _GEOFENCE_SUMMARY_REPORT_: "تقرير ملخص السياج الجغرافي .",
  _SUMMARY_: "تلخيص",
  _DETAIL_GEOFENCE_SUMMARY_: " موجز الحدود التفصيلية",
  _GEOFENCE_TYPE_: " نوع الحدود",
  _IN_TIME_: " في الوقت المناسب",
  _OUT_TIME_: " وقت الخروج",
  _DISTANCE_: " مسافه",
  _FUEL_: " بنزين",
  _COST_: " تكلفة",
  _RT_IDLE_TIME_: "الوقت خارج العمل",
  _RT_TRAVEL_TIME_: " تقرير وقت الرحلة",
  _FS_IDEL_TIME_: " الوقت المثالي",
  _FS_TRAVEL_TIME_: " وقت الرحلة",
  _VEHICLE_NOT_POLL_REPORT_: "تقرير عدم تنفيذ المركبة",
  _RULE_TRIGGER_REPORT_: "تقرير مشغل القاعدة",
  _RULE_SELECTOR_: "اختيار القواعد",
  _DRIVER_RELATED_REPORT_: "تقرير عن السائق",
  _DRIVER_LOGIN_REPORT_: "تقرير تسجيل دخول السائق",
  _DRIVER_NAME_: "أسم السائق",
  _DRIVER_DETAILS_: "تفاصيل السائق",
  _LOGIN_TIME_: "وقت تسجيل الدخول",
  _LOGOUT_TIME_: "وقت تسجيل خروج",
  _MAX_SPEED_1: "السرعة القصوى",
  _DRIVER_PERFORMANCE_REPORT_: "تقرير أداء السائق",
  _DRIVER_PERFORMANCE_DETAIL_: "تفاصيل أداء السائق",
  _EXCE_IDLE_: "سكون فائض",
  _HARSH_BREAK_: "فرملة مفاجئة قوية",
  _HARSH_ACCELERATION_: "تسارع السيارة المفاجئ",
  _OVER_SPEED_: "السرعة العالية",
  _MAXIMUM_SPEED_AS_PER_THE_LIMIT_SET_: "السرعة القصوى المحددة",
  _OVER_SPEED_AS_PER_THE_RAOD_SPEED_: "السرعة الزائدة عن سرعة الطريق المحددة",
  _DRIVER_COMPLAIN_REPORT_: "تقرير كيفية قيادة السائق",
  _OVERTIME_REPORT_: "تقرير الوقت الاضافي",
  _MISCELLANEOUS_REPORTS_: "تقرير عام",
  _USER_LOGIN_REPORT_: " تقرير تسجيل دخول المستخدم",
  _VEHICLE_REGISTERED_REPORT_: " تقرير الاستمارة",
  _COMMAND_SENT_REPORT_: "تقرير الطلبات المرسلة لجهاز التعقب",
  _EVENT_REPORT_: "تقرير الاحداث",
  _EMAIL_REPORT_: "ارسال تقرير بواسطة الايميل",
  _RAW_DATA_REPORT_: "تقرير بيانات الخام",
  _OFENCES_LIST_: "قائمة المخالفات",
  _PASSENGER_LOG_REPORT_: "تقرير سجل الراكب",
  _ATTENDANCE_REPORT_: "تقرير الحضور",
  _ATTENDANCE_DETAILS_: "تفاصيل الحضور",
  _EMPLOYEE_TYPE_: "نوع الموظف",
  _OFFICE_NAME_: "اسم الشركة",
  _CATALOGS_: "كاتالوج الجهاز",
  _USERS_: "المستخدمين",
  _USERS_LIST_: "قائمة المستخدمين",
  _NAME_: "الاسم",
  _LAST_NAME_: "الاسم الاخير",
  _USERNAME_: "اسم المستخدم",
  _USER_TYPE_: "نوع  المستخدم",
  _EMAIL_ID_: "عنوان الايميل",
  _MOBILE_NUMBER_: "رقم الجوال",
  _STATUS_: "الحالة",
  _EMPLOYEE_ID_: "معرف الموظف / هوية الموظف",
  _ASSET_ID_: "الرقم المرجعي للمركبة",
  _ASSET_NAME_: "اسم المركبة",
  _ACCOUNT_NAME_: "اسم الحساب",
  _ASSET_TYPE_: "نوع المركبة",
  _DEVICE_: "جهاز",
  _IMEI_: "رقم التعقب",
  _SIM_NO_: "رقم الشريحة",
  _INSTALLATION_: "تركيب",
  _EXPIRY_DATE_: "تاريخ الانتهاء",
  _DRIVER_: "السائق",
  _GROUPS_: "مجموعة",
  _LICENSE_NUMBER_: "قم الرخصة",
  _LICENSE_EXPIRE_: "ملاحظات انتهاء الرخصة",
  _OFFICE_LOCATION_: "موقع المكتب",
  _COMPANY_NAME_: "اسم الشركة",
  _OFFICE_NAME_: "اسم المكتب",
  _OFFICE_TYPE_: "نوع المكتب",
  _CONTACT_PERSON_: "مسؤول التواصل",
  _CONTACT_PERSONS_EMAIL_ID_: "اسم و هوية مسؤول التواصل",
  _ADD_: " إضافة",
  _REFRESH_: "تحديث",
  _ASSET_DEVICE_ASSIGNMENT_: "اعطاء مهمة",
  _ALLOCATE_ASSET_: "توزيع المهام",
  _ALLOCATE_DATE_: "تاريخ توزيع المهام",
  _DAYS_USED_: "ايام الاستخدام",
  _RETURN_ASSET_: "إعادة المهام",
  _RETURN_DATE_: "إعادة البيانات",
  _RULES_ENGINE_: "قاعدة المحرك",
  _RULE_ID_: "قاعدة الهوية",
  _DESCRIPTION_: "الوصف",
  _CORN_RULE_: "قاعدة الذرة",
  _ACTIVE_: "النشاط",
  _MAINTENANCE_MODULE_: "وحدة الصيانة",
  _ASSET_NUMBER_: "رقم الصنف",
  _CURRENT_ODOMETER_: "عداد المسافة الحالية",
  _GARAGE_NAME_: "اسم ورشة العمل",
  _GARAGE_ADDRESS_: "عنوان ورشة العمل",
  _FUEL_MANAGEMENT_: "إدارة الوقود",
  _FUEL_DETAIL_: "تفاصيل الوقود",
  _PETROL_: "البنزين",
  _DIESEL_: "الديزل",
  _FUEL_FILLED_: "الوقود معبأ",
  _ODOMETER_: "عداد المسافات",
  _GEOFENCE_MANAGEMENT_: "الإدارة الجغرافية/جيوقينس",
  _GEOFENCE_NAME_: "الاسم الجغرافي/جيوفينس",
  _GEOFENCE_TYPE_: "النوع الجغرافي",
  _CREATION_DATE_: "تاريخ الحدث",
  _BOUNDS_: "الحدود",
  _RADIUS_: "نصف القطر",
  _ACTION_: "الحدث",
  _TRIP_CODE_: "رمز الرحلة",
  _TRIP_NAME_: "اسم الرحلة",
  _ZONE_DETAILS_: "تفاصيل المنطقة",
  _ROUTE_MANAGEMENT_: "إدارة المسارات",
  _DELIVERY_MANAGEMENT_: "إدارة التسليم",
  _CUSTOMER_NAME_: "اسم العميل",
  _INVOICE_NO_: "رقم الفاتورة",
  _INVOICE_AMOUNT_: "مبلغ الفاتورة",
  _MATERIAL_DESCRIPTION_: "وصف المادة",
  _NO_OF_CURTONS_: "رقم الشحنه",
  _PICKUP_DELIVERY_: "الاستلام والتسليم",
  _BACK_: "العودة",
  _SERVICE_: "الخدمة",
  _LOCK_: "قفل الأمان",
  _TRANSIT_: "العبور",
  _TRAFFIC_: "حركة المرور",
  _EDIT_ACCOUNT_: "تعديل الحساب",
  _EDIT_PROFILE_: "تعديل الملف الشخصي",
  _CHANGE_PASSWORD_: "تغيير الرقم السري",
  _NEW_POI_: "نقطة الاهتمام الجديدة",
  _SHOW_POI_: "أظهر نقطة الاهتمام",
  _CLEAR_POI_: "مسح نقطة الاهتمام",
  _SEARCH_POI_: "بحث نقطة الاهتمام",
  _IMPORT_POI_FROM_KML_: "استيراد نقطة مهمة من لغة ترميز المفتاح (KML)",
  _DRAW_GEOFENCE_: "الرسم الجغرافي/جيوفينس",
  _SHOW_GEOFENCE_: "العرض الجغرافي /جيوفينس",
  _IMPORT_EXPORT_FROM_XML_: " الاستيراد والتصدير من XML",
  _SEARCH_ASSET_: " البحث عن سيارة",
  _SEARCH_ADDRESS_: " عنوان البحث /عنوان التتبع",
  _HOSPITAL_: "مستشفى",
  _FUEL_STATION_: "محطة بنزين",
  _POLICE_STATION_: "مركز الشرطة",
  _DRIVER_: "السائق",
  _NEAREST_ASSET_: "أقرب الأصول",
  _GARAGE_: "كراج",
  _NAVIGATION_: "الملاحة",
  _CLEAR_MAP_: " مسح / تحديث الخريطة",
  _DISTANCE_MEASURE_: "قياس المسافة",
  _CREATE_ASSIGN_TRIP_: "إنشاء أو تعيين رحلة",
  _GRAPH_: "رسم بياني",
  _SERVICES_: "خدمات",
  _LOCATION_: "موقعك",
  _IGNITION_: "تشغيل",
  _DATE_TIME_: "تاريخ الوقت",
  _LAST_LOCATION_: "اخر موقع",
  _SEARCH_BY_VEHICLE_NO_: "البحث حسب رقم مركبةالأصول ",
  _SEARCH_BY_IMEI_NO_: "البحث عن طريق رقم IMEI ",
  _ASSET_: "مركبةالأصول",
  _MAINTENANCE_: "الصيانة",
  _USER_PROFILE_: "ملف تعريفي للمستخدم",
  _CONTACT_: "اتصل",
  _GEOFENCES_LIST_: "قائمة السياج الجغرافي /جيوفينس",
  _GPRS_COMMAND_: "أمر جي بي آر إس",
  _FLEET_GPRS_TEXT_ADDBTN_: "إضافة أمر",
  _WORKING_HOURS_REPORT_: "تقرير ساعات العمل  ",
  _ESN_POLL_REPORT_: "ESN تقرير الاقتراع",
  _FLEET_REPORTS_ESNPOLLING_TITLE_: "ESN الاقتراع",
  _FLEET_REPORTS_VEHICLEID_: "رقم المركبة",
  _FLEET_REPORTS_DATE_: "تاريخ",
  _FLEET_REPORTS_DRIVER_ID_: "معرف سائق",
  _FLEET_REPORTS_IN_HOURS_DISTANCE_: " في ساعات المسافة (كم)",
  _FLEET_REPORTS_OUT_HOURS_DISTANCE_: "مسافة خارج ساعات العمل (كم)",
  _FLEET_REPORTS_DISTANCE_: "المسافة (كم)",
  _FLEET_REPORTS_IN_HOURS_DURATION_: "في ساعات المدة",
  _FLEET_REPORTS_OUT_HOURS_DURATION_: "مدة خارج ساعات العمل",
  _FLEET_REPORTS_IN_HOURS_START_: "في ساعات ابدأ",
  _FLEET_REPORTS_IN_HOURS_END_: "في ساعات النهاية",
  _FLEET_DASHBORD_SETTING_TEMP1_: "درجة الحرارة 1",
  _FLEET_DASHBORD_SETTING_TEMP2_: "درجة الحرارة 2",
  _FLEET_DASHBORD_SETTING_TEMP3_: "درجة الحرارة 3",
  _FLEET_DASHBORD_SETTING_RFID_: "RF معرف ",
  _COMMAND_: "الأمر",
  _ALL_: "الكل",
  _EXCEL_: "إكسل",
  _CSV_: "CSV",
  _PDF_: "بي دي إف",
  _XML_: "XML",
  _POWER_: "قوة",
  _LAST_ROUTE_: "آخر مسار ",
  _BATTERY_: "البطارية",
  _SPEED_: "سرعة",
  _KEEP_AN_EYE_: "راقب",
  _STREET_VIEW_: "منظر الشارع",
  _OBD_DATA: "بيانات OBD",
  _SEND_COMMAND_: "إرسال الأمر",
  _IMMOBILIZER_ON_: "تشغيل منع الحركة",
  _IMMOBILIZER_OFF_: "ايقاف منع الحركة ",
  _DOOR_LOCK_: "قفل الباب",
  _DOOR_UNLOCK_: "فتح الباب ",
  _SHARE_LOCATION_: "مشاركة الموقع",
  _RLID_: "RL معرف ",
  _LOADING_: "جار التحميل",
  _DISMISS_: "رفض",
  _FLEET_REPORTS_POILIST_TITLE_: "قائمة نقطة الاهتمام",
  _FLEET_SERVICE_POILIST_SEARCH_: "البحث عن طريق اسم نقطة الاهتمام",
  _POI_MANAGEMENT_: "إدارة نقطة الاهتمام",
  _FLEET_REPORTS_POILIST_NAME_: " اسم نقطة الاهتمام",
  _FLEET_SERVICE_POILIST_MAP_: "خرائط",
  _FLEET_SERVICE_POILIST_VIEWMAP_: "عرض على الخريطة",
  _FLEET_SERVICE_POILIST_SHARE_: "شارك",
  _FLEET_SERVICE_POILIST_SHARELOCATION_: "مشاركة الموقع",
  _FLEET_SERVICE_RULEALERS_VALUE_: "القيمة",
  _FLEET_SERVICE_POILIST_SHARE_: "شارك",
  _FLEET_DASHBORD_NEWPOI_SHAREEBTN_: "شارك",
  _FLEET_DASHBORD_GPRS_COMMAND_REPORT_: "تقرير",
  _FLEET_SELECT_EVENT_: "اختر الحدث",
  _REPORTS_HEALTHCHECK_TITLE_: "تقرير الفحص الصحي",
  _KPA_DASHBOARD_: " لوحة القيادة KPA ",
  _COURSE_: "دورة",
  _HEIGHT_: "ارتفاع",
  _PID_: "معرفات المعامل",
  _CURRENT_TRIP_FUEL_CONSUMPTION_: "استهلاك الوقود الرحلة الحالية",
  _CURRENT_TRIP_MILEAGE_: "الأميال الرحلة الحالية",
  _CURRENT_TRIP_DURATION_: "مدة الرحلة الحالية",
  _MMCX_: "MMCX",
  _GSENSOR_1_XAXIS_: "الحساس جي1 X-المحور",
  _GSENSOR_1_YAXIS_: "الحساس جي1 Y-المحور",
  _GSENSOR_1_ZAXIS_: "الحساس جي1 Z-المحور",
  _GSENSOR_2_XAXIS_: "الحساس جي2 X-المحور",
  _GSENSOR_2_YAXIS_: "الحساس جي2 Y-المحور",
  _GSENSOR_2_ZAXIS_: "الحساس جي2 Z-المحور",
  _GSENSOR_3_XAXIS_: "الحساس جي3 X-المحور",
  _GSENSOR_3_YAXIS_: "الحساس جي3 Y-المحور",
  _GSENSOR_3_ZAXIS_: "الحساس جي3 Z-المحور",
  _GSENSOR_4_XAXIS_: "الحساس جي4 X-المحور",
  _GSENSOR_4_YAXIS_: "الحساس جي4 Y-المحور",
  _GSENSOR_4_ZAXIS_: "الحساس جي4 Z-المحور",
  _GSENSOR_5_XAXIS_: "الحساس جي5 X-المحور",
  _GSENSOR_5_YAXIS_: "الحساس جي5 Y-المحور",
  _GSENSOR_5_ZAXIS_: "الحساس جي5 Z-المحور",
  _VOLTAGE_: "فولت",
  _REPORTS_OBD_TITLE_: "تقرير OBD",
  _FLEET_REPORTS_AREAWISESPEED_TITLE_: "تقرير انتهاك السرعة حسب المنطقة",
  _DEFINED_SPEED_: "السرعة المحددة",
  _VEHICLE_SPEED_: "سرعة السيارة",
  _SPEED_LIMIT_: "الحد الأقصى للسرعة",
  _SPEED_AREA_: "منطقة السرعة",
  _OVERSPEED_DATE_: "تاريخ السرعة الزائدة",
  _PACKET_TYPE_: "نوع الرزمة",
  _LOAD_VOLTAGE_: "تحميل فولت",
  _IDENTITY_NUMBER_: "رقم الهوية",
  _COMPANY_IDENTITY_NUMBER_: "رقم هوية الشركة",
  _SELECT_COMPANY_IDENTITY_NUMBER_: "حدد رقم هوية الشركة",
  _COMMERCIAL_RECORD_NUMBER_: "رقم السجل التجاري",
  _COMMERCIAL_RECORD_ISSUE_DATE_: "تاريخ إصدار السجل التجاري بالهجري",
  _EXTENSION_NUMBER_: "رقم التحويلة",
  _MANAGER_NAME_: "اسم المدير",
  _MANAGER_PHONE_: "رقم هاتف المدير",
  _MANAGER_MOBILE_: "رقم موبايل مدير",
  _REPLY_: "الرد",
  _REGISTER_: "تسجيل",
  _WASL_KEY_: " مفتاح الوصل",
  _WASL_VEHICLE_LIST_: " قائمة السيارات الوصل",
  _SEQUENCE_NUMBER_: "رقم التسلسل",
  _PLATE_REGISTER_NUMBER_: "رقم تسجيل اللوحة",
  _NUMBER_: "رقم اللوحة",
  _RIGHT_LETTER: "حرف اللوحة اليمين",
  _MIDDLE_LETTER_: "حرف اللوحة الأوسط",
  _LEFT_LETTER_: " حرف اللوحة اليسار",
  _IMEI_NUMBER_: "IMEI رقم",
  _RESPONSE_: "استجابة",
  _PLATE_TYPE_: "نوع اللوحة",
  _WASL_VEHICLE_KEY_: "مفتاح سيارة الوصل ",
  _WASL_DRIVER_KEY_: "مفتاح سائق الوصل ",
  _DOB_: "تاريخ الميلاد",
  _DOB_FORMATE_: "تنسيق تاريخ الميلاد",
  _DOB_LABEL_: "تاريخ الولادة",
  _SELECT_DRIVER_: "اختر سائق",
  _WASL_: "الوصل",
  _WEIGHT_VOLT_: "الوزن / فولت",
  _LOAD_SENSOR_: "حساس الوزن",
  _TOTAL_TRUCK_WEIGHT_: "الوزن الإجمالي (كجم)",
  _EMPTY_TRUCK_WEIGHT_: "الوزن الفارغ  (كجم)",
  _LOAD_LUGGAGE_WEIGHT_: "الوزن الحمولة / الأمتعة (كجم)",
  _EMPTY_TRUCK_WEIGHT_: "وزن الشاحنة الفارغة (كجم)",
  _VEHICLE_STATUS_: "حالة المركبة",
  _WASL_REPLY_: "الوصل",
  _FLEET_MAINTENANCE_REPORT_: "تقرير صيانة الأسطول",
  _GENSET_WORKING_HOUR_REPORT_: "تقرير المولد لساعات العمل",
  _VEHICLE_PERFORMANCE_REPORT_: "تقرير أداء السيارة",
  _DATA_CONSUMPTION_REPORT_: "تقرير استهلاك البيانات",
  _WASL_REPORT_: "تقرير الوصل",
  _TOTAL_: "مجموع",
  _HALTED_FROM_: "توقف من",
  _HALTED_TILL_: "توقفت حتى",
  _EMAIL_IDS_: "عنوان الأيميلات",
  _SUBMIT_: "إرسال",
  _TOTAL_STOP_REPORT_: "تقرير التوقف الكلي",
  _FROM_DATE_: "من التاريخ",
  _TO_DATE_: "حتى الآن",
  _TOTAL_STOPPAGE_: "مجموع التوقف",
  _TOTAL_DURATION_: "المدة الإجمالية",
  _SUB_ACCOUNT_: "الحسابات الفرعية",
  _TOTAL_TRAVEL_REPORT_: "تقرير السفر إجمالي",
  _TOTAL_TRIPS_: "مجموع الرحلات",
  _TOTAL_TRAVEL_DURATION_: "مجموع مدة السفر",
  _TAG_ID_: "معرف الهوية",
  _ACTIVITY_: "نشاط",
  _TRIP_REPORT_MAP_: "تقرير الرحلة خريطة",
  _TOTAL_IDLE_REPORT_: "تقرير الخمول الكلي",
  _TOTAL_IDLING_COUNT_: "إجمالي عدد الخمول",
  _IDLE_SINCE_: "خامل من",
  _IDLE_FROM_: "خامل من",
  _IDLE_TO_: "خامل إلى",
  _IDLE_DURATION_: "مدة الخمول",
  _TOTAL_EX_IDLE_REPORT_: "إجمالي تقرير الخمول الزائد",
  _TOTAL_EXIDLING_COUNT_: "إجمالي عدد الخمول الزائد",
  _VEHICLE_MODEL_: "موديل المركبة",
  _DETAILED_DISTANCE_RECORD_: "سجل المسافة المفصل",
  _START_DATETIME_: " بدء التاريخ",
  _STOP_DATETIME_: " توقف التاريخ",
  _IDLE_TIME_: "وقت الخمول",
  _TRAVEL_TIME_: "وقت السفر",
  _EXIDLE_TIME_: "وقت الخمول الزائد",
  _TOTAL_STOP_: "إجمالي التوقف",
  _IDLE_COUNT_: "عدد الخمول",
  _OVER_SPEED_: "تجاوز السرعة",
  _ENERED_ODOMETER_: "دخلت عداد المسافات",
  _TOTAL_RUN_KM_: "مجموع تشغيل كم",
  _FUEL_FILLED_: "الوقود المستهلك",
  _FUEL_CONSUMPTION_: "استهلاك الوقود (لتر)",
  _AMOUNT_: "كمية",
  _VEHICLE_TYPE_: "نوع السيارة",
  _LAST_POLLED_DATETIME_: "آخر استطلاع تاريخ / وقت",
  _LAST_POLLED_LOCATION_: "آخر استطلاع للرأي الموقع",
  _GEOFENCE_SUMMARY_TOTAL_: "مجموع ملخص جيوفينس",
  _FS_IDLE_: "FS الخمول",
  _FS_TRAVEL_: "FS السفر",
  _RT_IDLE_: "RT الخمول",
  _RT_TRAVEL_: "RT السفر",
  _TOTAL_RT_TIME_: "وقت إجمالي RT (دقائق)",
  _TOTAL_FS_TIME_: "إجمالي وقت الخدمة الثابتة (دقائق)",
  _TOTAL_RT_COST_: "إجمالي تكلفة RT",
  _TOTAL_FS_COST_: "إجمالي تكاليف الخدمات المالية",
  _FREEZER_: "الفريزر",
  _CHILLER_: "مبرد",
  _DRY_: "جاف",
  _INSTALLATION_DATE_: "تاريخ التثبيت",
  _DEVICE_: "جهاز",
  _DRIVER_PERFORMANCE_TOTAL_: "اداء السائق المجموع",
  _NO_OF_VEHICLES_: "عدد المركبات المستخدمة",
  _KM_: "كم",
  _TRAVEL_: "السفر",
  _IDLE_: "خامل",
  _EX_IDLE_: "الخمول الزائد",
  _MAX_SPEED_AS_LIMIT_SET_: "السرعة القصوى حسب الحد المسموح به",
  _COMPLAIN_: "تذمر",
  _COMPLAIN_DATETIME_: "تاريخ الوقت الشكوى",
  _OVER_TIME_REPORT_: "تقرير ساعات العمل الإضافي",
  _WORKING_HR_: "ساعات العمل (وقت السفر)",
  _ALLOCATE_WORKING_HR_: "تخصيص ساعة العمل",
  _OVERTIME_HOURS_: "ساعات العمل الإضافي",
  _OVERTIME_CHARGE_: "رسوم العمل الإضافي",
  _LOGIN_DATETIME_: "وقت تسجيل الدخول",
  _LOGIN_IP_: "تسجيل الدخول IP",
  _LOGIN_LOCATION_: "موقع تسجيل الدخول",
  _CONTACT_NO_: "رقم الاتصال",
  _ACCOUNT_STATUS_: "حالة الحساب",
  _REPORT_NAME_: "اسم التقرير",
  _FREQUENCY_: "تكرر",
  _RAW_DATA_: "مسودة بيانات",
  _CUSTOMER_TYPE_: "نوع العميل",
  _MAILID_: "عنوان الايميل",
  _CC_: "البريد الإلكتروني نسخة",
  _PASSENGER_TAGID_: "رقم تعريف الراكب",
  _PASSENGER_NAME_: "اسم الراكب",
  _LOGIN_LOCATION_: "تسجيل الدخول الموقع",
  _LOGOUT_LOCATION_: "تسجيل الخروج الموقع",
  _TOTAL_ATTENDANCE_DETAILS_: "إجمالي تفاصيل الحضور",
  _DRIVER_ID_: "معرف السائق",
  _TOTAL_WORKING_HOURS_: "مجموع ساعات العمل",
  _TOTAL_OVERTIME_: "إجمالي الوقت الإضافي",
  _PUNCH_IN_: "لكمة في",
  _PUNCH_OUT_: "لكمة خارج",
  _DAILY_WORKING_HOURS_: "ساعات العمل اليومية",
  _OVERTIME_: "الوقت الإضافي",
  _DIFFERENCE_: "فرق",
  _TRACKING_REPORT_: "تقرير تتبع",
  _USAGE_: "استعمال",
  _MULTISCREEN_: "متعدد الشاشات",
  _DEVICE_LOG_: "سجل الجهاز",
  _ASSET_DEVICE_ASSIGNMENT_: "تعيينات جهاز الأصول",
  _TASK_MANAGEMENT_: "ادارة المهام",
  _FUEL_CONSUMPTION_: "استهلاك الوقود",
  _WORKING_HOUR_: "ساعة العمل",
  _PASSENGER_: "راكب",
  _VEHICLE_PERFORMANCE_TOTAL_: "أداء مركبة المجموع",
  _VEHICLE_: "المركبات",
  _TOTAL_DRIVER_: "مجموع السائق",
  _TOTAL_KM_: "مجموع الكيلومترات",
  _TOTAL_TRAVEL_: "مجموع السفر",
  _TOTAL_IDLE_: "مجموع الخمول",
  _TOTAL_EXC_IDLE_: "مجموع الخمول الزائد",
  _TOTAL_OVER_SPEED_: "إجمالي السرعة الزائدة",
  _TOTAL_OVER_SPEED_AS_ROAD_SPEED_: "إجمالي السرعة الزائدة حسب سرعة الطريق",
  _VEHICLE_PERFORMANCE_DETAILS_: "تفاصيل أداء السيارة",
  _STOPPAGE_: "إضراب",
  _DATA_LOADING_: "تحميل....البيانات",
  _OVER_SPEED_AS_PER_THE_ROAD_SPEED_: "السرعة الزائدة حسب سرعة الطريق",
  _WASL_REPLY_: " الوصل",
  _LOCATION_TIME_: "وقت الموقع",
  _ROLE_CODE_: "رمز الدور",
  _REGISTRATION_TYPE_: "نوع التسجيل",
  _RESPONSE_TIME_: "وقت الاستجابة",
  _BYTES_RECEIVED_: "بايت المتلقاة",
  _VIN_: "رقم VIN",
  _CONNECT_: "الاتصال",
  _SUPPORT_PID_: "دعم Pid",
  _RPM_: "RPM",
  _COOLANT_TEMP_: "المبرد درجة الحرارة",
  _DTC__CLEARED_: "DTC مسح الأميال",
  _MIL_ACTIVATED_DISTANCE_: "ميل تنشيط المسافة",
  _MIL_STATUS_: "حالة ميل",
  _DTC_NUMBER_: "رقم DTC",
  _DIAGNOSTIC_TROUBLE_CODE_: "رمز تشخيص المشكلة",
  _THROTLE_POSITION_: "موقف خنق",
  _ENGINE_LOAD_: "حمولة المحرك",
  _FUEL_LEVEL_INPUT_: "مستوى الوقود الإدخال",
  _PROTOCOL_: "بروتوكول",
  _MILAGE_: "كيلومتر",
  _ACCOUNT_ID_: "معرف الحساب",
  _ACCOUNT_ALISE_: "الاسم المستعار للحساب",
  _POSTAL_CODE_: "رمز البريدى",
  _TAX_ID_: "الرقم الضريبي",
  _ACCOUNT_SETTINGS_: "إعدادت الحساب",
  _INDUSTRY_: "صناعة",
  _ACCOUNT_CREATED_: "تم إنشاء الحساب",
  _PAYMENT_TERM_: "شروط الدفع",
  _MAIN_CONTACT_: "الاتصال الرئيسية",
  _ADMINISTRATIVE_CONTACT_: "الاتصال الإداري",
  _TECHNICAL_CONTACT_: "الاتصال التقني",
  _WEBSITE_: "موقع الكتروني",
  _SHOW_HIDE_PASSWORD_: "إظهار / إخفاء كلمة المرور",
  _CREATED_BY_: "صنع من قبل",
  _PHOTO_ID_: "صورة شخصية (75 * 75)",
  _EMPLOYMENT_STATUS_: "الحالة الوظيفية",
  _NATIONALITY_: "جنسية",
  _TAG_ID_: "معرف الوسم",
  _EMPLOYEE_INFORMATION_: "معلومات الموظف",
  _EMARGENCY_INFORMATION_: "معلومات الطوارئ",
  _EMPLOYEE_ID_: "هوية الموظف",
  _TRACKING_DETAILS_: "تتبع التفاصيل",
  _TRACK_: "مسار",
  _WORKING_HOUR_: "ساعات العمل",
  _OVERTIME_CHARGES_: "رسوم العمل الإضافي",
  _SEARCH_BRAND_: "بحث العلامة التجارية",
  _SEARCH_DRIVER_: "بحث السائق",
  _SEARCH_SIM_NO_: "بحث رقم الشريحة",
  _SEARCH_IMEI_: "بحث IMEI",
  _SEARCH_AS_: "بحث باسم",
  _HOME_LOCATION_: "موقع المنزل",
  _PAYMENT_TYPE_: "نوع الدفع",
  _COMMUNICATION_: "الاتصالات",
  _ASSET_EXPIRY_DATE_: "تاريخ انتهاء صلاحية الأصول",
  _ATTENDANT_MOBILE_NO_: "رقم موبايل المصاحبة",
  _PLATE_NO_: "رقم الهوية / اللوحة",
  _INSTALLER_: "المثبت",
  _INSATALLED_LOCATION_: "الموقع المثبت",
  _FUEL_SENSOR_: "الحساس الوقود",
  _TEMP_SENSOR_: "الحساس درجة الحرارة",
  _CANBUS_OBD_: "canbus/OBD",
  _LOCK_ALARM_: "قفل / إنذار",
  _ASSET_IMAGE_: "صورة الأصول (150 * 150)",
  _ATTACHED_PICTURE_: "الصورة المرفقة",
  _ADMINISTRATIVE_SETTINGS_: "الإعدادات الإدارية",
  _ASSET_PICTURE_: "صورة الأصول",
  _DEVICE_SETTINGS_: "إعدادات الجهاز",
  _CONNECTED_ACCESORIES_: "الإكسسوارات المتصلة",
  _FUELID1_: "معرف الوقود 1",
  _FUELID2_: "معرف الوقود 2",
  _SENSOR1_: "الحساس 1",
  _SPEED_LIMIT_: "الحد الأقصى للسرعة",
  _IDLE_LIMIT_: "حد الخمول",
  _MAINTENANCE_MODULE_SETTING_: "إعدادات وحدة الصيانة",
  _DIFF_ODOMETER_: "ديف عداد المسافات",
  _SERVICE_INTERVAL_: "فترة الخدمة (كم)",
  _SERVICE_INTERVAL_DAYS: "فترة الخدمة (بالأيام)",
  _OIL_CHANGE_INTERVAL_: "تغيير الزيت الفاصل (كم)",
  _TIRE_CHANGE_KM_: "تغيير الإطارات الفاصل (كم)",
  _MACHINE_RUNNING_HR_: "ساعات تشغيل الجهاز",
  _SERVICING_HR_: "ساعات الخدمة",
  _FUEL_MODULE_SETTING_: "إعدادات وحدة الوقود",
  _ASSET_MILAGE_: "الأصول الأميال (كم / لتر)",
  _FUEL_CAPACITY1_: "سعة الوقود 1 (لتر)",
  _FUEL_CAPACITY2_: "سعة الوقود 2 (لتر)",
  _HEAVY_VEHICLE_: "المركبات الثقيلة",
  _LIGHT_VEHICLE_: "وقود خفيف",
  _DRIVING_DETAILS_: "تفاصيل القيادة",
  _ASSET_VEHICLE_: "الأصول / السيارات",
  _TAG_ID1_: "معرف الهوية 1",
  _TAG_ID2_: "معرف الهوية 2",
  _PERMIT_NO_: "رقم الهوية/التصريح",
  _PERMIT_EXPIRY_DATE_: "تاريخ انتهاء الصلاحية",
  _VISA_EXPIRY_DATE_: "تاريخ انتهاء التأشيرة",
  _CONTACT_PERSON_NAME_: "اسم شخص الاتصال",
  _CONTACT_PERSON_EMAILID_: "البريد الإلكتروني للأشخاص الاتصال",
  _SHIFT_NAME_: "تحول الاسم",
  _ASSIGN_ASSET_: "تعيين الأصول",
  _ADD_WORKING_HOURS_: "إضافة ساعات العمل",
  _ADD_LOCATION_: "أضف الموقع",
  _WORKING_TIME_FROM_: "وقت العمل من",
  _WORKING_TIME_TO_: "وقت العمل إلى",
  _SELECT_TYPE_: "اختر صنف",
  _EMPLOYEE_NAME_: "اسم الموظف",
  _DESIGNATION_: "المنصب",
  _DEPARTMENT_: "قسم",
  _LOCATION_: "موقع",
  _PICK_UP_TIME_: "وقت الركوب",
  _DROP_OFF_TIME_: "وقت النزول",
  //_ADD_PASSENGER_: 'إضافة الركاب',
  _REMOVE_: "إزالة",
  _IDNO_: "رقم بطاقة الهوية",
  _BLOOD_GROUP_: "فصيلة الدم",
  //_PASSENGER_TYPE_: 'نوع الركاب',
  _WASL_COMPANY_LIST_: "قائمة شركة الوصل",
  _ACCOUNT_LIST_: "قائمة الحساب",
  _USER_PROFILE_LIST_: "قائمة ملف تعريف المستخدم",
  _RECORD_: "سجل",
  _SEARCH_DERIVER_NAME_: "البحث حسب اسم السائق",
  _SEARCH_MOBILENO_: "البحث عن طريق رقم الجوال",
  _OFFICE_LOCATION_LIST_: "قائمة موقع المكتب",
  _WORKING_HOUR_LIST_: "قائمة ساعات العمل",
  //_PASSENGER_LIST_: 'قائمة الركاب',
  _BUSNO_: "رقم الحافلة",
  _UPLOAD_: "رفع",
  _DOWNLOAD_SAMPLE_EXCEL_: "Downdload عينة أكسل",
  _MODIFIEDBY_: "تم التعديل بواسطة",
  _FIELD_NAME_: "اسم الحقل",
  _BEFORE_: "قبل",
  _AFTER_: "بعد",
  _ASSIGN_JOB_: "تعيين العمل",
  _JOB_: "العمل",
  _DELIVERY_DATE_: "تاريخ التسليم او الوصول",
  _DELIVERY_TIME_: "موعد التسليم",
  _HALT_TIME_: "توقف الوقت",
  _NOTE_TO_DRIVER_: "ملاحظة للسائق",
  _SEARCHBY_: "البحث بواسطة",
  _CUSTOMER_ADDRESS_: "عنوان العميل",
  _CUST_NAME_: "اسم الزبون",
  _CUST_MOBILENO_: "رقم العميل المحمول",
  _NOTIFICATION_MAIL_: "البريد الإلكتروني الإخطار",
  _ADD_TASK_: "إضافة تفاصيل المهمة",
  _UPLOAD_INVOICE_: "تحميل الفاتورة",
  _QUANTITY_: "كمية",
  _JOB_TO_: "العمل إلى",
  _JOB_DATE_: "تاريخ العمل",
  _JOB_TIME_: "وقت العمل",
  _JOB_TYPE_: "نوع العمل",
  _ALLOCATE_TO_: "تخصيص",
  _CURRENT_VEHICLE_CONDITION_: "حالة المركبة الحالية",
  _DUEDATE_: "تاريخ الاستحقاق",
  _ALLOCATE_VEHICLE_: "تخصيص المركبات",
  _PROJECT_NAME_: "اسم المشروع / الشركة",
  _PAYMENT_MODE_: "طريقة الدفع",
  _TOTAL_AMOUNT_: "المبلغ الإجمالي",
  _GRAPHS_: "الرسوم البيانية",
  _SPEED_GRAPH_: " الرسم البياني السرعة",
  _DISTANCE_GRAPH_: "رسم بياني لمسافة المقطوعة",
  _IDLE_GRAPH_: "الرسم البياني الخمول",
  _FUEL_GRAPH_: "الرسم البياني الوقود",
  _USAGE_GRAPH_: "الرسم البياني الاستخدام",
  _TEMP_GRAPH_: "الرسم البياني الدرجة الحرارة",
  _DASHBOARD_GRAPH_: " الرسم البياني لوحة القيادة",
  _MULTISCREEN_GRAPH_: "الرسم البياني متعدد الشاشات",
  _DOWNLOAD_: "تحميل",
  _SPEED_VS_TIME_: "الرسم البياني سرعة مقابل الوقت",
  _FROM_DATE_TIME_: "من تاريخ الوقت",
  _SPEED_KM_PER_HOUR_: "السرعة (الكيلومتر / ساعة)",
  _TIME_MIN_: "الوقت (دقيقة)",
  _KM_PER_HOUR_: "(الكيلومتر / ساعة)",
  _FOR_: "إلى",
  _HOURS_: "الساعات",
  _MIN_: " دقيقة",
  _TOTAL_TRAVEL_DISTANCE: "مجموع مسافة السفر",
  _DISTANCE_NOT_APPLICABLE: "إجمالي مسافة السفر غير متوفرة",
  _IDLE_IN_MINUTE: " الخمول (بالدقائق)",
  _IDLE_DURATION_IN_MINUTE: "مدة الخمول (بالدقائق)",
  _SERVICE_DATE_: "تاريخ الخدمة",
  _SERVICE_TYPE_: "نوع الخدمة",
  _NEW_SERVICE_TYPE_: "نوع الخدمة الجديد",
  _EXTRA_WORK_DONE_: "اعمال اضافية",
  _TOTAL_DAYS_USED_: "مجموع الأيام المستخدمة",
  _TOLL_USED_: "رسوم الطريق مستخدم",
  _TOLL_CHARGES_: "رسوم لعبور",
  _VEHICLE_DAMAGE_CHARGE_: "تهمة الضرر السيارة",
  _TRAFFIC_FINE_: "مخالفة المرور",
  _TOTAL_AMOUNT_CHARGABLE_: "المبلغ الإجمالي تحميلها",
  _INITIAL_ODOMETER_: "عداد المسافات مبدئي",
  _INITIAL_VEHICLE_CONDTION_: "حالة المركبة مبدئي",
  _VEHICLE_PRESENT_CONDTION_: "حالة المركبة الحالية",
  _RETURN_ODOMETER_: " عداد المسافات العودة",
  _SEQUENCE_: "تسلسل",
  _STOP_IDLE_POINTS_: "نقاط التوقف / الخمول",
  _SELECT_ADDRESS_: "اختر العنوان",
  _SUNDAY_: "الأحد",
  _MONDAY_: " الإثنين",
  _TUESDAY_: "الثلاثاء",
  _WEDNESDAY_: "الأربعاء",
  _THURSDAY_: "الخميس",
  _FRIDAY_: "الجمعة",
  _SATURDAY_: "السبت",
  _VALID_FROM_: "صالح من تاريخ",
  _VALID_TO_: "صالحة إلى",
  _ZONE_: "منطقة",
  _POINT_NAME_: "اسم النقطة",
  _NO_OF_STUDENTS_: "عدد الطلاب",
  _SEQUENCE_NO_: "رقم التسلسل",
  _TRAVEL_DISTANCE_: "مسافة السفر",
  _SELECT_DAYS_: "اختر أيام",
  _FUEL_FILLED_DETAILS_: "تفاصيل تعبئة الوقود",
  _SEND_PUSH_NOTIFICATION_: "إرسال إشعار تنبيه",
  _ALERT_SETTING_: "تنبيه الإعداد",
  _INSIDE_GEOFENCE_: "داخل جيوفنس/السياج الجغرافي",
  // _TIRE_CHANGE_REMINDER_: "تذكير تغيير الإطارات",
  _OIL_CHANGE_REMINDER_: "تذكير تغيير الزيت",
  _AREAWISE_SPEED_VIOLATION_: "السرعة انتهاك بحسب المنطقة",
  _POWER_CUT_: "انقطاع الطاقة",
  _ALLOCATE_ASSET_LIST_: "تخصيص قائمة الأصول",
  _RETURN_ASSET_LIST_: "إرجاع قائمة الأصول",
  _MAINTENANCE_DETAILS_: "تفاصيل الصيانة",
  _SEARCH_BY_GEOFENCE_NAME_: "البحث حسب اسم السياج الجغرافي/جيوفينس",
  _TRIP_DETAILS_ASSIGN_: "إنشاء / تعيين تفاصيل الرحلة",
  _TASK_MANNAGE_LIST_: "قائمة إدارة المهام",
  _FUEL_DETAIL_LIST_: "قائمة تفاصيل الوقود",
  _FUEL_FILLED_LIST_: "قائمة تعبئة الوقود",
  _FINISH_JOB_: "إنهاء العمل",
  _CONTACT_INFORMATION_: "معلومات للتواصل",
  _BROWSE_: "تصفح",
  _TEMPID1_: "معرف درجة الحرارة 1",
  _TEMPID2_: "معرف درجة الحرارة 2",
  _TEMPID3_: "معرف درجة الحرارة 3",
  _TOTAL_ASSET_COST_: "إجمالي تكلفة الأصول",
  _LOW_LIMIT1_: "حد منخفض 1",
  _HIGH_LIMIT1_: "ارتفاع الحد 1",
  _SENSOR2_: "الحساس 2",
  _LOW_LIMIT2_: "حد منخفض 2",
  _HIGH_LIMIT2_: "ارتفاع الحد 2",
  _CLICKTOUPLOAD_EXCELFILE_: "انقر لتحميل ملف Excel",
  _SERATER_: "مقاعد",
  _EDIT_GRID_TIMING_: "تعديل توقيت الدرجة",
  _confirmation_: "هل أنت متأكد أنك تريد حذف السجل؟",
  _COMPANY_VEHICLES_: "مركبات الشركة",
  _GROUP_VEHICLES_: "مركبات المجموعة",
  _EMPTY_TRUCK_WEIGHT_KG_: "وزن الشاحنة الفارغة (كجم)",
  _ENTER_LOCATION_: "إدخال الدولة",
  _ADD_MAINTENANCE_DETAILS_: "إضافة تفاصيل الصيانة",
  _DELETE_GRID_TIMING_: "حذف توقيت الدرجة",
  _EXCEL_FILE_: "ملف اكسل",
  _SELECT_EXCEL_TO_UPLOAD_: "يرجى اختيار ملف اكسل",
  _FILE_UPLOAD_: "رفع الملف",
  _NOTIFICATIOS_: "إخطارات",
  _SELECT_ALERT_TYPE_: "حدد نوع التنبيه",
  _SHOW_GEOFENCES_: "أعرض جيوفينس/السياج الجغرافي",
  _SHOW_ALL_GEOFENCE_: "اظهار جميع جيوفينس/السياج الجغرافي",
  _CURRENT_VEHICLE_LOCATION_: "الموقع الحالية للمركبة",
  _MOVING_VEHICLES_: "المركبات المتحركة",
  _STOPPED_VEHICLES_: "المركبات المتوقفة",
  _IDLING_VEHICLES_: "المركبات الخاملة",
  _NOT_REPORTING_VEHICLES_: "المركبات عدم الإبلاغ",
  _EX_IDLE_: "خامل الزائد",
  _TOTAL_VEHICLES_: "مجموع المركبات",
  _LOCK_UNLOCK_MAP_: "قفل / فتح الخريطة",
  _CLEAR_LAST_ROUTE_: "مسح الطريق الأخير",
  _EXPORT_DETAILS_: "تفاصيل التصدير",
  _SELECT_FROM_HISTORY_: "اختر من التاريخ",
  _DISCARD_CREATE_NEW_: "تجاهل وإنشاء جديد",
  _LOAD_: "حمل",
  _SEARCH_ADDRESS_: "عنوان البحث",
  _SEARCH_GEOFENCE_: "البحث جيوفينس/السياج الجغرافي",
  _SEARCH_: "بحث",
  _ENTER_DISTANCE_: "أدخل المسافة",
  _SEARCH_NEARST_VEHICLE_: "بحث أقرب سيارة",
  _CLICK_ON_MAP_: "انقر على الخريطة",
  _STOPPED_: "توقفت",
  _RUNNING_: "جري/تشغيل",
  _IDLE_: "خامل",
  _EX_IDLE_: "خامل الزائد",
  _NO_GPS_: "لا يوجد جي بي أس",
  _NO_DATA_: "لايوجد بيانات",
  _SEC_: "(ثانيا)",
  _SEARCH_BY_ESN_: "البحث عن طريق ESN",
  _SELECT_ROUT_OPTION_: "حدد خيار الطريق",
  _SELECT_TRIP_EVENT_: "حدد حدث الرحلة",
  _SELECT_SPEED_: "اختر السرعة",
  _PAUSE_: "وقفة",
  _PLAY_: "شغل",
  _ASSET_HISTORY_DETAILS_: "تفاصيل تاريخ مركبةالأصول",
  _HEATMAP_: "خريطة/مخطط حراري",
  _UPDATE_DATE_: "تاريخ التحديث",
  _VEHICLE_REFERENCE_KEY_: "مفتاح مرجع السيارة",
  _DRIVER_REFERENCE_KEY_: "مفتاح مرجع السائق",
  _VELOCITY_: "فيلوسيتي(سرعة في اتجاه معين)",
  _WEIGHT_: "الوزن ",
  _TOTAL_OF_ASSETS_: "إجمالي عدد الأصول: ",
  _FUEL_CONSUMED_: "استهلاك الوقود",
  _MONITOR_CENTER_: "مركز المراقبة",
  _ROUTING_DASHBOARD_: "لوحة القيادة التوجيه",
  _ALERT_TYPE_: "نوع التنبيه",
  _SEARCH_DERIVER_: "البحث عن طريق السائق",
  _IDLE_DURATION_IN_MINUTE_: "مدة الخمول (بالدقائق) :",
  _TEMPERATURE_GRAPH_: "الرسم البياني لدرجات الحرارة",
  _TEMP_SENSOR1_: "الحساس الدرجة الحرارة 1",
  _TEMP_SENSOR2_: "الحساس الدرجة الحرارة 2",
  _TEMP_SENSOR3_: "الحساس الدرجة الحرارة 3",

  _TEMPERATURE_SENSOR1_: "الحساس الدرجة الحرارة 1",
  _TEMPERATURE_SENSOR2_: "الحساس الدرجة الحرارة 2",
  _TEMPERATURE_SENSOR3_: "الحساس الدرجة الحرارة 3",
  _SHOW_ALL_: "عرض الكل",
  _GEOFENCE_VISIT_REPORT_: "تقرير زيارة السياج الجغرافي ",
  _GEOFENCE_VISIT_: "زيارة جيوفينس/السياج الجغرافي",
  _GEOFENCE_: "جيوفينس/السياج الجغرافي",
  _TRAILER_ID_: "معرف المقطورات",
  _TRAILER_TYPE_: "نوع المقطورة",
  _TRAILER_EMPTY_WEIGHT_: "وزن المقطورة فارغة",
  _ADD_TRAILER_: "أضافة مقطورة",
  _TRAILER_LIST_: "قائمة المقطورة",
  _TAILER_: "مقطورة (خلفية الشاحنة)",
  _ATTACH_TAILER_: "ربط المقطورة (خلفية الشاحنة)",
  _SEARCH_BY_ZONE: "البحث حسب المنطقة",
  _HEAD_WEIGHT_: "وزن الرأس (الشاحنة)",
  _ASSIGN_TO_: "تعين إلى",
  _GEOFENCE_ZONE_: "منطقة جيوفينس/السياج الجغرافي",
  _DELETE_RECORD_: "حذف السجل",
  _REMOVE_RECORD_: "إزالة السجل",
  _DELETE_CONFIRM_: "هل تريد أن تحذف ؟",
  _REMOVING_DATA_: "إزالة البيانات",
  _ADD_ZONE_: "أضافة المنطقة",
  _ZONE_ADD_SUCCESS_: "تمت إضافة المنطقة بنجاح",
  _DELETE_VEHICLE_FROM_WASL_: "هل تريد حذف المركبة من وصل؟",
  _WASL_STATUS_: "حالة وصل",
  _ALARM_EVENT_: "حدث التنبيه",
  _EVENT_NAME_: "أسم التنبيه",
  _MINIMUM_VOLT_: "فولت الأدنى",
  _MAXIMUM_VOLT_: "أقصى فولت",
  _VEHICLE_GROUPS_: "مجموعات المركبات",
  _TRAILER_ID_: "معرف المقطورة (خلفية الشاحنة)",
  _TRAILER_TYPE_: "رقم المقطورة (خلفية الشاحنة)",
  _SPONSOR_NAME_: "أسم صاحب العمل",
  _SPONSOR_: "صاحب العمل",
  _FINE_MANAGEMENT_: "إدارة المخالفات",
  _FLEET_REPORTS_FINELIST_TITLE_: "قائمة المخالفات",
  _FLEET_FINE_FINELIST_ADDBTN_: " إضافة",
  _FLEET_DASHBORD_FINE_FIELD_: "تفاصيل حقل المخالفة",
  _MILITARY_PASS_NO_: "رقم التصريح العسكري",
  _MILITARY_PASS_EXPIRY_DATE_: "تاريخ انتهاء التصريح العسكري",
  _MVPI_EXPIRY_DATE_: "تاريخ انتهاء صلاحية الفحص الدوري",
  _PURCHASE_DATE_: "تاريخ الشراء",
  _REGISTRATION_NO_: "رقم التسجيل",
  _REGISTRATION_EXPIRY_DATE_: "تاريخ انتهاء التسجيل/الرخصة السير",
  _INSURANCE_NO_: "رقم التأمين",
  _INSURANCE_EXPIRY_DATE_: "تاريخ انتهاء التأمين",
  _WARRANTY_DATE_: "تاريخ الضمان",
  _DOCUMENT_: "وثيقة",
  _SELECT_DOCUMENT_: "يرجى تحديد الوثيقة",
  _UPLOADING_: "جارٍي التحميل",
  _FILE_UPLOADED_: "تم تحميل ملفك.",
  _FLEET_FINE_FINEDETAIL_SAVEBTN_: "حفظ",
  _FLEET_FINE_FINEDETAIL_BACKBTN_: "العودة",
  _FLEET_FINE_NAME_: "اسم المخالفة",
  _FLEET_FINE_NUMBER_: "رقم المخالفة",
  _FLEET_FINE_VEHICLE_COMPANY_NUMBER_: "رقم المركبة في الشركة",
  _FLEET_FINE_FROM_DATE_: "من التاريخ",
  _FLEET_FINE_FROM_TIME_: " من الوقت",
  _FLEET_FINE_TO_DATE_: "حتى الآن",
  _FLEET_FINE_TO_TIME_: " الى الوقت",
  _FLEET_FINE_DESCRIPTION_: "الوصف المخالفة",
  _FLEET_FINE_AMOUNT_: "مبلغ المخالفة",
  _FINE_STATUS_: "حالة المخالفة",
  _FLEET_FINE_DRIVERNAME_: "السائق المعين",
  _FLEET_FINE_VEHICLENO_: "رقم المركبة",
  _FINE_FINEDETAIL_SETLOAD_: "جاري الحفظ المخالفة",
  _PASSENGER_: "موظف",
  _ADD_PASSENGER_: "إضافة الموظف",
  _PASSENGER_TYPE_: "نوع الموظف",
  _PASSENGER_LIST_: "قائمة الموظف",
  _ASSIGNMENT_TYPE_: "نوع المهمة",
  _FLEET_FINE_DATE_: "تاريخ المخالفة",
  _FLEET_FINE_TIME_: "وقت المخالفة",
  _FLEET_FINE_FINELIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_FINE_FINELIST_YESBTN_: "نعم فعلا",
  _PART_MANAGEMENT_: "إدارة الجزء",
  _FLEET_REPORTS_PARTLIST_TITLE_: "قائمة الأجزاء",
  _FLEET_DASHBORD_PART_FIELD_: "تفاصيل ملف تعريف القطعة",
  _FLEET_PART_NUMBER_: "رقم القطعة",
  _FLEET_MANUFACTURE_: "صناعة",
  _FLEET_QUALITY_ON_HAND_: "كمية في اليد",
  _FLEET_COST_NUMBER_: "التكلفة لكل جزء",
  _FLEET_TYPE_: "نوع",
  _FLEET_SUPPORTED_VEHICLE_: "مركبة مدعومة",
  _FLEET_PART_PARTDETAIL_BACKBTN_: "العودة",
  _FLEET_PART_PARTDETAIL_SAVEBTN_: "حفظ",
  _FLEET_PART_PARTDETAIL_CANCELBTN_: "إلغاء",
  _FLEET_PART_PARTLIST_REMOVEMSG_: "أنت تريد إزالة متأكد؟",
  _FLEET_PART_PARTLIST_YESBTN_: "نعم فعلا",
  _FLEET_PART_PARTLIST_ADDBTN_: " إضافة",
  _LICENSE_TYPE_: "نوع الرخصة",
  _FLEET_SERVICE_PARTLIST_SEARCH_: "البحث رقم القطعة",
  _FLEET_SERVICE_MANUFACTURELIST_SEARCH_: "البحث عن الشركة المصنعة",
  _ORDERS_MANAGEMENT_: "إدارة الطلبات",
  _ORDERSLIST_TITLE_: "قائمة الطالبات العمل",
  _ORDERLIST_ADDBTN_: "إضافة",
  _ORDER_NUMBER_: "رقم الأمر",
  _DASHBORD_ORDER_FIELD_: "تفاصيل أمر العمل",
  _ORDER_NUMBER_: "رقم أمر العمل ",
  _COMPANY_NUMBER_: "رقم المركبة في الشركة",
  _TYPE_OF_SERVICE_: "نوع الخدمة",
  _TECHNICIAN_NAME_: "اسم الفني",
  _WORK_TIME_: "وقت العمل (ساعة)",
  _PARTSDETAIL_PARTSBTN_: "قطع غيار",
  _DATE_IN_: "تاريخ الدخول",
  _DATE_OUT_: "تاريخ الخروج",
  _SOLUTION_: "حل",
  _ORDER_STATUS_: "الحالة",
  _WORK_ORDER_: "أمر عمل",
  _DASHBORD_SUMMARY_REPORT_FIELD_: "تقرير تفاصبل الخلاصة",
  _FLEET_VEHICLE_NUMBER_: "رقم المركبة",
  _START_LOADING_TIME_: "وقت ابدأ التحميل",
  _STOP_LOADING_TIME_: "وقت التنزيل",
  _START_LOCATION_: "ابدأ الموقع",
  _STOP_LOCATION_: "موقع النهاية",
  _VEHICLE_RUN_: "إجمالي تشغيل كيلوميتر",
  _T_STOP_TIME_: "وقت التوقف الكلي",
  _REMARKS_: "ملاحظات (قابلة للتعديل)",
  _LOAD_SUMMARY_REPORT_: "تحميل تقرير الملخص",
  _QTY_AVAILABLE_: "الكمية متاحة",
  _QUANTITY_NOT_AVAILABLE_: "يجب ألا تتجاوز الكمية المتاحة!!",
  _START_VOLTAGE_: "بدء فولت",
  _END_VOLTAGE_: "نهاية فولت",
  _START_WEIGHT_: "الوزن الأبدأ",
  _END_WEIGHT_: "الوزن النهائي",
  _START_DISTANCE_: "بدء المسافة",
  _END_DISTANCE_: "نهاية المسافة",
  _START_LATITUDE_: "بداية الخط العرض",
  _END_LATITUDE_: "نهاية الخط العرض",
  _LATITUDE_: "",
  _START_LONGITUDE_: "ابدأ خط الطول",
  _END_LONGITUDE_: "نهاية خط الطول",
  _ESN_: "الرقم التسلسلي الإلكتروني",
  _DRIVER_SCORE_REPORT_: "تقرير نقاط السائق",
  _ACCEL_N_: "التسريع / دواسة",
  _BRAKE_: "فرامل",
  _EXIDLE_: "الخمول الزائد",
  _SCORE_: "الدرجات",
  _HARSH_ACCELERATION_: "تتسارع السيارة المفاجئ",
  _HARSH_BRACKING_: "فرملة السيارة المفاجأة ",
  _EX_IDELING_: "خالخمول الزائد ",
  _DRIVER_BEHAVIOR_SETTINGS_: "إعدادات سلوك السائق",
  _SPEEDING_: "مسرعة",
  _RPM_EXCESS_: "الدورات في الدقيقة",
  _Min_Mileage_: "الحد الأدنى. الأميال (كم)",
  _DISPLAY_ABSOLUTE_VAL_: "عرض القيمة المطلقة",
  _TOP_SCORE_: "أعلى الدرجة",
  _USER_COSTIMIZED_SCORE_: "درجة المخصصة للمستخدم",
  _ASSET_OR_VEHICLES_: "الأصول أو المركبات",
  _ASSET_DEVICE_MANAGEMENT_: "تعيينات جهاز الأصول",
  _RPM_LIMIT_: "الحد ثورة كل دقيقة",
  _MIN_ALLOWED_EVENTS_: "الحد الأدنى المسموح به من الأحداث",
  _ABSOLUTE_VAL_: "قيمه مطلقه",
  _DATE_: "تاريخ",
  _ACC_EVENT_: "تنبيه التسريع / دواسة",
  _BRAKE_EVENT_: "تنبيه الفرمل",
  _SPEED_EVENT_: "تنبيه السرعة",
  _EXIDLE_EVENT_: "تنبيه الخمول الزائد",
  _RPM_EVENT_: "تنبيه ثورة كل دقيقة",
  _BRAKE_RATE_: "نسبة الفرامل",
  _ACC_RATE_: "نسبة التسريع / دواسة",
  _SPEED_RATE_: "نسبة السرعة",
  _EXIDLE_RATE_: "نسبة الخمول الزائد",
  _RPM_RATE_: "نسبة ثورة كل دقيقة",
  _DBRAKE_: "الفرامل",
  _DRIVER_SCORE_GRAPH_: "الرسم البياني الدرجات السائق",
  _FLEET_MAINTANENCE_REPORT_: "تقرير صيانة الأسطول",
  _TOTAL_WEIGHT_: "أجمالي الوزن",
  _PAGE_: "الصفحة",
  _OF_: "من {0}",
  _TYPE_: "نوع",
  _ICCID_: "معرف بطاقة الدائرة المتكاملة",
  _TRIP_SUMMARY_REPORT_: "تقرير ملخص الرحلة",
  _FIRMWARE_: "البرامج الثابتة",
  _EXIDLE_COUNT_: "العد الخمول الزائد",
  _ASSIGN_DRIVER_: "تعيين السائق",
  _ASSIGN_DRIVER_COMPANY_: "تعيين سائق للشركة",
  _CURRENT_GPS_ODOMETER_: "عداد المسافات جي بي أس الحالي",
  _CHANGE_ODOMETER_: "تغيير عداد المسافات",
  _ODOMETER_TRAVELLED_: "عداد المسافات سافرت",
  _TOTAL_ODOMETER_: "مجموع عداد المسافات",
  _DIFF_ODOMETER_: "فرق عداد المسافات",
  _ADJUST_ODOMETER_: "ضبط عداد المسافات",
  _GPS_ODOMETER_: "عداد المسافات جي بي أس",
  _ODOMETER_ADJUSTMENT_: "تعديل عداد المسافات",
  _FLEET_SUMMARY_DAY_REPORT_: "تقرير ملخص يوم الأسطول",
  _FLEET_SUMMARY_REPORT_: "تقرير ملخص الأسطول",
  _FROM_DATETIME_: "من وقت التاريخ",
  _TO_DATETIME_: "إلى وقت التاريخ",
  _MAIL_SETTINGS_: "إعدادات البريد",
  _VEHICLE_NO_: "رقم  المركبة:",
  _FLEET_COMPANY_COMPANYDETAIL_TAXEXPIREDATE_: "تاريخ انتهاء الضريبة",
  _ADD_NEW_: "أضافة جديد",
  _DISTANCE_TRAVELED_: "المسافة سافرت",
  _GEOFENCE_OR_POI_INOUT_REPORT_: "تقرير جيوفينس أو نقطة الأهتمام داخل/خارج",
  _VEHICLE_REFERENCE_KEY_: "مفتاح مرجع السيارة",
  _POSTING_TIME: "وقت النشر",
  _VEHICLES_: "المركبات",
  _TRACK_DATE_: "تاريخ التتبع",
  _CLEAR_ALL_: "امسح الكل",
  _INVOICE_: "فاتورة",
  _CONTACT1_: "اتصال رئيسي",
  _CONTACT2_: "الاتصال الثانوي",
  _CONTACT3_: "اتصال 3",
  _MONTHLY_RENTAL_: "ايجار الشهري",
  _DEVICE_CHARGES_: "رسوم الجهاز",
  _SIM_CHARGES_: "رسوم الشريحة",
  _APPLICATON_CHARGES_: "رسوم البرنامج",
  _ADDON_: "اضافه",
  _RFID_CHARGES_: "رسوم تحديد الترددات الراديوية(RFID)",
  _TEMPERATURE_SENSOR_CHARGE_: "رسوم الحساس درجة الحرارة",
  _DRIVERID_CHARGES_: "رسوم معرف السائق",
  _FUEL_SENSOR_CHARGES_: "رسوم مستشعر الوقود",
  _CANBUS_: "كين بس",
  _MARK_LOCATION_: "ضع علامة على الموقع",
  _RENEW_: "تجديد",
  _ATTACH_INVOICE_: "إرفاق فاتورة",
  _PHOTO_: "الصورة",
  _LOCATION_ON_MAP_: "الموقع على الخريطة",
  _ADD_NEW_DEPARTMENT_: "إضافة قسم جديد",
  _POLLING_IMEI_: "الاقتراع Imei",
  _VEHICLE_EXPIRY_DATE_: "تاريخ انتهاء المركبة",
  _RENEWED_AT_: "مجددة في",
  _ATTENDAN_: "رقم موبايل المصاحبة",
  _TEMPERATURE_SENSOR_: "الحساس درجة الحرارة",
  _MULKIYA_RENEWAL_DATE_: "تاريخ تجديد رخصة السير الملكية",
  _INSURANCE_EXPIRY_DATE_: "تاريخ انتهاء التأمين",
  _MACHINE_SERVICE_HOURS_: "ساعات خدمة الماكينة (ساعات)",
  _VEHICLE_PURCHASE_DATE_: "تاريخ شراء المركبة",
  _TOTAL_VEHICLE_COST_: "إجمالي تكلفة السيارة",
  _DOWN_PAYMENT_: "الدفعة الأولى",
  _MONTHLY_EMI_: "أقساط شهري متساوية",
  _NO_OF_EMI_: "رقم أقساط شهري متساوية",
  _EMI_DUE_DATE_: "تاريخ الاستحقاق أقساط شهري متساوية ",
  _NATIONAL_PERMIT_EXPIRY_DATE_: "تاريخ انتهاء انتهاء الهوية الوطنية",
  _POLLUTION_EXPIRY_DATE_: "تاريخ انتهاء التلوث",
  _VEHICLE_MILAGE_: "عدد الأميال المركبة",
  _SENSOR3_: "الحساس 3",
  _LOWLIMIT3_: "حد منخفض 3",
  _HIGH_LIMIT3_: "الحد الأقصى 3",
  _INSURANCE_TYPE_: "نوع التأمين",
  _MULKIYA_TYPE_: "نوع رخصة السير الملكية",
  _TERM_: "فترة",
  _FEE_: "الرسوم",
  _CLASS_DIV_: "قطاع صف الدراسي",
  _STUDENT_NAME_: "أسم الطالب",
  _SCHOOL_NAME_: "اسم المدرسة",
  _CC_DETAILS_: "تفاصيل CC",
  _EMAIL_DETAILS_: "تفاصيل البريد الإلكتروني",
  _REMOVE_EVENT_: "إزالة التنبيه",
  _SELECT_COMPANY_: "حدد الشركة",
  _TRACK_DATA_MAP_: "خريطة بيانات التتبع",
  _REPORTS_PRIOR_CONTACT_ADMINISTRATOR_:
    "للتقارير قبل 6 أشهر ، يرجى الاتصال بالمسؤول",
  _BREAK_COUNT_: "صدى فرامل العد",
  _HARSH_BRAKE_COUNT_: "صدى الفرملة الصعبة",
  _HARSH_ACCELERATION_COUNTER_: "عداد تسريع الصدى",
  _IDLING_EVENT_: "تنبيه صدى في الخمول",
  _MAXSPEED_: "صدى السرعة القصوى",
  _ENGINEER_TIMER_: "محرك الصدى على المؤقت تشغيل",
  _IDLING_TIMER_: "صدى المؤقت في الخمول",
  _OVERSPEED_TIMER_: "صدى مؤقت على السرعة الزائدة",
  _RULE_DELETE_: "هل أنت متأكد أنك تريد حذف حكم/القاعدة؟",
  _MINUTES_: "الدقائق",
  _IBUTTONID_: "معرف إيبوتون",
  _PASSENGER_ID_: "هوية الراكب",
  _MANAGE_: "إدارة/يدير",
  _INPUT2_: "المدخلات 2",
  _MENU_SETTING_: "إعداد القائمة",
  _SIM_LIST_: "قائمة الشريحة",
  _NOTIFICATION_: "تنبيه",
  _INSPECTION_: "فحص",
  _ERROR_: "خطأ",
  _SELECT_EXCEL_OR_XML_: "يرجى تحديد ملف Excel أو XML فقط ..",
  _FROM_DATETIME_: "من وقت تاريخ",
  _TO_DATETIME_: "إلى وقت تاريخ",
  _UPLOADING_FILE_: "تحميل ملفك ..",
  _INTERVEL_: "فترة",
  _SAMPLE_EXCEL_: "نموذج إكسل",
  _DASH_BOARD_: "لوحة القيادة",
  _END_ODOMETER_: "نهاية عداد المسافات",
  _REMOVE_RECORD_: "إزالة السجل",
  _DELETE_SIM_RECORD_: "هل تريد حذف سجل الشريحة",
  _INT_: "Int",
  _SERVICE_PROVIDER_: "مقدم الخدمة",
  _SERIAL_NO_: "الرقم التسلسلي",
  _WANT_TO_DELETE_DEVICE_DETAILS_: "هل تريد حذف تفاصيل الجهاز؟",
  _EDIT_DEVICE_DETAILS_: "تحرير تفاصيل الجهاز",
  _DELETE_DEVICE_DETAILS_: "حذف تفاصيل الجهاز",
  _DISTANCE_TRAVEL_: "سافر مسافة",
  _STARTTIME_: "وقت البدء",
  _STOPTIME_: "وقت التوقف",
  _DISTANCETRAVELLED_: "المسافة المقطوعة,",
  _IDLE_MAP_: "خريطة خاملة",
  _IDLE_TILL_: "خامل حتى",
  _EXCESSIVE_IDLE_MAP_: "خريطة الخمول المفرط",
  _PICKUP_DROP_: "التقاط / إسقاط",
  _VEHICLE_NOT_PULL_MAP_: "المركبة لا تسحب الخريطة",
  _SPEED_EXCEPTION_REPORT_: "تقرير استثناء السرعة",
  _SPEED_KM_: "السرعة (كم)",
  _SHIPMENT_DATE_: "تاريخ الشحنة",
  _WARRANTY_DATE_: "تاريخ الضمان",
  _ROUTE_: "طريق",
  _NO_OF_TRIPS_: "عدد الرحلات",
  _IMEI_NO_: "رقم IMEI",
  _SHIPMENT_RECEIVED_DATE_: "تاريخ استلام الشحنة",
  _WARRANTY_VALIDITY_DATE_: "تاريخ صلاحية الضمان",
  _TRIP1_START_TIME_: "وقت بدء الرحلة 1",
  _TRIP2_START_TIME_: "وقت بدء الرحلة 2",
  _TRIP1_END_TIME_: "وقت الانتهاء الرحلة 1",
  _ADD_DEVICE_DETAILS_: "أضافة تفاصيل الجهاز",
  _TRIP2_END_TIME_: "وقت الانتهاء الرحلة 2",
  _GEOFENCE_OR_POI_OUT_MAP_: " جيوفينس أو نقطة الأهتمام داخل/خارج على الخريطة",
  _RPM_SPEED_: "دورة في الدقيقة والسرعة",
  _WANT_TO_DELETE_NOTIFICATION_: "هل تريد حذف التنبيه؟",
  _PRIMARY_DRIVER_IDENTITY_: "رقم هوية السائق الأساسي",
  _ASSISTANT_DRIVER_IDENTITY_: "رقم هوية السائق المساعد",
  _EDIT_SCHOOL_CALENDAR_: "تعديل تقويم المدرسة",
  _REGISTER_IN_WASL_: "سجل في وصل",
  _CONFIRM_: "يتأكد",
  _DOYOU_WANT_TO_REGISTER_: "هل ترغب في التسجيل",
  _IN_WASL_: "في وصل ؟",
  _REGISTER_IN_WASL_: "سجل في وصل",
  _TRIP_MIN_KM_: "رحلة دقيقة كم",
  _TRIP_COST_: "تكلفة الرحلة",
  _TRIP_DURATION_: "مدة الرحلة",
  _DELETE_NOTIFICATION_: "حذف التنبيه",
  _MH14_J5645_: "MH-14 J-5645",
  _GEOFENCE_ID_: "معرف جيوفينس/السياج الجغرافي",
  _HTML_: "HTML",
  _DRILL_START_TIME_: " وقت بدء تدريس الطلاب",
  _DRILL_STOP_TIME_: "وقت إيقاف تدريس الطلاب",
  _OPERATION_HOUR_: "ساعة التشغيل",
  _FATIQUE_HOURS_: "ساعات التعب",
  _SELECT_GROUP_: "اختر مجموعة",
  _EVENT_DESCRIPTION_: "وصف التنبيه",
  _RFID1_: "RFID1/تحديد تردد الراديو",
  _DURATION_HH_MM_SS_: "المدة (س س: د د: ث ث)",
  _DISTANCE_KM_: "المسافة (كم)",
  /*_TRAVEL_INT_:'الفاصل الزمني للسفر (تصفية في دقائق)',
    _STOP_INT_:'فترة التوقف (حد وقت التوقف بالدقائق)',    
    _IDLE_INT_:'الفاصل الزمني الخامل (حد وقت الخمول بالدقائق)',*/
  _TRAVEL_INT_: "فترة السفر",
  _STOP_INT_: "فترة التوقف",
  _IDLE_INT_: "فترة الخامل",
  _TEMP_REPORT_MAP_: "خريطة تقرير درجة الحرارة",
  _T1_MIN_: "T1 الحد الأدنى",
  _T1_MAX_: "T1 أقصى",
  _T2_MIN_: "T2 الحد الأدنى",
  _T2_MAX_: "T2 أقصى",
  _T3_MIN_: "T3 الحد الأدنى",
  _T3_MAX_: "T3 أقصى",
  _END_TIME_: "وقت النهاية",
  _DRIVER_TOTAL_DETAILS_: "التفاصيل الإجمالية للسائق",
  _NO_OF_VEHICLES_USED_: "عدد المركبات المستخدمة",
  _MACHINE_START_TIME_: "وقت تشغيل الماكينة",
  _MACHINE_STOP_TIME_: " وقت إيقاف الماكينة",
  _LAST_DRILL_START_TIME_: "Last Drill Start Time",
  _LAST_DRILL_STOP_TIME_: "Last Drill Stop Time",
  _MOVEMENT_SENSOR_: "الحساس الحركة",
  _IVCAN_VEHICLE_SPEED_: "Ivcan Vehicle Speed",
  _IVCAN_PEDAL_POSITION_: "Ivcan Pedal Position",
  _ENGINE_TEMP_: "درجة حرارة المحرك",
  _FUEL_PERCENT_: "نسبة الوقود",
  _FUEL_RATE_: "معدل استهلاك الوقود",
  _FUEL_CONSUMED_COUNTED_: "استهلاك الوقود المحسوب",
  _TOTAL_MILEAGE_COUNTED_: "إجمالي عد الأميال",
  _IVCAN_PROGRAM_NUMBER_: "Ivcan Program Number",
  _ENGINE_WORKING_TIME_: "وقت عمل المحرك",
  _PROJECT_NAMES_: "أسماء المشروع",
  _TRUCK_: "شاحنة نقل",
  _CAR_: "السيارة",
  _TOTAL_FUEL_CONSUMED_LTRS_: "إجمالي استهلاك الوقود (لتر)",
  _MILEAGE_DIST_LTR_: "الأميال (المسافة / لتر)",
  _FUEL_LEVEL_: "مستوى الوقود",
  _BEFORE_REFILL_LTRS_: "قبل إعادة التعبئة (لتر)",
  _AFTER_FUEL_LEVEL_LTRS_: "بعد الوقود مستوى (لتر)",
  _REFILL_FUEL_LEVEL_LTRS_: "مستوى إعادة تعبئة الوقود (لتر)",
  _DRIVER_MOBILE_: "موبايل السائق",
  _DELIVERED_AT_: "تم التسليم في",
  _DELIVERY_STATUS_: "حالة التوصيل",
  _FEEDBACK_: "تعليق",
  _ATTACHED_IMAGE_: "صورة مرفقة",
  _ROAD_SPEED_VIOLATION_REPORT_: "تقرير انتهاك سرعة الطريق",
  _ROADSPEED_: "سرعة الطريق",
  _ROAD_MAX_SPEED_: "السرعة القصوى للطريق",
  _SALES_PERSON_TOTAL_DETAILS_: "التفاصيل الكاملة لمندوب المبيعات",
  _SALES_PERSON_NAME_: "اسم مندوب المبيعات",
  _SALES_COORDINATOR_NAME_: "اسم منسق المبيعات",
  _TOTAL_MEETING_: "إجمالي الاجتماع",
  _TOTAL_MEETING_ATTEND_: "إجمالي حضور الاجتماع",
  _TOTAL_HOT_CUSTOMER_: "أجمالي عملاء ساخن",
  _TOTAL_WARM_CUSTOMER_: "أجمالي عملاء دافئ",
  _TOTAL_COOL_CUSTOMER_: "إجمالي عملاء كول",
  _MEETING_DATE_: "تاريخ الاجتماع",
  _MEETING_TIME_: "وقت الاجتماع",
  _MEETING_STATUS_: "حالة الاجتماع",
  _FINISHED_AT_: "انتهى في",
  _NEXT_MEETING_DATE_: "تاريخ الاجتماع التالي",
  _CUSTOMER_STATUS_: "حالة العميل",
  _SALES_PERSON_LOCATION_: "موقع مندوب مبيعات",
  _VEHICLE_LOCATION_: "موقع المركبة",
  _B_CARD_: "بطاقة العمل",
  _WANT_TO_DELETE_INSPECTION_: "هل تريد تحذف فحص/تفتيش؟",
  _DELETE_INSPECTION_: "حذف فحص/تفتيش؟",
  _EDIT_INSPECTION_: "تحرير تفاصيل الفحص",
  _TECHNICIAN_NAME_: "اسم الفني",
  _REPAIR_: "يصلح",
  _REMOVAL_: "إزالة",
  _CHECKING_: "تدقيق",
  _SEND_: "إرسال",
  _MEETING_: "مقابلة",
  _DELIVERY_: "توصيل",
  _SEARCH_MAP_: "خريطة البحث",
  _DEPARTMENTS_: "الأقسام",
  _VEHICLE_DETAIL_: "تفاصيل",
  _ADD_JOB_TYPE_: "أضف نوع الوظيفة",
  _SELECT_JOB_TYPE: "حدد نوع الوظيفة",
  _ADD_JOB_REQUEST_DETAILS_: "أضف تفاصيل طلب الوظيفة",
  _COMMAND_TYPE_: "نوع الأمر",
  _ENTER_APN_: "أدخل APN",
  _ENTER_IP_: "أدخل IP",
  _ENTER_PORT_: "أدخل المنفذ",
  _ENTER_INTERVAL_: "أدخل فترة",
  _SMS_COMMAND_: "أمر الرسالة",
  _SALES_PERSON_: "مندوب مبيعات",
  _COMAPNY_NAME_: "اسم الشركة",
  _SUBJECT_: "موضوع",
  _EMAIL_BODY_: "نص البريد الإلكتروني",
  _LAST_EXPIRY_DATE_: "تاريخ انتهاء الصلاحية الأخير",
  _CURRENT_EXPIRY_DATE_: "تاريخ الانتهاء الحالي",
  _OPTION_: "اختيار",
  _PUNCH_IN_TIME_: "لكمة في الوقت",
  _PUNCH_IN_LOCATION_: "لكمة في الموقع",
  _PUNCH_OUT_TIME_: "وقت لكمة خارج",
  _PUNCH_OUT_LOCATION_: "لكمة خارج الموقع",
  _GPS_: "جي بي أس",
  _STATIONARY_FROM_: "القرطاسية من",
  _VEHICLE_START_TIME_: "وقت بدء المركبة",
  _VEHICLE_STOP_TIME_: "وقت توقف المركبة",
  _TOTAL_TRAVEL_TIME_: "عدد ساعات الحركة ",
  _TOTAL_FS_CUSTOMER_: "Total FS Customer",
  _TOTAL_RT_CUSTOMER_: "Total RT Customer",
  _TOTAL_CUSTOMER_: "إجمالي العملاء",
  _FS_DELIVERY_TIME_: "FS Delivery Time",
  _RT_DELIVERY_TIME_: "RT Delivery Time",
  _TO_DATE_COL_: "إلى تاريخ:",
  _IDLE_DURATION_IN_MIN_: "مدة الخمول (بالدقائق)",
  _TOTAL_NO_OF_VEHICLES_: "إجمالي عدد المركبات: ",
  _VEHICLE_DATA_NOT_AVAILABLE_: "بيانات السيارة غير متوفرة ...",
  _DETAIL_TRACKING_: "تتبع التفاصيل",
  _SEARCH_BY_VEHICLE_: "البحث عن طريق السيارة",
  _ASSIGN_VEHICLE_: "تعيين مركبة",
  _SELECT_USER_: "اختر المستخدم",
  _DETAIL_TRACKING_MAP_: "تتبع التفاصيل على الخريطة",
  _LIVE_ROUTE_PATH_: "مسار الطريق مباشر",
  _LIVE_TRAVERSED_: "إتباع مباشر",
  _ADD_SIM_DETAILS_: "أضافة تفاصيل الشريحة",
  _ADD_INSPECTION_: "إضافة التفتيش/فحص",
  _TRIGGER_DETAILS_WINDOW_: "نافذة تفاصيل الزناد",
  _ROUTE_INFO_: "معلومات المسار",
  _ADDRESS_INFO_WINDOW_: "نافذة معلومات العنوان",
  _VEHICLES_NOT_REPORTING_: "المركبات لا تبلغ",
  _EXCESSIVE_IDLING_: "مفرط/ متطرف/ متهور خامل",
  _OVERSPEED_REPORT_: "تقرير السرعة الزائدة",
  _DATE_COL_: "تاريخ :",
  _INVALID_ADDRESS_: "عنوان خاطئ",
  _POI_SAVED_SUCCESSFULL_: "تم حفظ نقطة الاهتمام بنجاح",
  _SELECT_SHAPE_DRAW_FENCE_: "الرجاء تحديد الشكل ورسم السور",
  _LOADING_DATA_: "تحميل البيانات...",
  _RECORDS_NOT_FOUND_: "لم يتم العثور على السجلات",
  _REMOVE_MARKER_: "إزالة العلامة",
  _INFORMATION_: "معلومات",
  _VEHICLE_RENEWAL_ALERT_: "تنبيه تجديد المركبة",
  _LIVE_ROUTE_: "طريق مباشر",
  _CREATE_REPORT_: "إنشاء تقرير",
  _UNAUTHORIZED_USAGE_: "الاستخدام غير المصرح به",
  _GEOFENCE_REPORT_: "تقرير السياج الجغرافي ",
  _DRIVER_BEHAVIOUR_REPORT_: "تقرير سلوك السائق",
  _FUEL_USAGE_REPORT_: "تقرير استخدام الوقود",
  _DELIVERY_REPORT_: "تقرير التسليم",
  _VEHICLE_MANAGEMENT_: "إدارة المركبات",
  _SCHOOL_MANAGEMENT_: "إدارة المدرسة",
  _VEHICLE_SUMMARY_: "ملخص المركبة",
  _JOB_MANAGEMENT_: "إدارة العمل",
  _MASTERS_: "الرئيسية",
  _VEHICLES_TO_RENEW: "سيارات للتجديد",
  _OTHER_: "آخرى",
  _HIDE_VEHICLE_NO_: "إخفاء رقم المركبة",
  _EDIT_GEOFENCE_: "تحرير جيوفينس / السياج الجغرافي",
  _ACTIVITY_PANEL_SETTING_: "إعداد لوحة النشاط",
  _IMPORT_POI_SUCCESSFULLY_: "استيراد نقطة مهمة بنجاح",
  _EVENT_NAME_: "اسم التنبيه",
  _INFO_: "معلومات",
  _MAXIMUM_4_VEHICLES_ALLOWED_: "يسمح بحد أقصى 4 مركبات",
  _PASSENGER_LOG_: "سجل الركاب",
  _BUS_REGISTRATION_REPORT_: "تقرير تسجيل الحافلات",
  _RPM_GRAPH_REPORT_: "تقرير الرسم البياني للثورات في الدقيقة",
  _RULE_TRIGGER_: "مشغل القاعدة",
  _GEOFENCE_SUMMARY_: "ملخص جيوفينس/السياج الجغرافي",
  _DRIVER_LOGIN_: "تسجيل دخول السائق",
  _DRIVER_PERFORMANCE_: "أداء السائق",
  _VEHICLE_PERFORMANCE_: "أداء المركبة",
  _MACHINE_WORKING_HOURS_: "ساعات عمل الماكينة",
  _CANBUS_REPORT_: "تقرير كانبوس",
  _PROJECT_VEHICLE_REPORT_: "تقرير مركبة المشروع",
  _FUEL_CONSUMPTION_REPORT_: "تقرير استهلاك الوقود",
  _FUEL_REFILL_REPORT_: "تقرير إعادة تعبئة الوقود",
  _SALES_MANAGEMENT_: "إدارة المبيعات",
  _VEHICLE_EXPIRY_REPORT_: "تقرير انتهاء المركبة",
  _SALES_PERSON_TRACK_REPORT_: "تقرير مسار مندوب المبيعات",
  _STATIONARY_REPORT_: "تقرير المركبات الثابتة",
  _DISTANCE_KM_REPORT_: "تقرير المسافة (كم)",
  _FLEET_GEOFENCE_SUMMARY_REPORT_:
    "تقرير موجز حول الأسطول جيوفينس / السياج الجغرافي",
  _CHECK_BUS_ROUT_: "تحقق من مسار الحافلة",
  _TRIP_ID_: "معرف الرحلة",
  _MOOBILE_APP_: "تطبيق جوال",
  _SIBLING1_NAME_: "اسم الشقيق/(أخوان) 1",
  _SIBLING1_ID_: "معرف الشقيق/(أخوان) 1 ",
  _SIBLING1_CLASS_: "صف دراسي الشقيق/(أخوان) 1 ",
  _SIBLING2_NAME_: "اسم الشقيق/(أخوان) 2 ",
  _SIBLING2_ID_: "معرف الشقيق/(أخوان) 2 ",
  _SIBLING2_CLASS_: "صف دراسي الشقيق/(أخوان) 2 ",
  _FATHER_NAME_: "اسم الأب",
  _FATHER_CONTACT_NO_: "رقم اتصال الأب",
  _MOTHER_NAME_: "اسم الأم",
  _MOTHER_CONTACT_NO_: "رقم اتصال الأم",
  _GUARDIAN_NAME_: "اسم ولي الأمر",
  _GUARDIAN_CONTACT_NO_: "رقم اتصال ولي الأمر",
  _PICKUP_POINT_: "نقطة الالتقاط",
  _PICKUP_TIME_: "وقت الالتقاط",
  _AVAILABLE_SEATS_: "المقاعد المتاحة",
  _ROUT_TRIP_LIST_: "قائمة رحلة الطريق",
  _TRACKING_TIME_FROM_: "تتبع الوقت من",
  _TRACKING_TIME_TO_: "تتبع الوقت إلى",
  _STAFF_DETAILS_: "تفاصيل الموظفين",
  _TRACKING_INTERVAL_MIN_: "الفاصل الزمني للتتبع (الحد الأدنى)",
  _OTHER_PERMIT_: "تصريح آخر",
  _STAFF_DATA_SAVE_: "تم حفظ بيانات الموظفين بنجاح",
  _SAVING_SALES_PERSON_DATA_: "حفظ بيانات مندوبي المبيعات ..",
  _USERNAME_ALREDY_USE_:
    "اسم المستخدم قيد الاستخدام بالفعل. يرجى تجربة اسم مستخدم مختلف",
  _SALES_PERSON_ID_: "رقم تعريف مندوب المبيعات",
  _EMPLOYEE_STATUS_: "حالة الموظف",
  _REMOVE_SALES_PERSON_: "إزالة مندوب مبيعات",
  _TIME_FROM_: "الوقت من",
  _TIME_TO_: "وقت إلى",
  _LOADING_HOUR__DETAILS_: "تحميل تفاصيل ساعة العمل",
  _WORKING_HOUR_DETAILS_: "تمت إضافة تفاصيل ساعة العمل بنجاح",
  _SENSOR1_NAME_: "اسم الحساس 1° C",
  _SENSOR1_LOW_LIMIT_: "° C الحساس 1 حد منخفض",
  _SENSOR1_HIGH_LIMIT_: "°C الحساس  1ارتفاع الحد",
  _SENSOR2_NAME_: "اسم الحساس 2° C",
  _SENSOR2_LOW_LIMIT_: "° C الحساس 2 حد منخفض",
  _SENSOR2_HIGH_LIMIT_: "°C الحساس  2ارتفاع الحد",
  _SENSOR3_NAME_: "اسم الحساس 3° C",
  _SENSOR3_LOW_LIMIT_: "° C الحساس 3 حد منخفض",
  _SENSOR3_HIGH_LIMIT_: "°C الحساس  3ارتفاع الحد",
  _RETURNED_DATE_: "تاريخ الإرجاع",
  _RETURNED_FROM_: "العودة من",
  _EDIT_RETURN_ASSET_: "تعديل تفاصيل إرجاع الأصول",
  _DELETE_VEHICLE_DETAILS_: "حذف تفاصيل السيارة",
  _LICENSE_NO_: "رقم الرخصة",
  _SALIK_USED_: "ضريبة الطرق المستخدمة",
  _SALIK_CHARGES_: "رسوم ضرائب الطرق",
  _RETURN_ASSET_: "عودة الأصول",
  _PROJECT_NAME_: "اسم المشروع",
  _ADD_NEW_PROJECT_: "إضافة مشروع جديد",
  _SAVING_DETAILS_: "جاري حفظ التفاصيل",
  _ALREADY_ENTERED_FOR_DATE_:
    "لقد أدخلت بالفعل قراءات للسيارة المحددة لهذا التاريخ",
  _EDIT_VEHICLE_DETAILS_: "تعديل تفاصيل السيارة",
  _DELETE_MAINTENANCE_: "حذف قائمة الصيانة",
  _EDIT_MAINTENANCE_LIST_: "تعديل قائمة الصيانة",
  _TOTAL_DELIVERIES_: "إجمالي عمليات التسليم",
  _DATA_NOT_AVAILABLE_: "البيانات غير متوفرة",
  _DATA_BROWSER_STATS_2012_: "البيانات: إحصائيات المتصفح 2012",
  _DELIVERY_DASHABOARD_: "لوحة التسليم",
  _REMOVE_DELIVERY_DETAIL_: "إزالة تفاصيل التسليم",
  _CUSTOMER_MOBILE_NO_: "رقم هاتف العميل",
  _SALES_EXECUTIVE_NAME_: "اسم تنفيذي المبيعات",
  _DELIVERED_TIME_: "وقت التسليم",
  _CREATING_GEOFENCE_: "إنشاء جيوفينس/السياج الجغرافي ..",
  _ONLY_9_DIGITS_ALLOWED_: "يسمح فقط 9 أرقام",
  _SALES_PERSON_EXECUTIVE_: "مندوب مبيعات تنفيذي",
  _SALES_PERSON_COORDINATOR_: "منسق مبيعات",
  _NOTE_TO_SALES_PERSON_: "ملاحظة لمندوب المبيعات",
  _AUTO_FINISH_: "إنهاء تلقائي",
  _LANDLINE_NUMBER_: "رقم الهاتف الثابت",
  _ADD_NEW_JOB_: "إضافة العمل جديدة",
  _REQUEST_DATE_: "تاريخ الطلب",
  _INVOICE_NUMBER_: "رقم الفاتورة",
  _REQUEST_TIME_: "وقت الطلب",
  _REMOVE_JOB_REQUEST_DETAIL_: "إزالة تفاصيل طلب العمل",
  _DEPARTMENT_NAME_: "اسم القسم",
  _CONTACT_NO_: "رقم الاتصال",
  _ALLOCATE_AT_: "تخصيص في",
  _REMOVE_PARENT_: "إزالة أب/الأم؟",
  _CLASS_: "صف دراسي",
  _PARENTS_NAME_: "اسم الوالدين",
  _FUEL_DETAILS_: "تفاصيل الوقود",
  _GRADE_LIST_: "قائمة الصف",
  _GRADE_BUS_TIMING_: "توقيت حافلة الصف",
  _SCHOOL_CALENDER_LIST_: "قائمة التقويم المدرسي",
  _SCHOOL_CALENDER_: "التقويم المدرسي",
  _STAFF_: "العاملين",
  _REMOVE_PASSENGER_: "إزالة الراكب؟",
  _CHECK_ROUTE_: "تحقق من الطريق",
  _SELECT_VEHICLES_: "يرجى تحديد المركبات",
  _SUCCESS_: "نجاح",
  _FILE_UPLOADED_: "تم تحميل ملفك",
  _REASSIGN_VEHICLE_: "إعادة تعيين المركبة",
  _EXPORT_TO_EXCEL_: "تصدير إلى أكسل",
  _SEARCH_DELIVERY_: "البحث تسليم",
  _SEARCH_VEHICLE_: "ابحث عن مركبة",
  _EDIT_FUEL_FILLED_: "تعديل الوقود معبأ",
  _DELETE_FUEL_FILLED_: "حذف الوقود معبأ",
  _FUEL_FILLED_LITERS_: "الوقود معبأ (لتر)",
  _LOADING_FUEL_DETAILS_: "تحميل تفاصيل الوقود",
  _FUEL_FILLED_DETAILS_ADDED_SUCCESS_: "تمت إضافة التفاصيل معبأ بالوقود بنجاح",
  _EDIT_FUEL_DETAILS_: "تعديل تفاصيل الوقود",
  _DELETE_FUEL_DETAILS_: "حذف تفاصيل الوقود",
  _FUEL_DETAIL_IS_EMPTY_: "حقل تفاصيل الوقود فارغ",
  _FUEL_DETAILS_LIST_: "قائمة تفاصيل الوقود",
  _EXPORT_TO_EXCEL_: "تصدير إلى أكسل",
  _RECORDS_: "السجلات/محفوظات",
  _PLEASE_SELECT_VEHICLES_: "يرجى تحديد المركبات",
  _VEHICLE_DETAILS_: "تفاصيل المركبة",
  _ALLOCATE_VEHICLE_DETAILS_LIST_: "تخصيص قائمة المركبات",
  _SEARCH_BY_ALLOCATE_DATE_: "البحث عن طريق تخصيص التاريخ",
  _SEARCH_BY_RETURN_DATE_: "البحث حسب تاريخ العودة",
  _EDIT_FUEL_FILLED_: "تعديل الوقود معبأ",
  _DELETE_FUEL_FILLED_: "حذف الوقود معبأ",
  _FUEL_FILLED_LITERS_: "الوقود لتر",
  _LOADING_FUEL_DETAILS_: "تحميل تفاصيل الوقود",
  _FUEL_FILLED_DETAILS_ADDED_SUCCESS_: "تمت إضافة التفاصيل معبأ بالوقود بنجاح",
  _EDIT_FUEL_DETAILS_: "تعديل تفاصيل الوقود",
  _DELETE_FUEL_DETAILS_: "حذف تفاصيل الوقود",
  _FUEL_DETAIL_IS_EMPTY_: "حقل تفاصيل الوقود فارغ",
  _FUEL_DETAILS_LIST_: "قائمة تفاصيل الوقود",
  _EXPORT_TO_EXCEL_: "تصدير إلى أكسل",
  _PLEASE_SELECT_VEHICLES_: "يرجى تحديد المركبات",
  _VEHICLE_DETAILS_: "تفاصيل المركبة",
  _ALLOCATE_VEHICLE_DETAILS_LIST_: "تخصيص قائمة المركبات",
  _SEARCH_BY_ALLOCATE_DATE_: "البحث عن طريق تخصيص التاريخ",
  _SEARCH_BY_RETURN_DATE_: "البحث حسب تاريخ العودة",
  _ROUT_ALLOCATION_LIST_: "قائمة تخصيص المسار",
  _REASSIGN_VEHICLE_: "إعادة تعيين السيارة",
  _ADD_STUDENT_: "أضافة طالب",
  _GRADE_TIMING_LIST_: "قائمة توقيت الصف",
  _GRADE_LIST_: "قائمة الصف",
  _GRADE_BUS_TIMINGS_: "توقيت حافلة الصف",
  _SCHOOL_CALENDER_LIST_: "قائمة التقويم المدرسي",
  _SCHOOL_CALENDER_: "التقويم المدرسي",
  _PARENTS_LIST_: "قائمة أولياء الأمور",
  _PARENTS_NAME_: "اسم الوالدين",
  _PARENTS_: "الوالدان",
  _GRADE_: "الصف",
  _ASSIGN_TRIP_: "تعيين النوع",
  _CAPACITY_: "قدرة:",
  _AVAILABLE_SEAT_: "مقعد متاح:",
  _STUDENT_ID_: "هوية الطالب",
  _GRADE_TIMING_DETAILS_: "تفاصيل توقيت الصف",
  _LOADING_GRADE_DETAILS_: "تحميل تفاصيل الصف",
  _GRADE_TIMING_ADDED_SUCCESS_: "تمت إضافة تفاصيل توقيت الصف بنجاح",
  _EDIT_GRID_: "تعديل الصف",
  _DELETE_GRID_: "حذف الصف",
  _ACADEMIC_YEAR_FROM_: "العام الدراسي من",
  _ACADEMIC_YEAR_TO_: "السنة الأكاديمية إلى",
  _EDIT_SCHOOL_CALENDER_: "تعديل تقويم المدرسة",
  _DELETE_SCHOOL_CALENDER_: "حذف تقويم المدرسة",
  _SCHOOL_REOPEN_: "إعادة فتح المدرسة",
  _HOLYDAYS_: "العطلات",
  _PARENTS_MEETING_: "اجتماع الأهل",
  _EVENT_: "الحدث",
  _WINTER_BREAK_FROM_: "عطلة الشتاء من",
  _WINTER_BREAK_TO_: "عطلة الشتاء إلى",
  _SPRING_BREAK_FROM_: "عطلة الربيع من",
  _SPRING_BREAK_TO_: "عطلة الربيع إلى",
  _SUMMER_BREAK_FROM_: "عطلة الصيف من",
  _SUMMER_BREAK_TO_: "العطلة الصيفية إلى",
  _FIRST_TERM_: "الفصل الدراسي الأول",
  _SCHOOL_CALENDER_DETAILS_: "تفاصيل التقويم المدرسي",
  _SECOND_TERM_: "الفصل الدراسي الثاني",
  _THIRD_TERM_: "الفصل الدراسي الثالث",
  _SCHOOL_CALENDER_UPDATED_: "تم تحديث تقويم المدرسة بنجاح",
  _SCHOOL_CALENDER_ADDED_: "تمت إضافة تقويم المدرسة بنجاح",
  _LOADING_SCHOOL_CALENDER_DETAILS_: "تحميل تفاصيل تقويم المدرسة",
  _PLEASE_SELECT_START_POINT_FIRST_: "يرجى تحديد نقطة البداية أولاً",
  _SUGGEST_ROUTE_: "أقترح الطريق",
  _DEPARTURE_TIME_: "وقت المغادرة",
  _PLEASE_SELECT_ADDRESS_AND_SEQUENCE_: "يرجى تحديد العنوان وتسلسلهما",
  _PLEASE_SELECT_START_POINTAND_ADDRESS_: "يرجى تحديد نقطة البداية والعنوان",
  _EDIT_TRIP_: "تعديل الرحلة",
  _DELETE_TRIP_: "حذف الرحلة",
  _ASSIGN_BUS_TO_STUDENT_: "تعيين حافلة للطلاب",
  _COPY_TRIP_: "نسخ الرحلة",
  _REMOVE_TRIP_: "إزالة الرحلة؟",
  _SELECT_VEHICLE_: "حدد المركبة",
  _SELECT_DISTRIBUTOR1_: "حدد الموزع 1",
  _FILTER_TYPE_: "تصنيف بحسب النوع",
  _SALES_PERSON_LIST_: "قائمة موظفي المبيعات",
  _STAFF_LIST_: "قائمة الموظفين",
  _JOB_REQUEST_: "طلب العمل",
  _DELETE_JOB_: "حذف العمل",
  _FINISH_JOB_: "هل تريد إنهاء العمل؟",
  _SELECT_ONE_JOB_: "الرجاء تحديد العمل واحدة على الأقل",
  _SURE_TO_DELETE_: "هل أنت متأكد أنك تريد حذف؟",
  _SELECT_JOB_: "يرجى تحديد العمل",
  _SURE_TO_FINISH_JOB_: "هل أنت متأكد أنك تريد إنهاء العمل؟",
  _SEARCH_REQUEST_: "البحث الطلب",
  _SELECT_DISTRIBUTOR_: "حدد الموزع",
  _SELECT_DEPARTMENT_: "حدد القسم",
  _PENDING_: "قيد الانتظار",
  _PROCESSED_: "تمت معالجتها",
  _FINISHED_: "تم الانتهاء من",
  _SELECT_SALES_EXECUTIVE_: "حدد تنفيذي مبيعات",
  _EXCEL_DETAILS_ID_NAME_: "تفاصيل أكسل (المعرف / الاسم)",
  _JOB_REQUEST_LIST_: "قائمة طلب العمل",
  _WANT_TO_DELETE_: "هل تريد أن تحذف ؟",
  _EDIT_RECORD_: "تعديل السجل",
  _DELETE_RECORD_: "حذف السجل",
  _SEARCH_BUS_NO_: "البحث بالحافلة",
  _SEARCH_NAME_: "البحث عن طريق الإسم",
  _SEARCH_TAGID_: "البحث بواسطة معرف العلامة",
  _WRONG_BUS_BOARDING_: "ركوب في الحافلة خطأ",
  _TRIP_DETAILS_: "تفاصيل الرحلة",
  _SEARCH_BY_BUS_NO_: "البحث بالحافلة",
  _SEARCH_BY_NAME_: "البحث عن طريق الإسم",
  _SEARCH_BY_TAGID_: "البحث بواسطة معرف العلامة",
  _TRIP_START_TIME_: "وقت بدء الرحلة",
  _POINT_COVERED_: "تم تغطية النقطة",
  _POINT_LEFT_: "نقطة اليسار",
  _TRIP_END_TIME_: "وقت انتهاء الرحلة",
  _START_LOCATION_: "ابدأ الموقع",
  _COLOR_CODE_: "رمز اللون",
  _ASSIGN_VEHICLE_TOJOB_REQ_: "تعيين مركبة لطلب العمل",
  _ASSIGN_VEHICLE_TO_COMPANY_: "تعيين مركبة للشركة",
  _SELECT_DEVICE_GROUP_: "حدد مجموعة الأجهزة",
  _ASSIGN_DEVICE_GROUP_TO_VEHICLE_: "تعيين مجموعة الأجهزة للمركبة",
  _NO_VEHICLE_PLZ_ADD_: "No Vehicles. يرجى إضافة المركبات",
  _DEVICE_GROUP_APPLIED_TO_SELECTED_:
    "تم تطبيق مجموعة الأجهزة على المركبات المحددة",
  _SAVE_GROUP_DEVICE_DATA_: "جارٍي حفظ بيانات مجموعة الأجهزة",
  _CLASS_DEPARTMENT_: "الفصل / القسم",
  _STUDENT_OFF_BOARDS_: "طالب نزل",
  _STUDENT_ON_BOARDS_: "طالب ركب",
  _NOT_VALID_TIME_: "ليس وقتًا صالحًا",
  _SEARCH_ZONE_ADDRESS_: "البحث منطقة أو العنوان",
  _ADD_WAYPOINTS_: "أضافة نقاط الطريق",
  _GET_LOCATION_: "احصل على الموقع",
  _ENTER_ADDRESS_: "أدخل عنوانك",
  _DETAILS_: "تفاصيل",
  _DATA_NOT_AVALABLE_: "البيانات غير متوفرة",
  _STUDENT_DASHABOARD_: "لوحة تحكم الطالب",
  _SELECT_BUS_: "حدد الحافلة",
  _SETTINGS_: "الإعدادات",
  _PASSWORD_SENT_TO_EMAIL_ADDRESS_:
    "تم إرسال كلمة المرور الخاصة بك إلى عنوان بريدك الإلكتروني",
  _DETAILS_SEND_SUCCESSFULLY_: "تم إرسال التفاصيل بنجاح",
  _NOT_VALID_NUMBER_: "رقم غير صالح",
  _NOT_VALID_TEXT_: "نص غير صالح",
  _ENTER_EMAILID_: "الرجاء إدخال البريد الالكتروني",
  _NAVIGATION_WINDOW_: "شاشة التنقل",
  _AN_ADDRESS_: "عنوان",
  _ANOTHER_ADDRESS_: "عنوان آخر",
  _GET_DIRECTION_: "احصل على اتجاه",
  _VEHICLE_NOT_AVAILABLE_: "المركبة غير متوفرة",
  _VEHICLE_ZONE_: "منطقة المركبة",
  _ADD_ROUTE_ALLOCATION_: "إضافة تخصيص المسار",
  _SUCCESSFULLY_UPDATED_: "تم التحديث بنجاح",
  _SEARCH_SERVICE_TYPE_: "البحث نوع الخدمة",
  _SEARCH_GARAGE_: "البحث كراج",
  _SEARCH_DATE_: "البحث حسب التاريخ",
  _SELECT_EXCEL_FILE_ONLY_: "يرجى تحديد ملف أكسل فقط.",
  _RECORDS_: "السجلات",
  _STUDENT_ABSENT_: "الطالب غائب",
  _DISTANCE_FUELVOLUME_: "المسافة وحجم الوقود",
  _FUELVOLUME_: "حجم الوقود",
  _SELECT_ZONE_: "حدد المنطقة",
  _ASSIGN_VEHICLES_TO_ZONES_: "تعيين المركبات للمناطق",
  _GEO_FANCE_: "جيوفينس / السياج الجغرافي",
  _GENERAL_: "جنرال",
  _LOCATIONS_: "المواقع",
  _ALERTS_: "التنبيهات",
  _MESSAGES_LIST_: "قائمة الرسائل",
  _EVENTS_: "الأحداث",
  _MDT_: "أم دي تي",
  _VENDOR_CODE_: "رمز البائع",
  _ADD_TRIP_COST_: "أضف تكلفة الرحلة",
  _VEHICLE_OFFLINE_TRACKING_: "تتبع المركبات دون اتصال",
  _VEHICLE_TRACKING_: "تتبع المركبات",
  _TRIP_NO_: "الرحلة رقم",
  _MAXIMUM_SPEED_: "السرعة القصوى",
  _FUEL_USED_: "وقود مستخدم",
  _VEHICLE_TRIP_REPORT_: "تقرير رحلة المركبة",
  _GEO_BOUNDARY_REPORT_: "تقرير الحدود الجغرافية",
  _ADD_EVENT_: "إضافة حدث",
  _REMOVE_ROUTE_: "إزالة المسار",
  _OPTION2_: "الخيار 2",
  _OPTION3_: "الخيار 3",
  _REMOVE_MAINTENANCE_DETAIL_: "إزالة تفاصيل الصيانة",
  _GRADE_TIMINGS_: "توقيت الصف",
  _VEHICLE_DISTANCE_: "مسافة المركبة",
  _VEHICLE_DISTANCE_LIST_: "قائمة مسافة المركبة",
  _DUTY_TIME_FROM_: "وقت العمل من",
  _DUTY_TIME_TO_: "وقت العمل إلى",
  _SHIFT_NAME_: "اسم الفترة(صباح/مساء)",
  _ADD_NOTIFICATION_: "أضافة التنبيه",
  _POSTED_AT_: "نشر في",
  _VIDEO_MONITORING_: "مراقبة الفيديو",
  _MDVRAlarm_Report_: "تقرير التنبيه مسجل الفيديو الرقمي المحمول",
  _WELCOME_: "أهلا بك ",
  _MENU_SETTING_LIST_: "قائمة إعدادات القائمة",
  _NOTIFICATION_LIST_: "قائمة التنبهات",
  _INSPECTION_LIST_: "قائمة التفتيش/فحص",
  _MDVR_ALARM_REPORT_: "تقرير التنبيه مسجل الفيديو الرقمي المحمول",
  _DEVICE_ID_: "الرقم التسلسلي للجهاز",
  _ALARM_TYPE_: "نوع التنبيه",
  _CONTENT_: "المحتوى",
  _LAST_DATE_TIME_: "وقت آخر موعد",
  _LIVE_VIDEO_STREAMING_: "بث مباشر للفيديو",
  _CHANNEL1_: "قناة 1",
  _CHANNEL2_: "قناة 2",
  _CHANNEL3_: "قناة 3",
  _CHANNEL4_: "قناة 4",
  _CHANNEL_: "قناة 1",
  _PLAY_BACK_OPTION_: "خيارات التشغيل",
  _COLLECTION_LIST_: "قائمة المجموعات",
  _REMOVE_COLLECTION_IMAGE_: "إزالة وجه المجموعة",
  _REMOVE_COLLECTION_IMAGE_MSG_: "هل تريد إزالة الصورة من مجموعة أى دبليو اس؟",
  _AWS_: "أى دبليو اس",
  _IMAGE_UPLOAD_: "رفع صورة",
  _SELECT_IMAGE_: "الرجاء تحديد الصورة",
  _BLACKLIST_PERSON_REPORT_: "تقرير شخص القائمة السوداء",
  _SIMILARITY_: "تشابه",
  _DESCRIPTION_: "وصف",
  _BLACKLIST_: "بلاك ليست/السوداء",
  _BLACKLISTED_AT_: "بلاك ليست/السوداء في",
  _BLACKLIST_PERSON_: "شخص بلاك ليست/السوداء",
  _FIRST_NAME_: "الاسم الاول",
  _OFFICER_ID_: "معرف ضابط الشرطة",
  _OFFICER_DETAILS_: "تفاصيل ضابط الشرطة",
  _LIVE_LOCATION_: "موقع مباشر",
  _LIVE_LOCATION_TITLE_: "قائمة الموقع مباشر",
  _CREATE_LINK_: "إنشاء رابط",
  _LIVE_LOCATION_URL_: "عنوان URL لموقع مباشر",
  _CURRENT_GPS_ODOMETER_: "عداد المسافات جي بي أس الحالي",
  _WASL_DRIVER_LIST_: "قائمة السائقين الوصل",
  _VIEW_ON_MAP_: "عرض على الخريطة",
  _POLICE_OFFICERS_LIST_: "قائمة ضباط الشرطة",
  _POLICE_OFFICER_NAME_: "اسم ضابط الشرطة",
  _POLICE_OFFICER_MOBILE_NO_: "رقم هاتف ضابط الشرطة",
  _POLICE_OFFICER_USERNAME_: "اسم مستخدم ضابط الشرطة",
  _POLICE_OFFICER_: "ضابط شرطة",
  _BLACK_LIST_: "القائمة السوداء/بلاك ليست",
  _VEHICLE_GROUPS_: "مجموعات المركبات",
  _TOTAL_COUNT_: "العدد الإجمالي",
  _BLOCK_UNBLOCK_: "حظر / إلغاء الحظر",
  _GENDER_: "جنس",
  _MALE_: "الذكر",
  _FEMALE_: "أنثى",
  _CUSTOMER_DISTRIBUTOR_: "العميل / الموزع",
  _WASL_REGISTRATION_: "تسجيل الوصل",
  _UPLOADING_: "جارٍي التحميل",
  _SELECT_RECORD_: "يرجى تحديد السجلات/محفوظات",
  _PROCESSING_: "العملية الجارية",
  _ALERT_BLOCK_UNBLOCK_: "هل أنت متأكد أنك تريد حظر / إلغاء الحظر؟",
  _ALERT_REMOVE_FROM_BLACKLIST_:
    "هل أنت متأكد أنك تريد إزالة الشخص من القائمة السوداء/بلاك ليست؟",
  _BILL_NO_: "رقم الفاتورة",
  _ADD_SERVICE_: "إضافة خدمة",
  _ID_: "هوية/رقم معرف",
  _ITEM_: "البند",
  _PRICE_: "السعر",
  _GROSS_PRICE_: "السعر الاجمالي",
  _INCHARGE_NAME_: "اسم المسؤول",
  _WORKING_DAYS_: "أيام العمل",
  _CLASS_NAME_: "اسم الفصل",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME_: "خزان  حجم",
  _VEHICLE_HISTORY_DETAILS_: "تفاصيل محفوظات المركبة ",
  _CURRENT_SPEED_: "السرعة الحالية",
  _TOTAL_TRAVEL_REPORT_: "تقرير إجمالي الحركة",
  _FLEET_REPORTS_DRIVERNAME_: "Driver Name",
  _FLEET_REPORTS_AMOUNT_: "Amount",
  // _LOAD_SENSOR_: 'Load Sensor',
  // _MINIMUM_VOLT_: 'Minimum Volt',
  // _MAXIMUM_VOLT_: 'Maximum Volt',
  // _HEAD_WEIGHT_: 'Head Weight',
  // _ATTACH_TAILER_: 'Attach Trailer',

  _SEARCH_BY_ZONE: "Search By Zone",
  // _HEAD_WEIGHT_: 'Head Weight',
  _FLEET_DASHBORD_NEWPOI_REMOVEYES_ARABIC_: "yes",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT1_: "Driver Seatbelt",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT2_: "Passenger Seatbelt",
  _BLE_TEMP_: "BLE Temp",
  _BLE_HUMIDITY_: "BLE Humidity",
  _BLE_TEMP1_: "BLE Temp1",
  _BLE_TEMP2_: "BLE Temp2",
  _BLE_TEMP3_: "BLE Temp3",
  _BLE_TEMP4_: "BLE Temp4",
  _BLE_HUMIDITY1_: "BLE Humidity1",
  _BLE_HUMIDITY2_: "BLE Humidity2",
  _BLE_HUMIDITY3_: "BLE Humidity3",
  _BLE_HUMIDITY4_: "BLE Humidity4",
  _START_LOAD_VOLTAGE_: "بدء تحميل الجهد",
  _END_LOAD_VOLTAGE_: "نهاية تحميل الجهد",
  _START_LUGGAGE_LOAD_: "بدء تحميل الأمتعة",
  _END_LUGGAGE_LOAD_: "إنهاء تحميل الأمتعة",
  _START_EMPTY_WEIGHT_: "ابدأ بالوزن الفارغ",
  _END_EMPTY_WEIGHT_: "نهاية الوزن الفارغ",
  _START_TOTAL_WEIGHT_: "ابدأ بالوزن الإجمالي",
  _END_TOTAL_WEIGHT_: "نهاية الوزن الإجمالي",

  _FULL_PATH_: "مسار كامل",
  _STRAIGHT_LINE_: "بطريقة مباشرة",
  _TRIP_: "رحلة",
  _OVERSPEED_: "تجاوز السرعة",
  _ALL_: "الكل",
  _TRAVEL_: "متحرك",
  _FAST_: "سريع",
  _MEDIUM_: "متوسط",
  _SLOWER_: "بطيء",
  _STOPPED_: "متوقف",
  _IDLE_: "خمول",
  _HARSH_ACCELERATION_: "تسارع السيارة المفاجئ",
  _HARSH_DEACCELERATION_: "فرملة مفاجئة قوية",
  _LOAD_SENSOR_DISCONNECT_ALARM_: "إنذار فصل حساس الوزن",
  _POWER_CUT_ALARM_: "إنذار فصل الجهاز",
  _HIGH_IMPACT_ACCIDENT_: "تأثير عالي (حادث)",
  _LOW_IMPACT_ACCIDENT_: "تأثير منخفض (حادث)",
  _GSM_JAMMING_ALARM_: "إنذار تشويش شبكة",
  _NO_EVENT_: "لا يوجد",
  _MAX_SPEED_GRAPH_: "رسم بياني السرعة القصوى",

  _STOP_: "متوقف",
  _EXIDLE_: "الخمول الزائد",
  _RUNNING_: "متحرك",
  _NODATA_: "لا يوجد بيانات ",
  _NoData_: "ناداتا",
  _DISTANCE_MEASUREMENT_: "قياس المسافة",
  _TOTAL_: "مجموع",
  _EXPIRED_: "انتهى الاشتراك ",
  _EXPIRINGSOON_: "ينتهي الاشتراك قريبا",
  _SELECT_ALL_GROUPS_: "حدد كل المجموعات",
  _HISTORY_OF_MULTIPLE_VEHICLES_: "محفوظات مركبات المتعددة",
  _APPLY_: "عرض",
  _CANCEL_: "إلغاء",
  _CUSTOME_RANGE_: "تحديد التاريخ",
  _TRACK_BY_GROUP_: "تتبع حسب المجموعة ",
  _GROUP_NAME_: "اسم المجموعة ",
  _COMPANY_: "اسم الشركة ",
  _DRIVER_: "اسم السائق ",
  _IGNITION1_: "حالة المركبة",
  _TYPE_: " نوع",
  _REMINDERS_: "التذكيرات",
  //WORDS ADDED BY ASHVINI
  _MULTI_VIDEO_MONITORING_: "مراقبة فيديو متعددة",
  _WAREHOUSE_MONITORING_: "مراقبة المستودعات",
  _WEIGHT_REPORT_: "تقرير الوزن",
  _CITY_OF_WAREHOUSE_: "مدينة",
  _LOGITUDE_: "",
  _INVENTORY_: "المخزون",
  _LICENSE_EXPIRY_DATE_: "تاريخ انتهاء الترخيص",
  _PAYMENT_LIST_: "قائمة الدفع",
  _LICENSE_ISSUE_DATE_: "تاريخ إصدار الترخيص",
  _FLEET_COMPANY_COMPANYLIST_COMPANYNAME_: "اسم الحساب",
  _PAYMENT_ID_: "معرف الدفع",
  _PAYMENT_METHOD_: "طريقة الدفع",
  _TOTAL_VEHICLE_: "اجمالي السيارات ",
  _TOTAL_AMOUNT_: "اجمالي المبلغ",
  _PAYMENT_DATE_: "موعد الدفع",
  _INVENTORY_LIST_: "قائمة الجرد",
  _FLEET_DASHBORD_NEWPOI_CUSTOMER_: "الحساب",
  _FLEET_GROUP_GROUPLIST_DEVICENAME_: "الجهاز",
  _NAME_GRID_OF_WAREHOUSE_: "المستودعات",
  _INVENTORY_: "الجرد او المخزون",
  _INVENTORY_NUMBER_: "رقم المخزون ",
  _STORING_CATEGORY_: "فئة الخزين",
  _STORING_TYPE_: "نوع التخزين",
  _WAREHOUSE_WASL_REPLY_: "رد وصل",
  _WAREHOUSE_WASL_RESPONSE_: "استجابة وصل",
  _INVENTORY_STATUS_REPORT_: "تقرير حالة المخزون",
  _CANBUS_REPORT_: "اعتقد تقرير الحافلة",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT1_: "حزام الامان للسائق",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT2_: "حزام الأمان للركاب",
  _INSURANCE_RENEWAL_: "تجديد التأمين",
  _IGNITION1_: "اشتعال ",
  _ENGINE_TEMP_: "درجة حرارة المحرك",
  _POWER_CUT_: "انقطاع الطاقة",
  _MAX_SPEED_GRAPH_: "اقصى سرعة",
  _DRIVER_SCORE_GRAPH_: "الرسم البياني لدرجة السائق",
  _ACCIDENT_ALERT_: "تنبيه الحوادث",
  _TOWING_ALERT_: "تنبيه السحب",
  _STAY_ALERT_: "تنبيه حالة التأهب",
  _PARKING_ALERT_: "تنبيه وقوف السيارة",
  _VEHICLE_HARSH_CONNECTING_: "المنعطفات الحاده",
  _VEHICLE_HARSH_BRACKING_: "فرملة السيارة المفاجأة",
  _ROAD_OVERSPEED_: "السرعة الزائدة ع الطريق",
  _PAYMENT_: "المدفوعات اونلاين",
  _TESTING_POWER_CONNECTION_: "اختبار طاقة التشغيل ",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_LASTREPORT_: "اخر تقرير ",
  _TESTING_ACC_: "اختبار التشغيل",
  _TESTING_GPS_: "اختبار الجهاز",
  _LAST_KNOWN_DISTANCE_: "اخر مسافة ",
  _LAST_KNOW_BLE_TEMPERATURE_: "اختبار الحساس",
  _LAST_KNOWN_BLE_HUMIDITY_: "اختبار الرطوبة",
  _TESTING_VOLTAGE_: "اختبار الفولت",
  _TESTING_WEIGHT_: "اختبار الوزن",
  _DEVICE_TESTING_: "اختبار الجهاز",
  _SUBMIT_: "تأكيد او ارسال او تقديم ",
  _POWER_CONNECTED_: "زر التشغيل ",
  _LATTITUDE_AND_LONGITUDE_: "خط الطول والعرض",
  _TIMESTAMP_: "الوقت الزمني",
  _FLEET_RULEDETAIL_SELECTPARAMETER_DISTANCE_: "المسافة ",
  _BLE_TEMP_: " الحساس ",
  _BLE_HUMIDITY_: "الرطوبة",
  _VOLTAGE_: "الفولت",
  _TOTAL_WEIGHT_: "اجمالي الوزن ",
  _MULKIYA_RENEWAL_: "تجديد الملكية",

  _FILTER_BY_: "تصفيه بحسب",
  _FLEET_VEHICLENAME_: "أسم المركبة",
  _WAREHOUSE_NAME_: "أسم المستودع",
  _WAREHOUSE_LICENSE_: "رخصة المستودع ",
  _WASL_BUS_TRIP_REPORT_: "تقرير رحلة الباصات بوصل ",
  _SENSOR_REPORT_: "تقرير الحساس",
  _USER_ACTIVITY_REPORT_: "تقرير نشاط المستخدم",
  _INSTALLATION_TYPE_: "نوع التركيب",
  _RENEWAL_PRICE_: "قيمة التجديد",
  _YEARLY_: "سنوي",
  _MONTHLY_: "شهري",
  _QUARTERLY_: "ربعي",
  _6_MONTHS_: "6  شهور",
  _STUDENT_: "طالب",
  _EMPLOYEE_: "موظف",
  _CREATE_DATE: "تاريخ الانشاء",
  _WASL_STATION_: "محطة وصل",
  _REPORT_FORMAT_: "صيغة التقرير,",
  _SEARCH_BY_DEVICE_NO: "البحث برقم الجهاز",
  _RENEW_OPTION_: "خيارات التجديد",
  _WASL_TRIP_: "رحلة وصل",
  _EXCESSIVE_IDLE: "الخمول الزائد",
  _MAINTENANCE_DASHBOARD_: "لوحة معلومات الصيانة",
  _HARSH_ACCEL_: "نسبة السرعة الزائدة",
  _BLE_TEMP_HUMIDITY_: "الرطوبة",
  _NON_STOPPAGE_FOR_: "عدم التوقف",
  _FLEET_VEHICLE_VEHICLEDETAIL_LASTBATTERY_: "حالة البطارية للمركبة",
  _FLEET_VEHICLE_VEHICLEDETAIL_BATTERY_CHANGE_INT_: "تغير بطارية المركبة",
  _HOUR_METER_READING_: "قراءة ساعات العداد",
  _MAX_HOUR_PER_DAY_: "عدد الساعات في اليوم",
  _REGION_: "المنطقة",
  _BRANCH_: "الفرع",
  _OWNERSHIP_: "الملكية",
  //maintenance dashboard
  // _TYRE_CHANGE_REMINDER_: "تذكير بتغبير الاطارات",
  // _SERVICE_REMINDER_: 'تذكيرالخدمة  ',
  _SERVICE_REMINDER_: "تذكير الخدمة ",
  _Monthly_Expenses_: "المصاريف الشهرية",
  _Weekly_Expenses_: "المصاريف الاسبوعية ",
  // _BATTERY_CHANGE_REMINDER_: 'تذكيربتغييرالبطارية  ',
  _BATTERY_CHANGE_REMINDER_: "تذكير بتغيير البطارية  ",
  _INSURANCE_REMINDER_: " تذكير بالتأمين",
  // _INSURANCE_REMINDER_: ' تذكير بالتأمين',
  // _ISTIMARA_REMINDER_: 'تذكير بالاستمارة',
  _ISTIMARA_REMINDER_: "تذكير بالاستمارة",
  _SERVICE_: "الخدمة",
  _TYRE_CHANGE_: " تغيير الاطارات",
  _OIL_CHANGE_: " تغيير الزيت",
  _INSURANCE_: "التأمين",
  _ISTIMARA_: "الاستمارة",
  _UPCOMING_: "القادمة",
  _DUE_: "مستحق",
  _OVERDUE_: "متأخر",
  _VEHICLEID_: "المركبات",
  _SEND_NOW_: "ارسال الان",
  _WAREHOUSE_LIST_: "قائمة المستودعات",
  _LICENSE_GRID_ISSUE_DATE_: "تاريخ الإصدار",
  _LICENSE_GRID_EXPIRY_DATE_: "تاريخ الانتهاء",
  _PHONE_: "الهاتف",
  _EMAIL_GRID_: "البريد  للمدير",
  _WAREHOUSE_GRID_LAND_AREA_: "مساحة الأرض",
  _Cost_InThousand_: "التكلفة (بالألف)",
  _TOTAL_RUNNING_HOURS_: "ساعة التشغيل",
  _EXCESS_RUNNING_HOURS_: "ساعات التشغيل الزائدة",
  _REGULAR_RUNNING_HOUR_: "ساعات التشغيل المنتظمة",
  _STOP_SINCE_: "توقف منذ",
  _STATIONARY_REPORT_HOURS_: "الساعات",
  _EXC_IDLE_: "استثناء الخمول",
  _TRIP_STATUS_: "حالة الرحلة",
  _PASSENGER_OUT_: "دخول الركاب",
  _PASSENGE_IN_: "خروج الركاب",
  _TRIP_NUMBER_: "قم الرحلة",
  _STATION_CODE_: " كود المحطة",
  _BATTORY_VOLTAGE_: "جهد البطارية",
  _POWER_VOLTAGE_: "فولطية الطاقة",
  _SENSOR_TYPE_: "نوع الحساس",
  _SENSOR_COUNT_: "Sensor Count",
  _SENSOR_ID_: "معرّف الحساس",
  _BATTORY_VOLTAGE_STATUS_: "حالة جهد البطارية",
  _SENSOR_BATTORY_VOLTAGE_: "جهد بطارية الحساس",
  _TEMPRATURE_ALERT_STATUS_: "حالة تنبيه درجة الحرارة",
  _SENSORE_TEMPERATURE_: "حساس الحرارة",
  _SENSOR_BUTTON_STATUS_: "حالة زر الحساس",
  _SENSOR_STATUS_: "حالة الحساس",
  _HUMIDITY_: "الرطوبة",
  _SENSOR_TIME_: "وقت الحساس",
  _REGISTERED_AT_: " مسجل في",
  _REGISTERED_BY_: " مسجل من قبل",
  _REGISTERED_BY_IP: "مسجل بواسطة IP",
  _UPDATED_AT_: "تم التحديث في ",
  _UPDATED_BY_: "تم التحديث بواسطة",
  _UPDATED_BY_IP: "تم التحديث بواسطة Ip",
  _DELETED_AT_: "تم حذفه في",
  _DELETED_BY_: "تم الحذف بواسطة",
  _DELETED_BY_IP_: "تم الحذف بواسطة Ip",
  _INVENTORIES_: "قوائم الجرد",
  _TEMPERATURE_: "درجة الحرارة",
  _HORIZONTAL_: "افقي",
  _VERTICAL_: "عمودي",
  _SEND_PUSH_NOTIFICATION_: "إرسال إشعار دفع",
  _SEND_WEB_PUSH_NOTIFICATION_: "إشعار دفع الويب",
  _PLEASE_SELECT_VEHICLE_: "الرجاء تحديد المركبات",
  _EQUIPMENT_NAME_: "اسم المعدة",
  _BRAND_: "العلامة",
  _MANUFACTURER_: "الصانع",
  _STATUS_AND_DURATION_: "الحالة والمدة الأخيرة",
  _IMPORT_EXPORT_EXCEL_: "استيراد من اكسل",
  _TYPE_OF_VEHICLE_: "نوع المركبة",
  _FUEL_TYPE_: "نوع الوقود",
  _COMMENTS_: "تعليقات",
  _REGISTRATION_DATE_: "وقت التسجيل",
  _WASL_TRIP_LIST_: "قائمة رحلات وصل",
  _PRIMARY_DRIVER_: "لسائق الأساسي",
  _ASSISTANT_DRIVER_: "مساعد السائق",
  _WASL_TRIP_REGISTRATION_: "التسجيل في رحلة وصل",
  _WASL_TRIP_TYPE_: " نوع الرحلة",
  _WASL_TRIP_DEPARTURE_STATION_CODE_: "رمز محطة المغادرة",
  _WASL_TRIP_ARRIVAL_STATION_CODE_: "رمز محطة الوصول",
  _WASL_TRIP_ARRIVAL_TIME: "وقت الوصول المتوقع",
  _WASL_TRIP_DEPARTURE_TIME: "وقت المغادرة المتوقع",
  _NO_OF_PASSENGER_: "عدد الركاب",
  _NO_OF_PASSENGERS_: "عدد الركاب",
  _FUEL_CALIBRATION_: "معايرة الوقود",
  _FUEL_CALIBRATION_NAME_: "اسم معايرة الوقود",
  _FUEL_TANK_CAPACITY_: "سعة خزان الوقود",
  _MAX_VOLTAGE_: "أقصى فولت",
  _MIN_VOLTAGE_: " ادنى فولت",
  _ADD_RECORD_: "إضافة سجل",
  _TOLERANCE_: "التسامح (طن متري)",
  _ADD_JOB_DETAILS: "ضف تفاصيل العمل",
  _NAME_WAREHOUSE_: "اسم المستودع",
  _ADDRESS_OF_WAREHOUSE_: "عنوان المستودع",
  _LAND_CORDINATES_: "عنوان المستودع",
  _SELECT_FROM_MAP: "اختر من الخريطة",
  _WAREHOUSE_EMAIL_: "بريد المدير",
  _WAREHOUSE_LAND_AREA_: "مساحة الارض (بالمتر المربع)",
  _ADD_WAREHOUSE_: "اضافة مستودع",
  _INVENTORY_NAME_: "اسم المخزون",
  _ASSIGNED_VEHICLES_: "المركبات المخصصة",
  _REMOVE_ALL_: "حذف الكل",
  _SENSORID_: "معرف الاستشعار",
  _ASSIGN_VEHCLES_SELECTEDGRP_USERSELECTION_:
    "تعيين مركبات للمجموعات المحددة (اختيار المستخدم)",
  _ASSIGN_ALL_VEHICLES_SELECTEDGRP_: "تعيين كافة المركبات للمجموعات المحددة",
  _ASSIGN_ALL_VEHICLES_OF_COMPANY_: "تعيين جميع مركبات الشركة",
  _ASSIGN_VEHICLES_COMPANY_USERSELECTION_:
    "تعيين مركبات الشركة (اختيار المستخدم)",
  _ADD_INVENTORY_: "اضافة مخزون ",
  _SELECT_ADMIN_USER_: "حدد الموزع",
  _MAP_SETTING_: "اعدادات الخريطة",
  _OFFLINE_ALERT_: "تنبيهات في وضع عدم الاتصال",
  _DRIVER_BEHAVIOR_LIST_: "قائمة سلوك السائق",
  _STOP_: "قف",
  _TODAY_ACTIVITY_: "نشاط اليوم",
  _FACEBOOK_: "فيسبوك",
  _TWITTER_: "تويتر",
  _SKYPE_: "سكايب",
  _WHATSAPP_: "واتس اب",
  _LINK_TO_SHARE_: "رابط المشاركة",
  _COPY_LINK_: "نسخ الرابط",
  _LODING_PAGE_: "الصفحة الرئيسية",
  _MENU_: "القائمة",
  _SKIP_: "تخطي",
  _PLEASE_ENTER_EMAIL_: "الرجاء إدخال البريد الإلكتروني ",
  _PLEASE_ENTER_PHONENO_: "الرجاء إدخال رقم الجوال",
  _UPDATE_INFORMATION_: "تحديث البيانات",
  _ENTER_EMAIL_OTP_: " ادخل البريد الالكتروني",
  _ENTER_PHONE_OTP_: "ادخل رقم الجوال",
  _VERIFY_OTP_WINDOW_: "تاكيد رمز التحقق",
  _RESEND_: "إعادة إرسال",
  _OTP_NOT_SENT_: "رمز التحقق لم يرسل",
  _OTP_SUCCESS_: "تم ارسال رمز التحقق بنجاح",
  _GEOFENCE_VISIT_COUNT_: "عدد زيارات السياج",

  //***************addedd by google*************
  _COUNT_: "عدد",
  _GEOFENCE_DETAILS_: "تفاصيل السياج الجغرافي",
  _COPY_LINK_: "نسخ الرابط",

  _BOOKING_: "الحجز",
  _BOOKING_DASHBOARD_: "لوحة معلومات الحجز",
  _BOOKING_REQUEST_ID_: "معرف طلب الحجز",
  _TAXI_NO_: "رقم سيارة الأجرة",
  _CUSTOMER_NO_: "رقم العميل",
  _PICKUP_ADDRESS_: "عنوان الاصطحاب",
  _DROP_ADDRESS_: "عنوان الإيصال",
  _ESTIMATE_FARE_: "الأجرة المقدرة",
  _BOOKINNG_DATE_TIME_: "تاريخ / وقت الحجز",
  _TRIP_STOP_TIME_: "وقت توقف الرحلة",
  _BOOKING_STATUS_: "حالة الحجز",
  _CONFIRM_BOOKING_: "تأكيد الحجز",
  _CONFIRM_: "تأكيد",
  _REJECT_: "رفض",
  _ADD_BOOKING_: "أضف حجز",
  _SEARCH_CUSTOMER_: "البحث عن عميل",
  _ENTER_MOBILE_NO_: "أدخل رقم الهاتف المحمول الخاص بالعميل",
  _CUSTOMER_EMAIL_ID_: "البريد الإلكتروني للعميل",
  _IMAGE_: "صورة",
  _DRIVER_RESPONSE_TIME_: "وقت استجابة السائق (بالثانية)",
  _TAXI_SEARCH_TIME_: "وقت البحث عن سيارة أجرة (دقيقة)",
  _SCHEDULE_DATE_: "تاريخ الجدول الزمني",
  _SCHEDULE_TIME_: "توقيت الجدول الزمني",
  _RIDE_NOW_: "اركب الآن",
  _RIDE_LATER_: "اركب لاحقًا",
  _FARE_DETAILS_: "تفاصيل الأجرة",
  _TAXI_TYPE_: "نوع سيارات الأجرة",
  _DISCOUNT_: "خصم",
  _BASIC_FARE_: "الأجرة الأساسية",
  _MINIMUM_FARE_: "الحد الأدنى للأجرة",
  _DISTANCE_FARE_: "أجرة المسافة",
  _VIEW_TRIP_DETAILS_: "عرض تفاصيل الرحلة",

  //Advanced Dashboard
  _FLEET_STATUS_: "حالة الأسطول",
  _IN_ACTIVE_: "غير نشط",
  _OVERSPEED_: "السرعة الزائدة",
  _OUTSIDE_GEOFENCE_: "خارج السياج الجغرافي",
  _CONNECTED_: "متصل",
  _DISCONNECTED_: "انقطع الاتصال",
  _ALERTS_TRIGGERED_: "تفعل التنبيهات",
  _FLEET_IDLE_: "خمول الاسطول",
  _TOTAL_FLEET_IDLE_HOUR_: "إجمالي ساعة خمول الأسطول",
  _APPROX_FUEL_WASTE_LTR_: "لتر من الوقود المهدر",
  _LITRES_: "لتر",
  _NOTE_: "ملاحظة",
  _FLEET_IDLE_NOTE_MSG_:
    "في مكان ما بين 1.89 إلى 2.74 لترًا من الوقود في الساعة بشكل عام ، تستخدم السيارة في وضع الخمول",
  _JOB_INFO_: "معلومات مهمة",
  _ON_GOING_: "جاري التنفيذ",
  _CANCELLED_: "تم الإلغاء",
  _TOTAL_FLEET_USAGE_: "إجمالي استخدام الأسطول",
  _AVG_DIST_VEH_: "متوسط مسافة السيارة",
  _DAY_HRS_: "ساعات النهار",
  _NIGHT_HRS_: "ساعات الليل",
  _RENEWAL_: "التجديد",
  _DISTANCE_CLASSIFICATION_: "تصنيف المسافة",
  _HARSH_BREAKING_: "فرملة مفاجئة",
  _HARSH_TURNING_: "دوران مفاجئة",
  _ONDUTY_KM_TIME_: "في الدوام كيلو ميتر والوقت",
  _OFFDUTY_KM_TIME_: "خارج الدوام كيلو ميتر والوقت",
  _TOTAL_DISTANCE_COVERED_: "المسافة الإجمالية المغطاة",
  _VEHICLE_SCORE_: "درجات أداء السيارة",
  _VEHSCORE_AC_COUNT_: "حساب",
  _VEHSCORE_BREAK_COUNT_: "عد الفرملة",
  _VEHSCORE_EXIDLE_COUNT_: "عد خمول الزائد",
  _VEHSCORE_RPM_COUNT_: "عد دورة في الدقيقة",
  _VEHSCORE_SPEED_COUNT_: "عد السرعة",
  _REMINDER_: "تذكير",
  _INVALID_EMAILID_: "البريد الالكتروني خطأ",

  //Temperature Dashboard

  _TEMP_DASHBOARD_: "Temperature Dashboard",
  _TEMP_ALERT_: "Temperature alerts",
  _TEMP_DASHBOARD_SENSOR1_: "Sensor 1",
  _TEMP_DASHBOARD_SENSOR2_: "Sensor 2",
  _TEMP_DASHBOARD_SENSOR3_: "Sensor 3",
  _TEMP_DASHBOARD_SENSOR4_: "Sensor 4",
  _SENSOR_: "Sensor",
  _SENSORS_: "أجهزة الاستشعار",
  _TEMPERATURE_TRACK_: "Temperature Track",
  _TEMPERATURE_REPORTS_: "Temperature Report",
  _TEMPERATURE_GRAPH_: "Temperature Graph",
  _TEMP_HOME_: "Home",
  _TEMPERATURE_TRACK_DATA_: "Temperature Track Data",
  _TEMP_DATE_TIME_: "Date & Time",
  _SELECT_SUB_GROUP_: "Select Sub-Group",
  _TEMP_INTERVAL_: "Interval (sec)",
  _NO_ALERTS_GENERATED_: "No Alerts Generated",
  _NO_ALERTS_INFO_:
    "All temperature and humidity range violation alerts will be reflected here.",
  _TEMP_VEHICLE_NO_: "Vehicle No",
  _Validate_: "Validate",

  _FLEET_LOGIN_REMEMBER_: "تذكرنى",

  _DISTRIBUTOR_: "موزع",
  _HIJRI_: "هجري",
  _GREGORIAN_: "الميلادي",
  _COMMERCIAL_RECORD_ISSUE_DATE1_: "تاريخ إصدار السجل التجاري",
  _PLOT_DEFAULT_: "المؤامرة الافتراضية",
  _CLUSTER_: "تَجَمَّع",
  _RFID_CHARGES_: "رسوم RFID",
  _TOTAL_CHARGES_: "الكلفة الاجماليه",
  _NEXT_: "التالي",
  _SUBMIT_: "يُقدِّم",
  _PHONE_NUMBER_1_: "رقم الهاتف الرئيسي",
  // _CONTACT_2_: 'الاتصال 2',
  _EMAIL_2_: "البريد الإلكتروني الثانوي",
  _EMAIL_1_: "البريد الإلكتروني الرئيسي",
  _PHONE_NUMBER_2_: "رقم الغوغاء الثانوي",
  _HALF_YEARLY_: "نصف سنوي",
  _DEFAULT_ROLE_: "الدور الافتراضي",
  _NO_ROLE_: "لا دور",
  _BASIC_: "أساسي",
  _INDIVIDUAL_: "فردي",
  _DEFAULT_: "تقصير",
  _TOW_CAR_: "سيارة السحب",
  _SPECIALITY_TRANSPORT_: "النقل المتخصص",
  _BUS_RENTAL_: "تأجير الحافلات",
  _EDUCATIONAL_TRANSPORT_: "النقل التعليمي",
  _SFDA_: "الهيئة العامة للغذاء والدواء",
  _PHONE_EXTENSION_: "امتداد الهاتف",
  _STATIONS_TATUS_: "حالة المحطة",
  _CITY_CODE_: "رمز المدينة",
  _ENGLISH_NAME_: "الاسم الانجليزي",
  _ARABIC_NAME_: "الاسم العربي",
  _STATION_LIST_: "قائمة المحطات",
  _STATION_REGISTRATION_: "تسجيل المحطة",
  _PERMANENT_: "دائم",
  _TEMPORARY_: "مؤقت",
  _SELECT_COUNTRY_: "حدد الدولة",
  _CLICK_TO_UPLOAD_PHOTO_ID: "انقر لتحميل معرف الصورة",
  _SELECT_EMPLOYEE_TYPE: "حدد نوع الموظف",
  _ADD_DEPARTMENT_: "إضافة قسم",
  _SELECT_COUNTRY_: "حدد الدولة",
  _SELECT_DOB_FORMAT_: "حدد تنسيق DOB",
  _FULL_TERM_: "فصل دراسي كامل",
  _EDIT_ACCOUNT_DETAILS_: "تحرير تفاصيل الحساب",
  _DELETE_ACCOUNT_: "حذف الحساب",
  _WASL_STATUS_: "حالة الوصل",
  _ASSIGN_: "تعيين",
  _EDIT_STAFF_DETAILS_: "تحرير تفاصيل الموظفين",
  _DELETE_STAFF_DETAILS_: "حذف تفاصيل الموظفين",
  _EDIT_PASSENGER_DETAILS_: "تحرير تفاصيل الركاب",
  _DELETE_PASSENGER_: "حذف الراكب",
  _SEARCH_BY_NAME_MOBILE_EMAIL_:
    "البحث حسب الاسم، رقم الجوال، البريد الإلكتروني",
  _SEARCH_BY_DRIVER_NAME_MOBILE_: "البحث حسب اسم السائق ورقم الجوال",
  _SEARCH_BY_NAME_MOBILE_TAGID_BUSNO_:
    "البحث حسب الاسم، رقم الهاتف المحمول، معرف العلامة، رقم الحافلة",
  _SEARCH_BY_VEHICLE_NO_DRIVER_NAME_: "البحث عن طريق رقم المركبة، اسم السائق",
  _PROJECT_: "مشروع",
  _CLICK_TO_UPLOAD_: "انقر للتحميل",
  _SELECT_PROJECT_: "حدد المشروع",
  _ADD_PROJECT_: "أضف المشروع",
  _PROJECT_DETAILS_: "تفاصيل المشروع",
  _RETURNED_FROM_: "عاد من",
  _EDIT_RETURN_ASSET_: "تحرير الأصول المرتجعة",
  _EDIT_MAINTENANCE_: "تحرير الصيانة",
  _DELETE_MAINTENANCE_: "حذف الصيانة",
  _OPERATION_: "عملية",
  _Id_: "بطاقة تعريف",
  _SEARCH_BY_GARAGE_SERVICE_VEHICLE_:
    "البحث عن طريق المرآب، تاريخ الخدمة، السيارة",
  _SEARCH_BY_GARAGE_VEHICLE_: "البحث عن طريق المرآب، مركبة",
  _ASSIGNED_TO_: "مخصص ل",
  _SHIFT_NAME_: "اسم التحول",
  _ASSIGN_TO_: "يسند إلى",
  _ASSIGN_BUS_TO_STUDENT_: "تعيين حافلة للطالب",
  _EDIT_DETAILS_: "عدل التفاصيل",
  _PLEASE_CREATE_LINK_: "الرجاء إنشاء الرابط",
  _car_: "سيارة",
  _SCHOOL_BUS_: "باص المدرسة",
  _PICKUP_: "يلتقط",
  _2TON_PICKUP_: "بيك أب 2 طن",
  _3TON_PICKUP_: "بيك اب 3طن",
  _5TON_PICKUP_: "بيك أب 5 طن",
  _10TON_PICKUP_: "بيك أب 10طن",
  _CRANE_: "رافعة",
  _JCB_: "جي سي بي",
  _VAN_: "سيارة نقل",
  _HIACE_: "هايس",
  _URVAN_: "أورفان",
  _TANKER_: "ناقلة",
  _BULLDOZER_: "جرافة",
  _DUMPER_: "شاحنة قلابة",
  _REEFER_: "المبردة",
  _SHOVEL_: "مجرفة",
  _EXCAVATOR_: "حفارة",
  _FORKLIF_: "رافعة شوكية",
  _BOBCAT_: "قط بري أمريكي",
  _ROLLER_: "أسطوانة",
  _CANTER_: "كانتر",
  _MINIBUS_: "ميني باص",
  _TOURIST_BUS_: "حافلة سياحية",
  _BOOM_LOADER_: "محمل بوم",
  _GENERATOR_: "مولد كهرباء",
  _HILUX_: "هايلكس",
  _MOTORCYCLE_: "دراجة نارية",
  _6_MONTHS_: "6 اشهر",
  __PRIVATE_CAR_: "سيارة خاصة",
  _PUBLIC_TRANSPORT_: "النقل العام",
  _PRIVATE_TRANSPORT_: "النقل الخاص",
  _PUBLIC_MINIBUS_: "ميني باص عمومي",
  _PRIVATE_MINIBUS_: "ميني باص خاص",
  _TAXI_: "سيارة اجره",
  _HEAVY_EQUIPMENT_: "معدات ثقيلة",
  _DIPLOMATIC_: "دبلوماسي",
  _VEHICLE_INFO_: "معلومات المركبة",
  _FUEL_ID_1_: "معرف الوقود1",
  _FUEL_ID_2_: "معرف الوقود2",
  _TEMP_ID1_: "معرف درجة الحرارة1",
  _TEMP_ID2_: "معرف درجة الحرارة2",
  _TEMP_ID3_: "معرف درجة الحرارة3",
  _TANK_DETAILS_: "تفاصيل الخزان",
  _PTA_: "منطقة التجارة التفضيلية",
  _TANK_L_IN_CM_: "الخزان (L) سم.",
  _TANK_W_IN_CM_: "الخزان (W) سم.",
  _TANK_HEIGHT_IN_CM_: "الخزان (الارتفاع) بالسم.",
  _TANK1_l_IN_CM_: "الخزان 1 (L) سم.",
  _TANK1_D_IN_CM_: "الخزان 1 (د) بالسم.",
  _TANK2_L_IN_CM_: "الخزان 2 (L) سم.",
  _TANK2_W_IN_CM_: "خزان 2 (عرض) سم.",
  _TANK2_H_IN_CM_: "الخزان 2 (ح) سم.",
  _TANK2_D_IN_CM_: "Tank2(D) بالسم.",
  _F_ID_2_: "معرف ف 2",
  _SPACE_2_CM_: "المساحة 2 (سم)",
  _TANK3_IN_CM_: "خزان 3 (لتر) سم.",
  _TANK3_W_IN_CM_: "خزان 3 (عرض) سم.",
  _TANK3_H_IN_CM_: "الخزان 3 (ارتفاع) سم.",
  _TANK3_D_IN_CM_: "Tank3(D) سم.",
  _F_ID_3_: "معرف ف 3",
  _SPACE_3_CM_: "المساحة 3 (سم)",
  _ADD_NEW_VEHICLE_: "إضافة مركبة جديدة",
  _COLUMNS_: "أعمدة",
  _DISTANCE_IN_METERS_: "المسافة بالأمتار",
  _SELECT_STATION_CODE_: "حدد رمز المحطة",
  _SELECT_ACTIVITY_: "حدد النشاط",
  _PLATE_NO_SEQUENCENO_: "رقم اللوحة، الرقم التسلسلي",
  _SEARCH_BY_BRAND_DRIVERNAME_SIMNO_IMEI_ASSET_:
    "البحث حسب العلامة التجارية، اسم السائق، رقم SIM، IMEI، الأصول",
  _INSTALLED_: "المثبتة",
  _UNINSTALLED_: "تم إلغاء تثبيته",
  _DELETED_: "تم الحذف",
  _SELECT_TANK_SHAPE_: "حدد شكل الخزان",
  _SPACE_1_CM_: "المساحة 1 (سم)",
  _SELECT_TANK2_SHAPE_: "حدد شكل Tank2",
  _SELECT_TANK3_SHAPE_: "حدد شكل الخزان 3",
  _SPACE_2_CM_: "المساحة 2 (سم)",
  _SPACE_3_CM_: "المساحة 3 (سم)",
  _SELECT_CLASS_: "اختر فئة",
  _SEARCH_BY_STUDENT_PARENT_MOBILE_EMAIL_:
    "البحث حسب اسم الطالب، اسم ولي الأمر، رقم الهاتف المحمول، معرف البريد الإلكتروني",
  _EDIT_TRIP_DETAILS_: "تعديل تفاصيل الرحلة",
  _DELETE_TRIP_DETAILS_: "حذف تفاصيل الرحلة",
  _SAVE_TO_ASSIGN_: "حفظ للتعيين",
  _ASSIGNED_: "مُكَلَّف",
  _NOT_ASSIGNED_: "غيرمعتمد",
  _SCHOOL_: "مدرسة",
  _ROUT_DEVIATION_ALERT_: "تنبيه انحراف المسار",
  _PLEASE_SELECT_ACCOUNT_: "الرجاء تحديد الحساب",
  _SEARCH_ON_MAP_: "البحث على الخريطة",
  _HISTORY_: "تاريخ",
  _START_POINT_: "نقطة البداية",
  _END_POINT_: "نقطة النهاية",
  _EDIT_NOTIFICATION_: "تحرير الإخطار",
  // _MODIFIED_BY_:'تم التعديل بواسطة'
  _DEVICE_LOG_LIST_: "قائمة سجل الجهاز",
  _ModBus_: "مودبوس",
  _NORMAL_: "طبيعي",
  _REPORT_: "تقرير",
  _EDIT_DEVICE_RECORD_: "تحرير سجل الجهاز",
  _DELETE_DEVICE_RECORD_: "حذف سجل الجهاز",

  _SAMPLE_: "عينة",
  _NEW_GEOFENCE_: "سياج جغرافي جديد",
  _RETAILS_: "التجزئة",
  _FOOD_SERVICE_: "خدمة الطعام",
  _HEAD_OFFICE_: "المكتب الرئيسي",
  _GARAGE_: "كراج",
  _OUTLET_: "مَنفَذ",
  _WE_NEED_TO_DRAW_GEOFENCE_: "نحن بحاجة إلى رسم السياج الجغرافي للعنوان",
  _DISTANCE_BETWEEN_THEM_: "المسافة بينهما",
  _PLEASE_CLICK_MAP_TO_SELECT_LOCATIONS_:
    "الرجاء الضغط على الخريطة لتحديد المواقع.",
  _VEHICLE_OR_DRIVER_: "مركبة أو سائق",
  _TEMP_PDF_: "درجة الحرارة PDF",
  _WEIGHT_PDF_: "الوزن قوات الدفاع الشعبي",
  _ALL_DATA_: "كل المعلومات",
  _TEMP_ID_: "معرف درجة الحرارة",
  _FLEET_COMPANY_PASSWORD_PROVIDE_: "من فضلك أدخل رقمك السري",
  _FLEET_COMPANY_USERNAME_PROVIDE_: "الرجاء إدخال اسم المستخدم",
  _MOVING_: "متحرك",
  _SIM_NUMBER_: "رقم سيم",

  _SELECT_COLUMNS_: "حدد الأعمدة",

  _INSIDE_CITY_: "داخل المدينة",
  _BETWEEN_CITIES_: "بين المدن",
  _MANUFACTURING_YEAR_: "تصنيع السنة",
  _IDLE_RPM_: "دورة في الدقيقة الخاملة",
  _STOPPED_RPM_: "توقفت دورة في الدقيقة",
  _MOVING_RPM_: "تتحرك دورة في الدقيقة",
  _ADDRESS_GRID_OF_WAREHOUSE_: "عنوان شبكة المستودعات",
  _HUMIDITY_: "رطوبة",
  _INPUT_2_: "الإدخال 2",
  _MAX_SPEED_: "سرعة الصدى القصوى",
  _hour_: "ساعات",
  _SEND_EMAIL_: "ارسل بريد الكتروني",
  _ALL_EVENTS_: "كل الأحداث",
  _ACCIDENT_: "حادثة",
  _PICKuP_: "يلتقط",
  _DROP_: "يسقط",
  _ALARMTYPE_: "نوع التنبيه",
  _WASREHOUSE_WASL_KEY_: "مستودع واصل مفتاح",
  _INVENTORY_WASL_KEY_: "مستودع واصل مفتاح",
  _WASL_COMPANY_: "شركة واصل",
  _WASL_VEHICLE_: "مركبة وصل",
  _WASL_DRIVER_: "سائق وصل",

  _SEARCH_BY_DRIVER_VEHICLE_: "البحث عن طريق المركبة، السائق",
  _ELAPSED_TIME_: "الوقت المنقضي",
  _GEOFENCE_DETAILS_: "تفاصيل السياج الجغرافي",
  _GEOFENCEVISIT_: "زيارة السياج الجغرافي",
  _TOTAL_EXCEL_: "إجمالي إكسل",
  _TOTAL_PDF_: "إجمالي قوات الدفاع الشعبي",
  _ROLE_SETTING_: "إعداد الدور",
  _DELETE_SETTING_: "حذف الإعداد",
  _EDIT_SETTING_: "وضع تحرير",
  _ENTER_IMEI_: "أدخل IMEI",
  _EDIT_BEHAVIOUR_SETTING_: "تعديل إعدادات السلوك",
  _DELETE_BEHAVIOUR_SETTING_: "حذف إعداد السلوك",
  _ON_: "على",
  _OFF_: "عن",
  _DAYS_: "أيام",

  _SEATBELT_VIOLATION_: "انتهاك حزام الأمان",
  _BASE_LOCATION_: "موقع القاعدة",
  _DRIVER_MOBILE_NO_: "رقم جوال السائق",
  _EX_IDLE_COUNT_: "عدد الخمول السابق",
  _LAST_TYRE_CHANGE_: "آخر تغيير للإطار (التاريخ/الوقت وعداد المسافات)",
  _NEXT_TYRE_CHANGE_KM_: "تغيير الإطار التالي (كم)",
  _FS_IDLE_TIME_: "FS وقت الخمول",
  _SEARCH_VEHICLE_DRIVER_: "البحث عن طريق المركبة، السائق",
  _MULKIYA_RENEWAL_: "تجديد الملكية",
  _EXCESSIVE_IDLE_: "الخمول المفرط",
  _VEHICLE_HARSH_ACCEL_: "التسارع الشديد للمركبة",
  _NON_STOPPAGE_: "عدم التوقف",
  _GPS_BATTERY_DISCONNECTION_: "فصل بطارية نظام تحديد المواقع",
  _UNAUTHO_DRIVING_: "القيادة غير المصرح بها",
  _NON_STOPPAGE_MIN: "عدم التوقف دقيقة",
  _COMPANY_LIST_: "قائمة الشركة",
  _PASSENGER_LIST_: "قائمة الركاب",
  _SCHOOL_CALENDER_LIST_: "قائمة التقويم المدرسي",
  _PARENT_LIST_: "قائمة الوالدين",
  _TRIP_MANAGEMENT_: "إدارة الرحلة",
  _ADVANCE_DASHBOARD_: "لوحة القيادة المتقدمة",
  _ASSET_REGISTER_REPORT_: "تقرير تسجيل الأصول",
  _DRIVER_LOGIN_REPORT_LIST_: "قائمة تقرير تسجيل دخول السائق",
  _SIM_LIST_: "قائمة SIM",
  _ALERT_LIST_: "قائمة التنبيه",
  _ALERT_MSG_DISPLAY_8_VEHICLES_AT_TIME_:
    "يتم عرض 8 مركبات فقط في الوقت المحدد",
  _DRIVER_NOT_AVAILABLE_: "السائق غير متوفر",
  _PLEASE_ENTER_COMPANY_NAME_: "الرجاء إدخال اسم الشركة",
  _PLEASE_SELECT_VEHICLE_: "الرجاء اختيار المركبة",
  _PLEASE_SEARCH_BY_OPTION_: "الرجاء تحديد البحث حسب الخيار",
  _PLEASE_SELECT_GEOFENCE_: "الرجاء تحديد السياج الجغرافي",
  _PLEASE_ENTER_LOCATION_: "الرجاء إدخال الموقع",
  _PLEASE_CLICK_ON_MAP_: "الرجاء الضغط على الخريطة",
  _PLEASE_ADD_DISTANCE_: "الرجاء إضافة المسافة",
  _JOB_MANAGEMENT_LIST_: "قائمة إدارة الوظائف",
  _GRADE_BUS_TIMING_LIST_: "قائمة توقيت حافلة الصف",
  _TEMPERATURE_GRAPH_: "الرسم البياني لدرجة الحرارة",
  _SELECT_TIME_: "حدد الوقت",
  _OPENSTREET_: "خريطة الشارع المفتوحة",
  _MIN_TEMP_: "الحد الأدنى لدرجة الحرارة",
  _ACCELERATION_FOR_: "تسريع ل",
  _BRAKING_FOR_: "الكبح ل",
  _SPEEDING_FOR_: "مسرعة ل",
  _RPM_FOR_: "دورة في الدقيقة ل",
  _ACCELERATION_RATE_: "معدل التسارع",
  _BRAKE_RATE_: "معدل الفرامل",
  _IDLE_RATE_: "معدل الخمول",
  _RPM_RATE_: "معدل دورة في الدقيقة",
  _SPEED_RATE_: "معدل السرعة",
  _EX_IDLING_FOR_: "التباطؤ السابق ل",
  _TOTAL_NO_VEHICLE_: "العدد الإجمالي للمركبة",
  _EXIDLE_GRAPH_: "الرسم البياني ExIdle",
  _TYRE_CHANGE_REAMINDER_: "تذكير بتغيير الإطارات",
  _HELP_: "يساعد",
  _VEHICLE_LIST_: "قائمة المركبات",
  _VEHICLE_GRP_LIST_: "قائمة مجموعة المركبات",
  _VEHICLE_LIVE_LOCATION_LIST_: "قائمة الموقع المباشر للمركبة",
  _VEHICLE_ODOMETER_LIST_: "قائمة عداد المسافات للمركبة",
  _VEHICLE_FUEL_CALIBERATION_LIST_: "قائمة معايرة وقود المركبات",
  _EXIDLE_REPORT_: "تقرير EX-IDLE",
  _DRIVER_RELATED_: "المتعلقة بالسائق",
  _DRIVER_LOGIN_REPORT_LIST_: "قائمة تقرير تسجيل دخول السائق",
  _DRIVER_PERFORMANCE_REPORT_LST_: "قائمة تقارير أداء السائق",
  _DRIVER_COMPLAIN_REPORT_LIST_: "قائمة تقارير شكاوى السائقين",
  _SYSTEM_EVENT_: "حدث النظام",
  _BETWN_CITIES_: "بين المدن",
  _ASSIGN_DRIVER_: "تعيين سائق",
  _VEHICLE_DISTANCE_TRAVELLED_DETAILS_: "تفاصيل المسافة المقطوعة للمركبة",
  _FLEET_DAY_SUMMRY_REPORTS_FLEETREPORT_TITLE_: "تقرير ملخص يوم الأسطول",
  _STOPPAGE_: "توقف",
  _ENTER_MONTHS_: "أدخل الأشهر",
  _SECONDARY_CONTACT_NO_: "رقم الاتصال الثانوي",
  _Enter_Primary_Contact_: "رقم الاتصال الثانوي",
  _Identity_Number_should_be_10_Digit_Number_:
    "يجب أن يتكون رقم الهوية من 10 أرقام",
  _Account_Name_Identity_Number_CR_Number_:
    "اسم الحساب، رقم الهوية، رقم السجل التجاري",
  _Enter_7_or_10_Digit_Number_: "أدخل رقمًا مكونًا من 7 أو 10 أرقام",
  _Enter_5_or_12_Digit_Number_: "أدخل رقمًا مكونًا من 5 أو 12 رقمًا",
  _Search_By_Driver_Name_Driver_Identity_Number_:
    "البحث حسب اسم السائق، رقم هوية السائق",
  _PASSENGER_DELETE_POPUP_: "هل أنت متأكد أنك تريد حذف الركاب",
  _FILE_SIZE_UPTO_10_MB: "يجب أن يكون حجم الملف أقل من أو يساوي 10 ميغابايت",
  _INSTALLATION_TYPE_MESSAGE_:
    "الرجاء تحديد نوع التثبيت كـ PTA لتمكين مستشعر الحمل",
  _RENEWAL_FOR_: "تجديد ل",
  _RENEW_VEHICLE_: "تجديد المركبة",
  _STATE_: "ولاية",
  _LENGTH_: "طول",
  _ENTER_PROJECT_NAME_: "الرجاء إدخال اسم المشروع",
  _PLEASE_SELECT_A_VEHICLE_TO_SET_GPS_ODOMETER_:
    "الرجاء اختيار مركبة لضبط عداد المسافات GPS",
  _ENTER_ACADEMIC_YEAR_FROM_: "أدخل العام الدراسي من",
  _HOLIDAY_EVENT_: "حدث العطلة",
  _CLEAR_MAP_: "خريطة واضحة",
  _PLEASE_ENTER_IMEI_: "الرجاء إدخال IMEI",
  _TOTAL_SHOULD_BE_MORE_THAN_100_:
    "يرجى التحقق من الإجمالي %، يجب أن يكون الإجمالي 100%",
  PLEASE_ENTER_NEW_PASSWORD_: "الرجاء إدخال كلمة المرور الجديدة",
  PLEASE_ENTER_OLD_PASSWORD_: "الرجاء إدخال كلمة المرور القديمة",
  PLEASE_ENTER_CONFIRM_PASSWORD_: "الرجاء إدخال تأكيد كلمة المرور",
  _NEW_PASSWORD_AND_CONFIRM_PASSWORD_MESSAGE_:
    "كلمة المرور الجديدة وتأكيد كلمة المرور غير متطابقين",
  _PLEASE_ENTER_TEMPERATURE_: "الرجاء إدخال درجة الحرارة",
  _PLEASE_ENTER_TIME_LIMIT_: "الرجاء إدخال الحد الزمني",
  _TIME_LIMIT_: "الحد الزمني",
  _SET_PARKING_TIME_LIMIT_: "ضبط الحد الزمني لوقوف السيارات (دقيقة)",
  PLEASE_CHECK_SEND_PUSH_NOTIFICATION_: "يرجى التحقق من إرسال إشعار الدفع",
  _FLEET_RULESELECTORDETAIL_RULE_PASSENGER_DESCRIPTION_:
    "يتم تفعيل هذه القاعدة عندما تتحرك السيارة بسرعة: 10 كيلومتر في الساعة والراكب لا يرتدي حزام الأمان.",
  _PLEASE_ENTER_A_VALID_EMAIL_ADDRESS_: "يرجى إدخال عنوان بريد إلكتروني صالح",
  _FLEET_RULESELECTORDETAIL_RULE_INSURANCE_RENEWAL_DESCRIPTION_:
    "يتم تفعيل هذه القاعدة مع انتهاء صلاحية الإطارات",
  _FLEET_RULESELECTORDETAIL_RULE_SPEED_VIOLATION_DESCRIPTION_:
    "يتم تفعيل هذه القاعدة عندما تتجاوز المركبة الحد المحدالسرعة للسياجات الجغرافية المحددة",
  _FLEET_RULESELECTORDETAIL_RULE_UNAUTHORIZED_DRIVER_DESCRIPTION_:
    "يتم تشغيل هذا التنبيه عند قيادة السيارة  سائق غير مصرح به",
  _FLEET_RULESELECTORDETAIL_RULE_GPS_BATTERY_DESCRIPTION_:
    "يتم تشغيل هذا التنبيه عندما تكون البطارية الداخلية لنظام تحديد المواقع العالمي (GPS) على وشك النفاد",
  _FLEET_RULESELECTORDETAIL_RULE_NON_STOPPAGE_DESCRIPTION_:
    "سيتم تشغيل هذا التنبيه عندما تتحرك السيارة لأكثر من فترة زمنية محددة",
  _FLEET_RULESELECTORDETAIL_RULE_BLE_TEMP_DESCRIPTION_:
    "سيتم تفعيل قاعدته عندما تتجاوز درجة الحرارة/الرطوبة الحد المحدد. يمكن للمستخدم ضبط الحد الأدنى والحد الأقصى لدرجة الحرارة/الرطوبة",
  _FLEET_RULESELECTORDETAIL_RULE_ACCIDENT_ALERT_DESCRIPTION_:
    "سيتم تشغيل هذا التنبيه عندما تتعرض السيارة لحادث",
  _FLEET_RULESELECTORDETAIL_RULE_TOWING_ALERT_DESCRIPTION_:
    "سيتم تشغيل هذا التنبيه عندما تتحرك السيارة ويتم إيقاف تشغيل الإشعال",
  _FLEET_RULESELECTORDETAIL_RULE_STAY_ALERT_DESCRIPTION_:
    "سيتم تشغيل هذا التنبيه إذا ظلت السيارة داخل السياج الجغرافي المحدد لأكثر من الحد الزمني المحدد",
  _FLEET_RULESELECTORDETAIL_RULE_PARKING_ALERT_DESCRIPTION_:
    "يتم إنشاء هذا التنبيه إذا كانت السيارة متوقفة لأكثر من الحد الزمني المحدد",
  _FLEET_RULESELECTORDETAIL_RULE_POWER_CUT_DESCRIPTION_:
    "سيتم تشغيل هذا التنبيه عند فصل الطاقة الرئيسية للجهاز",
  _FLEET_RULESELECTORDETAIL_RULE_PANIC_DESCRIPTION_:
    "سيتم تشغيل هذا التنبيه عند الضغط على زر الذعر",
  _FLEET_RULESELECTORDETAIL_RULE_VEHICLE_HARSH_BRAKING_DESCRIPTION_:
    "سيتم تشغيل هذه التنبيهات بناءً على الحدث المستلم من أجهزة GPS",
  _FLEET_RULESELECTORDETAIL_RULE_MULKIYA_RENEWAL_DESCRIPTION_:
    "سيتم تشغيل هذه التنبيهات بناءً على الحدث المستلم من أجهزة GPS",
  _FLEET_RULESELECTORDETAIL_RULE_EXCESSIVE_IDLE_DESCRIPTION_:
    "سيتم تفعيل هذه القاعدة عندما تكون السيارة في وضع الخمول بعد تجاوز الحد المحدد في صفحة السيارة",
  _FLEET_RULESELECTORDETAIL_RULE_EXCESSIVE_IGINITION_DESCRIPTION_:
    "سيتم تفعيل هذه القاعدة عند تشغيل وإيقاف تشغيل السيارة",
  _FLEET_RULESELECTORDETAIL_RULE_ROAD_OVERSPEED_DESCRIPTION_:
    "سيتم تفعيل هذه القاعدة عند تشغيل وإيقاف تشغيل السيارة",
  _FLEET_RULESELECTORDETAIL_RULE_ENGINE_TEMP_DESCRIPTION_:
    "سيتم تشغيل هذا التنبيه عندما تتجاوز درجة حرارة المحرك الحد المحدد",
  _DURATION_: "المدة (دقيقة)",
  _RATE_: "معدل",
  _TIME_: "وقت",
  _SENSOR_: "المستشعر",
  _COMMAND_NOT_DEFINED_: "الأمر غير محدد",
  _SELECT_SEQUENCE_FIRST_: "حدد التسلسل أولاً",
  _VEHICLE_MAINTENANCE_LIST_: "قائمة صيانة المركبات",

  _EXIDLE_DURATION_IN_MINUTE_: "مدة الخمول السابقة (بالدقيقة)",
  _VEHICLE_TEMP_SENSOR_LIST_: "قائمة أجهزة استشعار درجة حرارة السيارة",
  _VEHICLE_WORKING_HOURS_LIST_: "قائمة ساعات عمل المركبة",
  _VEHICLE_TRAILER_LIST_: "قائمة مقطورة السيارة",
  _HEIGHT__: "ارتفاع",
  _WIDTH_: "عرض",
  _DIAMETER_: "قطر الدائرة",
  _UPDATE_GEOFENCE_: "تحديث السياج الجغرافي",
  _SELECT_CHANNEL_: "حدد القناة",
  _CHANGE_RADIUS_: "تغيير نصف القطر",
  _CHANGE_OPACITY_: "تغيير التعتيم",
  _CHANGE_GRADIENT_: "تغيير التدرج",
  _TOGGLE_HEATMAP_: "تبديل الخريطة الحرارية",
  _WAREHOUSE_MONITORING_: "مراقبة المستودعات",
  _WAREHOUSE_: "مستودعا",
  _ENTER_PHONE_NO_: "أدخل رقم الهاتف",
  _Enter_8_or_10_Digit_: "أدخل رقمًا مكونًا من 8 إلى 10 أرقام",
  _COMMERCIAL_RECORD_NUMBER_ARABIC_: "رقم السجل التجاري عربي",
  _COMPANY_NAME_ARABIC_: "اسم الشركة عربي",
  _LICENSE_NUMBER_ARABIC_: "رقم الترخيص عربي",
  _DRIVER_NAME_ARABIC_: "اسم السائق عربي",
  _COMPANY_NAME_ARABIC_: "اسم الشركة عربي",
  _STATUS_ACTIVE_: "هل أنت متأكد من تغيير الحالة إلى نشط؟",
  _STATUS_INACTIVE_: "هل أنت متأكد من تغيير الحالة إلى غير نشط؟",
  _EXIDLE_GRAPH_: "الرسم البياني الخامل السابق",
  _ENGINEE_CURRENT_LOAD_: "الحمل الحالي للمحرك",
  _ENGINE_TOTAOL_FUEL_USE_: "إجمالي الوقود المستخدم للمحرك",
  _ENGINE_TOTAL_HOURSE_OF_OPERATION_: "إجمالي ساعات تشغيل المحرك",
  _ENGINE_COOLENT_TEMETATURE_: "درجة حرارة سائل تبريد المحرك",
  _INSTANTANIOUSE_FUEL_ECONOMY_: "الاقتصاد الفوري في استهلاك الوقود",
  _ADMIN_REPORT_: "تقرير المشرف",
  _CURRENT_FUEL_LEVEL_: "مستوى الوقود الحالي",
  _SELECT_DATE_: "حدد تاريخ",
  _ALERT_TRIGGER_: "تنبيه الزناد",
  _INVENTORY_ONE_: "المخزون 1",
  _INVENTORY_TWO_: "المخزون 2",
  _INVENTORY_THREE_: "المخزون 3",
  _INVENTORY_FOUR_: "المخزون 4",
  _NO_DATA_AVAILABLE_: "لا تتوافر بيانات",
  _TEMPERATURE_DASHBOARD_: "لوحة قياس درجة الحرارة",
  _HOME_: "بيت",
  _SELECT_SUB_GROUP_: "حدد المجموعة الفرعية",
  _SENSOR4_: "الاستشعار 4",
  _MINIMUM_: "الحد الأدنى",
  _MAXIMUM_: "أقصى",
  _FAULTY_: "متعطل",
  _SENSOR_DETAILS_: "تفاصيل الاستشعار",
  _MIN_TEMP_ALERT_: "تنبيه الحد الأدنى لدرجة الحرارة",
  _MAX_TEMP_ALERT_: "تنبيه درجة الحرارة القصوى",
  _TEMPERATURE_ALERTS_: "تنبيهات درجة الحرارة",
  _NO_ALERTS_GENERATED_: "لم يتم إنشاء أي تنبيهات",
  _RANGE_VIOLATION_ALERTS_:
    "ستظهر هنا جميع تنبيهات انتهاك نطاق درجة الحرارة والرطوبة.",
  _CURRENT_STATUS_: "الحالة الحالية",
  _STATE_DURATION_: "مدة الولاية",
  _LAST_SIGNAL_SYNC_: "آخر مزامنة للإشارة",
  _FULL_SUMMARY_: "ملخص كامل",
  _FINISH_LOCATION_: "إنهاء الموقع",
  _WORK_TIME_: "وقت العمل",
  _STOP_DURATION_: "مدة التوقف",
  _DRIVING_DURATION_: "مدة القيادة",
  _START_: "يبدأ",
  _ACCIDENT_COUNT_: "عدد الحوادث",
  _SHARE_LIVE_LOCATION_: "شارك الموقع المباشر",
  _ENTER_EXCEL_: "الرجاء إدخال ملف Excel",
  _EMPLOYEE_LIST_: "قائمة موظف",
  _EMPLOYEE_DETAILS_: "تفاصيل الموظف",
  _USER_ID_: "معرف المستخدم",
  _CUSTOMER_CODE_: "رمز العميل",
  _ROUTE_ID_: "معرف الطريق",
  _ROUTE_NAME_: "اسم الطريق",
  _CREATED_DATE_: "تاريخ الإنشاء",
  _INVOICE_DATE_: "تاريخ الفاتورة",
  _DEPOSITOR_: "المودع",
  _ALLOCATE_TIME_: "تخصيص وقت",
  _QUIT_: "يترك",
  _LOGOUT_STATUS_: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
  _VOLTAGE_DROP_: "انخفاض الجهد",
  ARABIC_VEHICLE_NO_: "رقم المركبة العربية",
  _MULKIYA_TYPE_: "نوع الملكية",
  _AREA_: "منطقة",
  _RESPONSIBLE_MANAGER: "المدير المسؤول",
  _VEHICLE_OWNERSHIP_: "ملكية المركبة",
  _SMART_PHONE_: "هاتف ذكي",
  _MULKIYA_RENEWAL_DATE_1: "تاريخ تجديد الملكية",
  _MDVR_IMEI_: "مدفر IMEI",
  _MAX_IDLE_LIMIT_: "الحد الأقصى للخمول",
  _MIN_FINE_AMOUNT_: "الحد الأدنى لمبلغ الغرامة",
  _FINE_PER_MINUTE_: "غرامة في الدقيقة",
  _SPEEDMILEAGE_: "السرعة/المسافة المقطوعة",
  _RULE_: "قاعدة",
  _SEAT_SENSORS_: "مستشعر المقاعد",
  _SEATING_CAPACITY_: "سعة الجلوس",
  _INPUT_DIGITAL_: "الإدخال الرقمي",
  _SPEED_CONSTANT_: "ثابت السرعة",
  _MILEAGE_PER_CIRCLE_: "عدد الكيلومترات لكل دائرة",
  _PULSE_COUNT_PER_CIRCLE_: "عدد النبض لكل دائرة",
  _SUBTOTAL_AMOUNT_: "المبلغ الاجمالي",
  _SERVICE_CATEGORY_: "فئة الخدمة",
  _CUSTOMER_NAME_INVOICE_NO_: "اسم العميل، رقم الفاتورة، تاريخ الفاتورة",
  _ODOMETER_RECORDS_: "سجلات عداد المسافات",
  _PROCESS_DATA_: "معالجة البيانات",
  _SHEDULED_AT_: "المقرر في",
  _ADD_INVOICE_: "أضف الفاتورة",
  _CANCEL_JOB_: "إلغاء الوظيفة",
  _REASON_TO_CANCEL_JOB_: "سبب إلغاء الوظيفة",
  _TRIP_DISTANCE_TRAVELLED_: "مسافة الرحلة المقطوعة",
  _DOOR_OPEN_CLOSE_ALERT_: "تنبيه فتح/إغلاق الباب",
  _DOOR_OPEN_CLOSE_ALERT_STATEMENT_:
    "سيتم تشغيل هذا التنبيه عند فتح باب المركبة أو إغلاقه.",
  _MULKIYA_RENEWAL_ALERT_: "تنبيه تجديد الملكية",
  _MULKIYA_RENEWAL_ALERT_STATEMENT_:
    "يتم تفعيل هذا التنبيه قبل 30 يومًا و15 يومًا و7 أيام من تاريخ انتهاء الملكية.",
  _INSURANCE_RENEWAL_ALERT_: "تنبيه تجديد التأمين",
  _INSURANCE_RENEWAL_ALERT_STATEMENT_:
    "يتم تشغيل هذا التنبيه قبل 30 يومًا و15 يومًا و7 أيام من تاريخ انتهاء التأمين.",
  _BATTERY_CHANGE_ALERT_: "تنبيه تغيير البطارية",
  _BATTERY_CHANGE_ALERT_STATEMENT_:
    "يتم تشغيل هذا التنبيه قبل 30 يومًا و15 يومًا و7 أيام من تاريخ تغيير البطارية التالي.",
  _SERVICE_OIL_TYRE_CHANGE_ALERT_: "تنبيه الخدمة/الزيت/تغيير الإطارات.",
  _SERVICE_OIL_TYRE_CHANGE_ALERT_STATEMENT_:
    "يتم تشغيل هذا التنبيه عند حلول موعد الصيانة أو تغيير الزيت أو الإطارات للسيارة المحددة.",
  _TAXI_CUSTOMERS_: "عملاء سيارات الأجرة",
  _NAME_: "اسم",
  _EMAIL_ADDRESS_: "عنوان البريد الإلكتروني",
  _DISCOUNT_LIST_: "قائمة الخصم",
  _DISCOUNT_NAME_: "اسم الخصم",
  _DISCOUNT_TYPE_: "نوع الخصم",
  _DISCOUNT_: "تخفيض",
  _ADD_DISCOUNT_: "إضافة خصم",
  _FLAT_AMOUNT_: "مبلغ مقطوع",
  _DISCOUNT_AMOUNT_: "مقدار الخصم",
  _ADVERTISEMENT_: "إعلانات",
  _INTERVAL_SEC_: "الفاصل الزمني (ثانية)",
  _IMAGE_VIDEO_TYPE_: "نوع الصورة/الفيديو",
  _PICTURE_VIEW_: "عرض الصورة",
  _PLEASE_SELECT_IMAGE_VIDEO_: "الرجاء تحديد الصورة/الفيديو",
  _FULL_BANNER_: "راية كاملة",
  _MIDDLE_BANNER_: "الراية الوسطى",
  _BOTTOM_BANNER_: "لافتة سفلية",
  _AUDIO_VIEW_: "عرض الصوت",
  _AUDIO_MESSAGE_: "رسالة صوتية",
  _PLEASE_SELECT_AUDIO_: "الرجاء تحديد الصوت",
  _POINT_OF_INTEREST_TYPE_: "نوع نقطة الاهتمام",
  _EVENT_LIST_: "قائمة الأحداث",
  _TITLE_: "عنوان",
  _NEWS_: "أخبار",
  _SPECIAL_OFFER_: "عرض خاص",
  _ADD_NEWS_: "أضف أخبار",
  _ADD_OFFER_: "أضف العرض",
  _ADD_POI_: "أضف نقطة اهتمام",
  _NEW_MAINTENANCE_: "صيانة جديدة",
  _MAINTENANCE_TYPE_: "نوع الصيانة",
  _ADD_NEW_MAINTENANCE_: "إضافة صيانة جديدة",
  _SUB_GROUP_LIST_: "قائمة المجموعة الفرعية",
  _SERVICE_ID_: "معرف الخدمة",
  _SERVICE_TEMPLATE_: "قالب الخدمة",
  _VEHICLE_AUDIT_: "تدقيق المركبات",
  _ACCIDENT_ANALYSIS_: "تحليل الحوادث",
  _VEHICLE_AUDIT_SCORE_: "نقاط تدقيق المركبات",
  _ACCIDENT_REASON_: "سبب الحادث",
  _OUTSIDE_PARTY_: "الحفلة الخارجية",
  _TAXI_TYPE_: "نوع التاكسي",
  _TAXI_TYPE_LIST_: "قائمة أنواع سيارات الأجرة",
  _QUEUE_LIST_: "قائمة الانتظار",
  _POSITION_: "موضع",
  _SUB_GROUP_DETAILS_: "تفاصيل المجموعة الفرعية",
  _TAXI_MANAGEMENT_: "إدارة سيارات الأجرة",
  _TAXI_DEFAULT_PARAMETERS_: "معلمات التاكسي الافتراضية",
  _FARE_DETAILS_: "تفاصيل الأجرة",
  _DAY_TIME_FROM_: "وقت النهار من (ساعات)",
  _DAY_TIME_TO_: "وقت النهار إلى (ساعات)",
  _SPEED_DISTANCE_: "السرعة/المسافة",
  _MAX_SPEED_INTERVAL_SEC_: "الفاصل الزمني الأقصى للسرعة (ثانية)",
  _DIGITAL_INPUT_1: "الإدخال الرقمي 1",
  _DIGITAL_INPUT_2: "الإدخال الرقمي 2",
  _DIGITAL_INPUT_3: "الإدخال الرقمي 3",
  _DIGITAL_INPUT_4: "المدخلات الرقمية 4",
  _PRINTER_: "طابعة",
  _TAXI_PARAMETER_LIST_: "قائمة معلمات سيارات الأجرة",
  _TARIFF_NAME_: "اسم التعريفة",
  _BASIC_FARE_: "الأجرة الأساسية",
  _MINIMUM_FARE_: "الحد الأدنى للأجرة",
  _DISTANCE_FARE_: "أجرة المسافة",
  _TIME_FARE_: "أجرة الوقت",
  _VALID_FROM_DATETIME_: "صالح من التاريخ",
  _VALID_TO_DATETIME_: "صالح للتاريخ",
  _FAIR_TYPE_: "النوع العادل",
  _TAX_PERCENTAGE_: "نسبة الضريبة",
  _BOOKING_CHARGES_: "رسوم الحجز",
  _ADD_VERSION_: "إضافة نسخة",
  _VERSION_: "إصدار",
  _HOLIYDAY_: "عطلة",
  _LONG_DISTANCE_FARE_: "أجرة المسافة الطويلة",
  _LONG_DISTANCE_KM_: "المسافة الطويلة (كم)",
  _PEAK_TIME_FROM_: "وقت الذروة من",
  _PEAK_TIME_TO_: "وقت الذروة ل",
  _PEAK_DISTANCE_FARE_: "أجرة مسافة الذروة",
  _PEAK_TIME_FARE_: "أجرة وقت الذروة",
  _PEAK_BASIC_FARE_: "الأجرة الأساسية لذروة الأسعار",
  _PEAK_MIN_FARE_: "أجرة الذروة دقيقة",
  _TAXI_PARAMETERS_: "معلمات التاكسي",
  _ASSIGN_ZONE_: "تعيين المنطقة",
  _QUESTION_: "سؤال",
  _ANSWER_: "إجابة",
  _PREDEFINED_MESSAGES_LIST_: "قائمة الرسائل المحددة مسبقًا",
  _ADD_PREDEFINED_MESSAGES_: "إضافة رسالة محددة مسبقًا",
  _TOTAL_JOB_: "الوظيفة الإجمالية",
  _STARTED_: "بدأت",
  _DELIVERIES_: "التسليم",
  _JOB_COMPLETED_: "أنجزت المهمة",
  _PENDING_JOBS_: "الوظائف المعلقة",
  _CANCELLED_JOBS_: "الوظائف الملغاة",
  _CREATED_AT_: "أنشئت في",
  _UPDATED_FIELD_: "الحقل المحدث",
  _CANCELLED_AT_: "تم الإلغاء عند",
  _CANCELLED_BY_: "تم الإلغاء بواسطة",
  _DELIVERY_LOG_: "سجل التسليم",
  _DELIVERY_MANAGEMENT_REPORT_: "تقرير إدارة التسليم",
  _UNDELIVERED_REASON_: "سبب عدم التسليم",
  _NO_OF_ATTEMPTS_: "عدد المحاولات",
  _LOG_: "سجل",
  _DETAILED_JOB_REPORT_: "تقرير مفصل عن الوظيفة",
  _TOTAL_KM_DAY_: "إجمالي كم/يوم",
  _JOB_STARTED_TIME_: "وقت بدء الوظيفة",
  _JOB_FINISHED_TIME_: "وقت انتهاء المهمة",
  _TRAVEL_KM_: "كم السفر",
  _SELECT_VEHICLE_BY_: "حدد المركبة حسب",
  _SPEED_DURATION_: "مدة السرعة",
  _VENDOR_ID_: "معرف البائع",
  _SPECIAL_: "خاص",
  _NEIGHT_PRICE_: "سعر الليلة",
  _BIG_PRICE_: "سعر كبير",
  _BIG_NEIGHT_PRICE_: "سعر الليلة الكبيرة",

  _FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_1: "اسم الأصل (اسم السائق)",
  _SELECT_VEHICLE_1: "اختر المركبة (اسم السائق)",
};
