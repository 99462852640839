import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Checkbox, Col, DatePicker, Form, Input, Select } from "antd";

const VehicleInfoTabFormFields = (props) => {
  const shouldShow = shouldShowComponent(
    config.project,
    "VehicleInfoTabFormFields"
  );

  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Col span={12} className="formColumnClass">
        {props.isadmin.isadmin !== 1 && (
          <>
            <Form.Item
              name="installer"
              // label=" Installer"
              label={storeContext.translate("_INSTALLER_")}
              // rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="installedLocation"
              // label=" Installed Location"
              label={storeContext.translate("_INSATALLED_LOCATION_")}
              // rules={[{ required: true }]}
            >
              <Input></Input>
            </Form.Item>
          </>
        )}

        <div className="vehicleFormCheckbox">
          {props.isadmin.isadmin !== 1 && (
            <>
              <Form.Item
                name="addOn"
                // label="Add On"
                label={storeContext.translate("_ADDON_")}
                style={{ marginTop: "14px" }}
              >
                <Checkbox
                  // value={this.state.DriverId}
                  checked={props.This.DriverId}
                  //   onChange={(e) => {
                  onChange={async (e) =>
                    props.handleCheckboxChange(e.target.checked, "DriverId")
                  }
                  // props.This.this.setState({
                  //   DriverId: e.target.checked,
                  // });
                  //   }}
                >
                  {/* Driver Id */}
                  {storeContext.translate("_FLEET_REPORTS_DRIVER_ID_")}
                </Checkbox>

                <Checkbox
                  value={4}
                  checked={props.This.rfid}
                  //   onChange={(e) => {
                  //     this.setState({ rfid: e.target.checked });
                  //   }}
                  onChange={async (e) =>
                    props.handleCheckboxChange(e.target.checked, "rfid")
                  }
                >
                  {/* RFID */}
                  {storeContext.translate("_FLEET_DASHBORD_SETTING_RFID_")}
                </Checkbox>
              </Form.Item>
            </>
          )}
        </div>

        <Form.Item
          name="password"
          // label="Hour Meter Reading"
          label={storeContext.translate("_FLEET_LOGIN_PASSWORDLBL_")}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="UDID"
          // label="Hour Meter Reading"
          label={storeContext.translate("_DEVICE_UDID_")}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="UDID2"
          // label="Hour Meter Reading"
          label={storeContext.translate("_DEVICE_UDID2_")}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="seatCapacity"
          // label="Hour Meter Reading"
          label={storeContext.translate("_SEATING_CAPACITY_")}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="noOfBaggage"
          // label="Hour Meter Reading"
          label={storeContext.translate("_MAX_NO_BAGGAGE_")}
        >
          <Input />
        </Form.Item>

        {props.This.mdvrFields && (
          <>
            <Form.Item
              name="cmsvssWebHost"
              // label={this.context.translate("_MDVR_IMEI_")}
              label="CMS/VSS Web Host"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="MDVR"
              // label={this.context.translate("_MDVR_IMEI_")}
              label="MDVR Server"
            >
              <Select
                placeholder="Select Type"
                options={[
                  {
                    label: "CMS",
                    value: "CMS",
                  },
                  {
                    label: "VSS",
                    value: "VSS",
                  },
                  {
                    label: "JT1078",
                    value: "JT1078",
                  },
                  {
                    label: "H-Protocol",
                    value: "H-Protocol",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="cmsvssWebPort"
              // label={this.context.translate("_MDVR_IMEI_")}
              label="CMS/VSS Web Port"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="cmsvssLoginServerPort"
              label="CMS/VSS Login Server Port"
              // label={this.context.translate("_MDVR_IMEI_")}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="cmsvssPlaybachPort"
              // label={this.context.translate("_MDVR_IMEI_")}
              label="CCMS/VSS Login Playback Port"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="cmsvssUserName"
              // label={this.context.translate("_MDVR_IMEI_")}
              label="CMS/VSS Username"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="cmsvssPassword"
              // label={this.context.translate("_MDVR_IMEI_")}
              label="CMS/VSS Password"
            >
              <Input />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="harshAcce"
          // label="Hour Meter Reading"
          label={storeContext.translate("_HARSH_ACCELERATION_")}
          initialValue={2}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="harshBrack"
          // label="Hour Meter Reading"
          label={storeContext.translate("_HARSH_BRACKING_")}
          initialValue={2}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12} className="formColumnClass">
        <Form.Item
          name="exIdeling"
          // label="Hour Meter Reading"
          label={storeContext.translate("_EX_IDELING_")}
          initialValue={2}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="speeding"
          // label="Hour Meter Reading"
          label={storeContext.translate("_SPEEDING_")}
          initialValue={2}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="rpmExcess"
          // label="Hour Meter Reading"
          label={storeContext.translate("_RPM_EXCESS_")}
          initialValue={2}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="inputdigital"
          label={storeContext.translate("_INPUT_DIGITAL_")}
        >
          <Select
            options={[
              {
                label: storeContext.translate("_FLEET_DASHBORD_SETTING_DOOR_"),
                value: "Door",
              },
              {
                label: storeContext.translate(
                  "_FLEET_DASHBORD_SETTING_SEATBELT_"
                ),
                value: "Seatbelt",
              },
              {
                // label: "ACC",
                label: storeContext.translate("_FLEET_DASHBORD_SETTING_ACC_"),
                value: "ACC",
              },
              {
                // label: "Panic",
                label: storeContext.translate("_FLEET_DASHBORD_SETTING_PANIC_"),
                value: "Panic",
              },
              {
                // label: "AC ",
                label: storeContext.translate("_FLEET_DASHBORD_SETTING_AC_"),
                value: "AC",
              },
            ]}
          />
        </Form.Item>
        {(Number(props.isadmin.isadmin) === -1 ||
          Number(props.isadmin.isadmin) === 3) && (
          <>
            <Checkbox
              style={{ marginTop: "14px" }}
              // value={this.state.fuelSensor}
              checked={props.This.fuelSensor}
              onChange={async (e) =>
                props.handleCheckboxChange(e.target.checked, "fuelSensor")
              }

              //   onChange={(e) => {
              //     this.setState({ fuelSensor: e.target.checked });
              //   }}
            >
              <span style={{ fontWeight: "bold" }}>
                {storeContext.translate("_FUEL_SENSOR_")}
              </span>
            </Checkbox>

            <Form.Item
              name="fuelId1"
              // label="Fuel Id1"
              label={storeContext.translate("_FUEL_ID_1_")}
              className={!props.This.fuelSensor && "disabledLabel"}
            >
              <Input disabled={props.This.fuelSensor ? false : true} />
            </Form.Item>

            <Form.Item
              name="fuelId2"
              // label="Fuel Id2"
              label={storeContext.translate("_FUEL_ID_2_")}
              className={!props.This.fuelSensor && "disabledLabel"}
            >
              <Input disabled={props.This.fuelSensor ? false : true} />
            </Form.Item>

            <Checkbox
              style={{ marginTop: "14px" }}
              // value={4}
              checked={props.This.temperatureSensor}
              //   onChange={(e) => {
              //     this.setState({
              //       temperatureSensor: e.target.checked,
              //     });
              //   }}
              onChange={async (e) =>
                props.handleCheckboxChange(
                  e.target.checked,
                  "temperatureSensor"
                )
              }
            >
              <span style={{ fontWeight: "bold" }}>
                {/* Temperature Sensor */}
                {storeContext.translate("_TEMPERATURE_SENSOR_")}
              </span>
            </Checkbox>

            <>
              <Form.Item
                name="tempId1"
                // label="Temp Id1"
                label={storeContext.translate("_TEMP_ID1_")}
                className={!props.This.temperatureSensor && "disabledLabel"}
              >
                <Select
                  disabled={props.This.temperatureSensor ? false : true}
                />
              </Form.Item>
              <Form.Item
                name="tempId2"
                // label="Temp Id2"
                label={storeContext.translate("_TEMP_ID2_")}
                className={!props.This.temperatureSensor && "disabledLabel"}
              >
                <Select
                  disabled={props.This.temperatureSensor ? false : true}
                />
              </Form.Item>
              <Form.Item
                name="tempId3"
                // label="Temp Id3"
                label={storeContext.translate("_TEMP_ID3_")}
                className={!props.This.temperatureSensor && "disabledLabel"}
              >
                <Select
                  disabled={props.This.temperatureSensor ? false : true}
                />
              </Form.Item>
            </>
          </>
        )}
      </Col>
    </>
  );
};

VehicleInfoTabFormFields.contextType = StoreContext;
export default observer(withRouter(VehicleInfoTabFormFields));
