import { encode as base64_encode } from "base-64";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";

class VideoMonitoringStore {
  deviceListArray = [];
  playbackData = [];

  constructor(rootStore) {
    makeObservable(this, {
      deviceListArray: observable,
      playbackData: observable,
      getDevices: action,
      getPlayBackVideoNew: action,
      getPlaybackData: action,
      stopBsjVideo: action,
      getPlaybackVideoData: action,
      bsjVideoMonitoring: action,
    });
    this.rootStore = rootStore;
  }

  async getDevices(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=getdevices2",
        "postData=" + base64Input
      );

    this.deviceListArray = response.data.data?.vehicles;

    let liveTrackingData = this.deviceListArray;
    Object.keys(liveTrackingData).map((e, i) => {
      let r = liveTrackingData[i];

      const diff = moment(r.currtime).diff(r.updatedate, "minutes");

      var vehicleTypeNew = r.vehicleTypeNew.split(" ").join("-");
      let defaultImage = "car-icon-blue.png";

      if (diff >= 90) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-gray.png";
        liveTrackingData[i].rowBackground = "gray";
        liveTrackingData[i].currentStatus = "No Data";
        defaultImage = "default-gray.png";
      } else if (r.acc == 1 && r.speed == 0) {
        //idle)
        liveTrackingData[i].listImage = vehicleTypeNew + "-yellow.png";
        liveTrackingData[i].rowBackground = "yellow";
        liveTrackingData[i].currentStatus = "Idle";
        defaultImage = "default-yellow.png";
      } else if (r.acc == 0) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-red.png";
        liveTrackingData[i].rowBackground = "red";
        liveTrackingData[i].currentStatus = "Stopped";
        defaultImage = "default-red.png";
      } else if (r.acc == null || (r.acc == 1 && r.speed > 0)) {
        liveTrackingData[i].listImage = vehicleTypeNew + "-green.png";
        liveTrackingData[i].rowBackground = "green";
        liveTrackingData[i].currentStatus = "Running";
        defaultImage = "default-green.png";
      }
      liveTrackingData[i].mapImage = liveTrackingData[i].listImage;
      try {
        require("../assets/images/detailtracking/" +
          liveTrackingData[i].listImage);
      } catch (err) {
        liveTrackingData[i].listImage = defaultImage;
      }
      try {
        require("../assets/images/livetracking/" +
          liveTrackingData[i].mapImage);
      } catch (err) {
        liveTrackingData[i].mapImage = defaultImage; //"car-icon-blue.png";
      }
    });
    return response.data.data?.vehicles;
  }

  async getPlayBackVideoNew(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=getPlayBackVideoNew",
        "postData=" + base64Input
      );
    return response.data.data[0];
  }

  async howenMDVRSocket(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=howenMDVRVideoMonitoring",
        "postData=" + base64Input
      );
    //console.log(response);

    return response;
  }

  async getPlaybackData(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=getbsjdata",
        "postData=" + base64Input
      );

    this.playbackData = response?.data?.data;
    return response;
  }

  async getPlaybackVideoData(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=bsjPlaybackVideoMonitoring",
        "postData=" + base64Input
      );
    return response?.data?.data;
  }

  async stopBsjVideo(data) {
    var postData = {
      channelId: data.logicalChannelNumber,
      full_imei: data.terminalPhone,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=bsjVideoStopCommand",
        "postData=" + base64Input
      );
    return response;
  }

  async bsjVideoMonitoring(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=bsjVideoMonitoring",
        "postData=" + base64Input
      );
    return response;
  }
}

export default VideoMonitoringStore;
