import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";

import config from "../config";
import { action, makeObservable, observable } from "mobx";

class FuelGraphStore {
  fuelGraph = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      fuelGraph: observable,
      total: observable,
      loading: observable,
      loadFuelGraphData: action,
      downloadFuelGraph: action
    });
    this.rootStore = rootStore;
  }

  async loadFuelGraphData({
    offset,
    limit,
    companyId,
    vehicleId,
    fromDate,
    toDate,
    Date,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      fromDate: fromDate,
      toDate: toDate,

      offset: 0,
      limit: 15,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=fuel&a=getFuelGraphDetails", "postData=" + base64Input);
    console.log("fuel", response?.data?.data?.FuelDetails);
    var FuelGraphData = response?.data?.data?.FuelDetails;
    // if (FuelGraphData && FuelGraphData) {
    //   FuelGraphData.forEach((element) => {
    //     element.fuel = ( element.fuel ? null : 0) 
    //   });
    // }
    this.fuelGraph = FuelGraphData;

    // this.distanceGraph = response?.data?.data?.distancegraph;
    return response?.data?.data?.FuelDetails;
  }
  async downloadFuelGraph({ companyId, vehicleId, fromDate, toDate,interval }) {
    try {
      const postData = {
        companyId,
        vehicleId,
        fromDate,
        toDate,
        int:interval,
        page: 1,
      };

      const base64Input = await base64_encode(JSON.stringify(postData));


      try {
        var response = await postApi
        .getClient()
        .post("index.php?c=fuel&a=DownloadFuelGraph", "postData=" + base64Input,
          {  responseType: "blob"}
        );
        if (!response || !response.data) {
          console.error("Empty response received.");
          return;
        }

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "fuel_graph_report.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Clean up the URL
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    } catch (error) {
      console.error("Error downloading Fuel Graph:", error);
    }
  }

}

export default FuelGraphStore;
