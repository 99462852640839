import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Tree,
  Image,
} from "antd";
import React, { Component } from "react";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { addMinutes, format } from "date-fns";
import dayjs from "dayjs";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import VideoMonitoringStore from "../store/VideoMonitoringStore";
import { CheckLg, PauseCircle, PlayCircle } from "react-bootstrap-icons";

const timeArray = [];

const startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
const endTime = new Date(2000, 0, 1, 23, 30); // 11:45 P

for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
  const formattedTime = format(time, "HH:mm:ss");
  var ob = {
    formattedTime: formattedTime,
    time: time,
  };
  timeArray.push(ob);
}

class VideoMonitoring extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      videoUrlArray: [],
      companyId: "",
      iframeSrcs: [],
      openVideoModal: false,
      selectedIframe: null,
      showPlaybackTable: false,

      isPause: true,
      treeData: [],
      playbackVideoUrl: "",
      showVideoWindow: false,
      activeRow: null,
      channelId: 0,
      vehicleId: "",
      selectedData: [],
      showVideo: "",
      openCard: true,
      hiddenIframeKeys: [],
    };
    this.videoMonitoringStore = new VideoMonitoringStore(this);

    this.columns = [
      {
        title: context.translate("_CHANNEL_"),
        dataIndex: "logicalChannelNumber",
        width: "12%",
      },
      {
        title: context.translate("_STARTTIME_"),
        dataIndex: "startTime",
        width: "18%",
      },
      {
        title: context.translate("_END_TIME_"),
        dataIndex: "endTime",
        width: "18%",
      },
      {
        title: context.translate("_PLAY_"),
        width: "10%",
        render: (text, record) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {this.state.activeRow === record.startTime ? (
              <Tooltip title={"Pause"} color="green">
                <PauseCircle
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() => {
                    this.setState({
                      activeRow: null,
                      showVideoWindow: false,
                      playbackVideoUrl: "",
                    });
                    this.videoMonitoringStore.stopBsjVideo(record);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Play"} color="green">
                {" "}
                <PlayCircle
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={() => {
                    if (this.state.channelId === record.logicalChannelNumber) {
                      Modal.warning({
                        title: this.context.translate(
                          "_FLEET_DASHBORD_SHOWGEO_REMOVEALERT_"
                        ),
                        content: this.context.translate(
                          "Live streaming is currently active on this channel, so playback of recorded videos is unavailable."
                        ),
                      });
                    } else {
                      this.handlePlaybackVideo(record);
                      this.setState({
                        activeRow: record.startTime,
                        showVideoWindow: true,
                      });
                    }
                  }}
                />
              </Tooltip>
            )}
            {/* <Tooltip title={"Download"} color="green">
              <DownloadOutlined
                style={{
                  fontSize: "20px",
                  paddingInlineStart: "7px",
                  cursor: "pointer",
                }}
              />
            </Tooltip> */}
          </div>
        ),
      },
    ];
  }

  handlePlaybackVideo = async (record) => {
    const data = {
      channelId: record.logicalChannelNumber,
      vehicleId: this.state.vehicleId,
      full_imei: record.terminalPhone,
      streamType: record.streamType,
      memoryType: record.memoryType,
      beginTime: record.startTime,
      endTime: record.endTime,
    };
    const videoData = await this.videoMonitoringStore.getPlaybackVideoData(
      data
    );

    this.setState({ playbackVideoUrl: videoData.url });
  };
  onSubmit = async (obj) => {
    const filteredData = this.state.treeData?.filter(
      (item) => item.vehicleId === this.state.vehicleId
    );

    var fromDate = dayjs(obj.fromDate).format("YYYY-MM-DD");
    var toDate = dayjs().format("YYYY-MM-DD");
    var fromTime = obj.fromTime;
    var toTime = dayjs().format("HH:mm:ss");
    var data = {
      channelId: obj.channelId,
      companyId: this.state.companyId,
      vehicleId: obj.vehicleId,
      starttime: fromDate + "  " + fromTime,
      endtime: toDate + "  " + toTime,
    };

    const resp = await this.videoMonitoringStore.getPlayBackVideoNew(data);

    const videoUrls = [
      resp?.url1,
      resp?.url2,
      resp?.url3,
      resp?.url4,
      resp?.url5,
      resp?.url6,
      resp?.url7,
      resp?.url8,
    ];
    await this.setState({ videoUrlArray: videoUrls });

    const playbackData = {
      companyId: this.state.companyId,
      // vehicleId: obj.vehicleId,
      channelId: obj.channelId,
      starttime: fromDate + "  " + fromTime,
      endtime: toDate + "  " + toTime,
      imei: filteredData[0]?.full_imei,
    };
    const MDVR = filteredData[0]?.MDVR;

    this.setState({ showVideo: MDVR });

    if (MDVR === "JT1078") {
      await this.videoMonitoringStore.getPlaybackData(playbackData);

      await this.setState({ showPlaybackTable: true });
    }
  };

  handleChange = async (v) => {
    await this.setState({ companyId: v.value });
    const data = await this.videoMonitoringStore.getDevices({
      companyId: v.value,
    });

    await this.setState({
      treeData: data,
      showPlaybackTable: false,
      iframeSrcs: [],
    });
    await this.setState({ update: 1 });
  };

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      const data = await this.videoMonitoringStore.getDevices({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.setState({ treeData: data, update: 1 });
    }
  }

  handleVideoChange = async (info) => {
    const childKey = info.node.vehicleId;

    const parent = await this.findParent(
      await this.videoMonitoringStore.deviceListArray,
      childKey
    );

    this.setState({ selectedData: parent });

    const host = parent?.cmsvssWebHost;
    const imei = parent?.full_imei;
    const UserName = parent?.cmsvssUserName;
    const Password = parent?.cmsvssPassword;
    const MDVR = parent?.MDVR;
    const channelId = info.node.channelId;
    const deviceId = parent?.deviceId;

    if (info.checked) {
      if (MDVR === "JT1078") {
        const data = {
          channelId: channelId,
          vehicleId: parent.vehicleId,
          full_imei: imei,
        };
        await this.videoMonitoringStore.bsjVideoMonitoring(data);

        var url =
          "https://media.trackeazy.com/test/multimedia#" +
          imei +
          "-" +
          channelId;

        this.setState({ channelId });
        this.setState((prevState) => ({
          iframeSrcs: [
            ...prevState.iframeSrcs,
            { key: info.node.key, src: url, title: info.node.title },
          ],
        }));
      } else if (MDVR === "CMS") {
        // Construct iframe source URL
        const iframeSrc = `${host}/808gps/open/player/video.html?lang=en&devIdno=${imei}&account=${UserName}&password=${Password}&channel=1&chns=${
          channelId - 1
        }`;

        this.setState((prevState) => ({
          iframeSrcs: [
            ...prevState.iframeSrcs,
            { key: info.node.key, src: iframeSrc, title: info.node.title },
          ],
        }));
      } else if (MDVR === "H-Protocol") {
        const mdvrdata = {
          vehicleId: parent.vehicleId,
          channelId: channelId,
          deviceId: deviceId,
          full_imei: imei,
        };

        await this.videoMonitoringStore.howenMDVRSocket(mdvrdata);

        var url =
          "https://media.trackeazy.com/howen/test/multimedia#live_" +
          imei +
          "_0" +
          channelId +
          "_00";

        this.setState({ channelId });
        this.setState((prevState) => ({
          iframeSrcs: [
            ...prevState.iframeSrcs,
            { key: info.node.key, src: url, title: info.node.title },
          ],
        }));
      } else {
        const port = parent?.cmsvssWebPort;
        const token = parent?.token;

        let iframeSrc = "";

        if (port != null) {
          iframeSrc = `${host}:${port}/vss/apiPage/RealVideo.html?token=${token}&deviceId=${imei}&chs=${channelId}&stream=0&wnum=0&buffer=0&panel=1`;
        } else {
          iframeSrc = `${host}/vss/apiPage/RealVideo.html?token=${token}&deviceId=${imei}&chs=${channelId}&stream=0&wnum=0&buffer=0&panel=1`;
        }
        console.log(iframeSrc);
        this.setState((prevState) => ({
          iframeSrcs: [
            ...prevState.iframeSrcs,
            { key: info.node.key, src: iframeSrc, title: info.node.title },
          ],
        }));
      }
    } else {
      this.setState((prevState) => ({
        iframeSrcs: prevState.iframeSrcs.filter(
          (iframe) => iframe.key !== info.node.key
        ),
      }));
    }
  };

  renderTreeNodes = (data) => {
    return data?.map((item) => {
      // const isParent = item?.children && item.children?.length > 0;

      return {
        ...item,
        title: (
          <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Image
              width={35}
              src={require("../assets/images/detailtracking/" + item.listImage)}
              style={{ marginInlineEnd: "6px" }}
            />
            {item.text || item.title || ""}
          </span>
        ),
      };
    });
  };

  openModal = (iframe) => {
    this.setState((prevState) => ({
      openVideoModal: true,
      selectedIframe: iframe,
      hiddenIframeKeys: [...prevState.hiddenIframeKeys, iframe.key],
    }));
  };

  closeModal = () => {
    this.setState((prevState) => ({
      openVideoModal: false,
      hiddenIframeKeys: prevState.hiddenIframeKeys.filter(
        (key) => key !== prevState.selectedIframe?.key
      ),
      selectedIframe: null,
    }));
  };

  findParent = async (tree, key) => {
    for (const node of tree) {
      if (node.vehicleId === key) {
        return node;
      }
      if (node.children) {
        const found = await this.findParent(node.children, key);
        if (found) return node;
      }
    }
    return null;

    // let ren = null;
    // Object.keys(tree).map((e, i) => {
    //   console.log(i, "i380");
    //   console.log(tree[i].vehicleId);
    //   if (tree[i].vehicleId === key) {
    //     //console.log(tree[i])
    //     ren = tree[i];

    //     console.log(ren, "ren");
    //   }
    // });
    // return ren;
  };

  render() {
    return (
      <div>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_VIDEO_MONITORING_")}
              </div>
            </Col>
          </Row>
        </div>

        <Row className="video-monitoring">
          <Col span={4} className="live-video-streaming-block">
            <div className="heading">
              {this.context.translate("_LIVE_VIDEO_STREAMING_")}
            </div>
            <CompanyAutocomplete
              onChange={(v) => this.handleChange(v)}
              style={{ padding: "10px", width: "100%" }}
            />

            <Tree
              checkable
              showIcon
              showLine={true}
              onCheck={(e, info) => {
                this.handleVideoChange(info);
                this.setState({ playbackVideoUrl: "", showVideoWindow: false });
              }}
              treeData={this.renderTreeNodes(
                this.videoMonitoringStore.deviceListArray
              )}
            />
          </Col>

          <Col span={16} style={{ height: "90vh", overflow: "auto" }}>
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  // overflow: "auto",
                  // height: "80vh",
                }}
              >
                {this.state.showVideo !== "JT1078" &&
                  this.state.selectedData?.MDVR !== "JT1078" &&
                  this.state.videoUrlArray &&
                  this.state.videoUrlArray.map((url, index) => (
                    <iframe
                      key={index}
                      style={{ padding: "20px", height: "262px" }}
                      width="50%"
                      height="50%"
                      src={url}
                      title={`Video ${index + 1}`}
                      allowFullScreen
                    ></iframe>
                  ))}
              </div>
            </>
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  margin: "12px",
                }}
              >
                {this.state.openCard &&
                  this.state.iframeSrcs
                    .filter(
                      (iframe) =>
                        !this.state.hiddenIframeKeys.includes(iframe.key)
                    )
                    .map((iframe) => (
                      <Card
                        key={iframe.key}
                        title={
                          <div
                            style={{
                              fontSize: 17,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            className="videoCardTitle"
                          >
                            {iframe.title}
                            <i
                              className="bi bi-arrows-fullscreen"
                              style={{
                                border: "none",
                                fontSize: "17px",
                                cursor: "pointer",
                              }}
                              onClick={() => this.openModal(iframe)}
                            ></i>
                          </div>
                        }
                        style={{ width: 300 }}
                      >
                        <iframe
                          key={iframe.key}
                          src={iframe.src}
                          width="100%"
                          height="200px"
                          frameBorder="0"
                          scrolling="no"
                          allow="fullscreen"
                          title={`Video Player ${iframe.key}`}
                        />
                      </Card>
                    ))}
              </div>
            </>
            <Row span={24}>
              <Col span={12}>
                {this.state.showPlaybackTable && (
                  <>
                    <div
                      style={{
                        paddingInlineStart: "20px",
                        // backgroundColor: "#e0e0e0",
                      }}
                    >
                      Playback Video Data
                    </div>
                    <div style={{ paddingInlineStart: "20px" }}>
                      <Table
                        dataSource={this.videoMonitoringStore.playbackData}
                        columns={this.columns}
                        pagination={false}
                        bordered
                        small
                        scroll={{
                          y: 550,
                        }}
                      />
                    </div>
                  </>
                )}
              </Col>
              <Col span={1} />
              <Col span={11}>
                {this.state.showVideoWindow && this.state.playbackVideoUrl && (
                  <Card style={{ width: 400 }}>
                    <iframe
                      key={1}
                      src={this.state.playbackVideoUrl}
                      style={{ width: "100%", aspectRatio: "16/9" }}
                      // padding="10px"
                      scrolling="no"
                      allow="fullscreen"
                      title="Playback Video"
                      aria-label="Video Player"
                    />
                  </Card>
                )}
              </Col>
            </Row>
          </Col>

          <Col span={4} className="playback-options-block">
            <div className="heading">
              {this.context.translate("_PLAY_BACK_OPTION_")}
            </div>

            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              onFinish={this.onSubmit}
              style={{ padding: "15px" }}
              className="formClass"
            >
              <Form.Item
                name="vehicleId"
                label={this.context.translate("_VEHICLES_")}
                rules={[{ required: true }]}
              >
                <Select
                  onSelect={(value) => {
                    this.setState({ vehicleId: value });
                  }}
                  options={this.videoMonitoringStore.deviceListArray}
                  fieldNames={{ value: "vehicleId", label: "text" }}
                ></Select>
              </Form.Item>
              <Form.Item
                name="channelId"
                label={this.context.translate("_CHANNEL_")}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ height: "44px !important" }}
                  placeholder={this.context.translate("_SELECT_CHANNEL_")}
                  options={[
                    { label: "Channel1", value: 1 },
                    { label: "Channel2", value: 2 },
                    { label: "Channel3", value: 3 },
                    { label: "Channel4", value: 4 },
                    { label: "Channel5", value: 5 },
                    { label: "Channel6", value: 6 },
                    { label: "Channel7", value: 7 },
                    { label: "Channel8", value: 8 },
                  ]}
                  className="search-field"
                  mode="multiple"
                  maxTagCount={"responsive"}
                />
              </Form.Item>

              <Form.Item
                name="fromDate"
                className="blur-input"
                label={this.context.translate("_FROM_DATE_")}
                initialValue={dayjs()}
              >
                <DatePicker style={{ width: "100%" }} format={"YYYY-MM-DD"} />
              </Form.Item>

              <Form.Item
                name="fromTime"
                label={this.context.translate(
                  "_FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_"
                )}
                className="blur-input"
                rules={[{ required: true }]}
                initialValue={"00:00:00"}
              >
                <Select
                  onChange={(val) =>
                    this.formRef.current.setFieldsValue({ fromTime: val })
                  }
                >
                  {timeArray.map((data) => {
                    return (
                      <Select.Option
                        key={data.formattedTime}
                        value={data.formattedTime}
                      >
                        {format(data.time, "h:mm aa")}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="toDate"
                className="blur-input"
                label={this.context.translate("_TO_DATE_")}
              >
                <DatePicker
                  defaultValue={dayjs()}
                  style={{ width: "100%" }}
                  format={"YYYY-MM-DD"}
                />
              </Form.Item>

              <Form.Item
                name="toTime"
                label={this.context.translate(
                  "_FLEET_RULESELECTORDETAIL_RULE4_TOTIME_"
                )}
                className="blur-input"
                rules={[{ required: true }]}
                initialValue={dayjs().format("hh:mm A ")}
              >
                <Select
                  onChange={(val) =>
                    this.formRef.current.setFieldsValue({ toTime: val })
                  }
                >
                  {timeArray.map((data) => {
                    return (
                      <Select.Option
                        key={data.formattedTime}
                        value={data.formattedTime}
                      >
                        {format(data.time, "h:mm aa")}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item label=" ">
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ width: "100%" }}
                >
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Modal
          open={this.state.openVideoModal}
          onCancel={this.closeModal}
          footer={null}
          width={800}
          title={this.state.selectedIframe?.title}
        >
          {this.state.selectedIframe && (
            <iframe
              src={this.state.selectedIframe.src}
              width="100%"
              height="400px"
              allow="fullscreen"
              title={`Video Player ${this.state.selectedIframe.key}`}
            />
          )}
        </Modal>
      </div>
    );
  }
}
VideoMonitoring.contextType = StoreContext;
export default observer(withRouter(VideoMonitoring));
