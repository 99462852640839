import { CloseOutlined } from "@ant-design/icons";
import { Card, DatePicker, Image, Tabs, Spin, Select, Button } from "antd";
import L from "leaflet";
import { Icon } from "leaflet";

import React, { Component } from "react";

import dayjs from "dayjs";

import VehicleOtherInfoStore from "../store/VehicleOtherInfoStore";
import Draggable from "react-draggable";
import ShareModalWindow from "./ShareModalWindow";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import FuelGraphLivetracking from "./FuelGraphLivetracking";

import { Tooltip } from "recharts";
import { update } from "lodash";
import FuelGraphStore from "../store/FuelGraphStore";
import highlightWeekends from "react-multi-date-picker/plugins/highlight_weekends";

const { RangePicker } = DatePicker;

class VehicleOtherInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: 0,
      showFullSummary: false,
      // vehicleSummaryInfo:[],
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD 23:59:59"),
      trackingDataObj: [],
      shareModalVisible: false,
      selectedIndex: null,
      selectedData: [],
      fuelRefillData: [],
      graphData: [],
      interval: 120,
    };
    this.polyline2 = null;
    this.marker2 = null;

    this.vehicleOtherInfoStore = new VehicleOtherInfoStore();
    this.fuelGraphStore = new FuelGraphStore();

    this.calculateDays = this.calculateDays.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.removeMarkerPoints = [];

    this.rangePresets = {
      items: [
        {
          label: "Today",
          value: [dayjs(), dayjs().endOf("day")],
          key: 1,
        },
        {
          key: "yesterday",
          label: "Yesterday",
          value: [
            dayjs().subtract(1, "day").startOf("day"),
            dayjs().subtract(1, "day").endOf("day"),
          ],
        },
        {
          key: "Last 7 Days",
          label: "Last 7 Days",
          value: [dayjs().add(-6, "d"), dayjs()],
        },

        {
          label: "Last 30 Days",
          value: [dayjs().add(-29, "d"), dayjs()],
          key: 4,
        },
        {
          label: "This Month",
          value: [dayjs().startOf("month"), dayjs().endOf("month")],
          key: 5,
        },
        {
          label: "Last Month",
          value: [
            dayjs().subtract(1, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
          ],
          key: 6,
        },
      ],
    };
  }
  async componentDidMount() {
    await this.getFuelRefillInfo();
  }
  componentWillUnmount() {
    this.clearMap();
  }

  getFuelRefillInfo = async () => {
    const data = await this.vehicleOtherInfoStore.getFuelRefillData({
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      companyId: this.props.info.companyId,
      vehicleId: [this.props.info.vehicleId],
    });

    await this.setState({
      fuelRefillData: data.fuelrefill,
      graphData: data.graphdata,
      update: 1,
    });
  };
  async getVehicleHistInfo(record) {
    this.vehicleOtherInfoStore.vehiclesHistData = [];
    this.vehicleOtherInfoStore.vehicleSummaryData = [];
    this.vehicleOtherInfoStore.vehicleTripData = [];
    this.vehicleOtherInfoStore.timeWisePolylineArray = [];
    this.setState({ update: 1 });
    await this.vehicleOtherInfoStore.getVehicleHistoryData({
      vehicleId: this.props.info.vehicleId,
      companyId: this.props.info.companyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });

    await this.setState({ update: 1 });
  }

  async getVehicleOffilneInfo(resetOffset) {
    await this.vehicleOtherInfoStore.setOfflineData({
      vehicleId: this.props.info.vehicleId,
      companyId: this.props.info.companyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      resetOffset: resetOffset,
    });

    await this.setState({ update: 1 });
  }

  async getVehicleAlertInfo(record) {
    await this.vehicleOtherInfoStore.getVehicleRuleTriggerData({
      vehicleId: this.props.info.vehicleId,
      companyId: this.props.info.companyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });

    await this.setState({ update: 1 });
  }

  convertToFormattedTime = (time) => {
    if (time) {
      const [hours, minutes] = time?.split(":");

      const formattedTime = `${hours}h ${minutes}min`;
      return formattedTime;
    }
  };

  convertToFormattedDate = (dateString) => {
    const dateObject = new Date(dateString);
    const dateStringData = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return dateStringData;
  };

  calculateDays = () => {
    var lastReportingTime = this.props.info.updatedate;
    var serverCurrentTime = this.props.info.currtime;

    var diff = new Date(serverCurrentTime) - new Date(lastReportingTime);

    diff /= 60000;

    let time;
    if (diff >= 90) {
      time = this.getSectoTime(this.props.info?.stopSince);
    } else if (this.props.info.acc == 1 && this.props.info.speed == 0) {
      time = this.getSectoTime(this.props.info?.idleSince);
    } else {
      if (this.props.info.acc == 0 || this.props.info.acc == null) {
        time = this.getSectoTime(this.props.info?.stopSince);
      } else {
        if (this.props.info.acc == 1 && this.props.info.speed > 0) {
          time = this.getSectoTime(this.props.info?.runSince);
        }
      }
    }
    return time;
  };

  getSectoTime = (c) => {
    var g, f, b, e;
    e = c;
    b = Math.floor(e / 60);
    e = e % 60;
    f = Math.floor(b / 60);
    b = b % 60;
    g = Math.floor(f / 24);
    f = f % 24;
    var a = (g < 10 ? "0" + g : g) + " days,";
    a += f < 10 ? "0" + f : f;
    a += ":" + (b < 10 ? "0" + b : b);
    a += ":" + (e < 10 ? "0" + e : e);
    return a;
  };
  getColor = (status) => {
    let statusColor;
    switch (status) {
      case "No Data":
        statusColor = "text-secondary";
        break;
      case "Running":
        statusColor = "text-success";
        break;
      case "Stopped":
        statusColor = "text-danger";
        break;
      case "Idle":
        statusColor = "text-warning";
        break;
    }
    return "bi bi-circle-fill " + statusColor;
  };
  converteToDaysFormat = (val) => {
    const stopDurationSeconds = val || 0;
    const days = Math.floor(stopDurationSeconds / (24 * 60 * 60));
    const hours = Math.floor(
      (stopDurationSeconds % (24 * 60 * 60)) / (60 * 60)
    );
    const minutes = Math.floor((stopDurationSeconds % (60 * 60)) / 60);
    const seconds = stopDurationSeconds % 60;

    const formattedStopDuration = `${days
      .toString()
      .padStart(2, "0")} days, ${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedStopDuration;
  };

  onRangeChange = async (dates, dateStrings) => {
    const startDate = new Date(dates[1]);
    const endDate = new Date(dates[0]);
    const diffrence = Math.round((startDate - endDate) / (1000 * 60 * 60 * 24));

    if (diffrence === 0 || diffrence === 1) {
      this.setState({ showFullSummary: false });
    } else {
      this.setState({ showFullSummary: true });
    }

    if (dates) {
      await this.setState({
        fromDate: dayjs(dateStrings[0]).format("YYYY-MM-DD 00:00:00"),
        toDate: dayjs(dateStrings[1]).format("YYYY-MM-DD 23:59:59"),
      });

      await this.getFuelRefillInfo();

      this.setState({}, async () => {
        console.log("Updated fuelRefillData:", this.state.fuelRefillData);
        await this.showFuelIcons();
      });

      const removeData = this.state.fuelRefillData.map((val) => {
        if (val.latitude && val.longitude) {
          return [Number(val.latitude), Number(val.longitude)];
        }
      });

      if (this.props.mapRef.current && removeData.length > 0) {
        this.props.mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            const markerLatLng = layer.getLatLng();

            const removeFuelmarker = removeData.some((point) =>
              markerLatLng.equals(L.latLng(point))
            );
            if (removeFuelmarker) {
              this.props.mapRef.current.removeLayer(layer);
            }
          }
        });
      }

      await this.getVehicleHistInfo();
      await this.clearMap();

      await this.getVehicleOffilneInfo(0);
      await this.showPolyline();
      await this.setState({ update: 1 });
    }
  };

  clearMap = async () => {
    let mapRef = this.props.This.mapRef;
    if (this.props.mapRef.current && this.removeMarkerPoints.length > 0) {
      this.props.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          const markerLatLng = layer.getLatLng();
          // Check if the marker's coordinates are in the removeMarkerPoints array
          const isMarkerInRemovePoints = this.removeMarkerPoints.some((point) =>
            markerLatLng.equals(L.latLng(point))
          );
          if (isMarkerInRemovePoints) {
            // If the marker's coordinates are in the removeMarkerPoints array, remove it from the map
            this.props.mapRef.current.removeLayer(layer);
          }
        }
      });
    }
    if (this.props.mapRef) {
      await this.props.mapRef?.current?.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          this.props.mapRef.current.removeLayer(layer);
        }
      });
    }
    this.vehicleOtherInfoStore.offlineData = [];
    this.vehicleOtherInfoStore.offlineDataNewObj = [];
    this.vehicleOtherInfoStore.idleAndStopPointsArray = [];
    this.vehicleOtherInfoStore.totalCount = null;
    this.vehicleOtherInfoStore.total = 0;
    this.vehicleOtherInfoStore.pgcount = 1;
    this.vehicleOtherInfoStore.offset = 0;

    // await this.clearFuelIcons();
  };

  clearFuelIcons = () => {
    const data = this.state.fuelRefillData.map((val) => {
      if (val.latitude && val.longitude) {
        return [Number(val.latitude), Number(val.longitude)];
      }
    });

    if (this.props.mapRef.current && data.length > 0) {
      this.props.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          const markerLatLng = layer.getLatLng();

          const removeFuelmarker = data.some((point) =>
            markerLatLng.equals(L.latLng(point))
          );
          if (removeFuelmarker) {
            this.props.mapRef.current.removeLayer(layer);
          }
        }
      });
    }
  };

  showPolyline = async () => {
    const polyline = await L.polyline(
      this.vehicleOtherInfoStore.offlineDataNewObj.length > 0
        ? [...this.vehicleOtherInfoStore.offlineDataNewObj]
        : [],
      {
        color: "grey",
        smoothFactor: 5,
        weight: 4,
      },
      15
    ).addTo(this.props.This.mapRef.current);
    const bounds = polyline.getBounds();

    if (bounds.isValid()) {
      await this.props.This.mapRef.current.fitBounds(bounds, {
        maxZoom: 20,
      });
    }
    polyline.arrowheads({
      size: "6px",
      color: "blue", // Set arrow color to blue
    });
  };

  drawIdleAndStopIcon = (vehicleObj, activity) => {
    const activityImage = require(`../assets/images/BaloonIcons/${activity}-north.png`);
    const marker = L.marker([vehicleObj.latitude, vehicleObj.longitude], {
      icon: L.divIcon({
        className: "custom-marker",
        iconSize: [25, 25],
        html: `
          <img src=${activityImage} alt="Icon" style=  "width: 100%; height: 100%;">`,
      }),
    })
      .bindTooltip(
        `<div style='background:#00ff00;border:solid 1px #000;text-align:left;padding:10px;'>
  <b>Vehicle No:</b> ${vehicleObj.vehicleNo} <br/>
  <b>Idle From:</b> ${vehicleObj.fromdate} <br/>
  <b>Idle To:</b> ${vehicleObj.todate} <br/>
  <b>Duration:</b> ${vehicleObj.elapsedTime} <br/>
 
</div>`,
        {
          maxWidth: 300,
          direction: "top",
          // className: "my-labels"
        }
      )

      .addTo(this.props.This.mapRef.current);
  };

  showTrackingPolyline = async () => {
    if (this.polyline2) {
      this.props.This.mapRef.current.removeLayer(this.polyline2);
    }

    let dta = this.vehicleOtherInfoStore.idleAndStopPointsArray?.map(
      (val) => val.vehicleObj
    );
    this.removeMarkerPoints = dta.map((val) => [
      Number(val.latitude),
      Number(val.longitude),
    ]);

    const showMarkers = this.vehicleOtherInfoStore.idleAndStopPointsArray.map(
      async (val) => {
        await this.drawIdleAndStopIcon(val.vehicleObj, val.activity);
      }
    );
    this.polyline2 = await L.polyline(
      this.vehicleOtherInfoStore.timeWisePolylineArray.length > 0
        ? [...this.vehicleOtherInfoStore.timeWisePolylineArray]
        : [],
      {
        color: "#37d5ed",
        smoothFactor: 5,
        weight: 6,
      },
      15
    ).addTo(this.props.This.mapRef.current);
    const bounds2 = this.polyline2.getBounds();

    await this.props.This.mapRef.current.fitBounds(bounds2, {
      maxZoom: 20,
    });
  };

  getGPSStatus(status) {
    if (status === 1) {
      return <>Running</>;
    }
    if (status === 0) {
      return <>Stop</>;
    }
    if (status === undefined || status === null) {
      return <>No Data</>;
    }
  }

  copyTextToClipboard = () => {
    const textToCopy = this.props.info.formatted_address;

    // Create a temporary input element to copy the text
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();

    document.execCommand("copy");

    document.body.removeChild(tempInput);
  };

  showFuelIcons = () => {
    this.state.fuelRefillData.forEach((value, index) => {
      if (value.latitude && value.longitude) {
        var locationIcon = new Icon({
          iconUrl: require("../assets/images/map-car-icon-green.png"),
          iconSize: [30, 35], // size of the icon
        });

        L.marker([value?.latitude, value?.longitude])
          .setIcon(locationIcon)
          .addTo(this.props.This.mapRef.current)
          .bindTooltip(
            `<div style='background:#00ff00;border:solid 1px #000;text-align:left;padding:10px;'>
        <b>Vehicle No :</b> ${value.vehicleNo ? value.vehicleNo : "-"} <br/>
        <b>Location :</b> ${value.location ? value.location.replace(/(.{30})/g, "$1<br/>") : "-"
            } <br/>
         <b>Refill Fuel :</b> ${value.refillfuel ? value.refillfuel : "-"
            } L<br/>
        <b>Before Level :</b> ${value.beforLevel ? value.beforLevel : "-"
            } L<br/>
         <b>Refill Fuel Level :</b> ${value.refillfuellevel ? value.refillfuellevel : "-"
            } L
      </div>`,
            { direction: "right" }
          );
        this.props.This.mapRef.current.setView(
          [Number(value?.latitude), Number(value?.longitude)],
          16
        );
      }
    });

    this.setState({ update: 1 });
  };

  render() {
    return (
      <>
        <Draggable>
          <Card
            className="vehicle-info-panel"
            title={
              <div>
                <span>{this.props.info.vehicleNo}</span>
                <span>
                  <CloseOutlined
                    onClick={async () => {
                      await this.props.This.setState({
                        vehicleOtherInfo: false,
                      });
                      await this.clearFuelIcons();
                      await this.clearMap();
                    }}
                  />
                </span>
              </div>
            }
            style={{
              zIndex: 1000,
              position: "absolute",
              top: "1%",
              height: "96vh",
              maxHeight: "96vh",
              padding: "10px",
              // right: "69%",
              width: "25%",
            }}
          >
            <div>
              <Tabs
                className="activityTab"
                defaultActiveKey="1"
                items={[
                  {
                    label: this.context.translate("_INFORMATION_"),
                    key: "1",
                    children: (
                      <>
                        <div class="vehicleInformation">
                          <div class="vehiclePic">
                            <Image
                              src={require("../assets/images/vehicle-type-car.png")}
                            />
                          </div>
                          <div class="vehicleDetails">
                            <p>
                              <span>
                                {" "}
                                <i class="bi bi-person"></i>
                              </span>

                              <span>{this.props.daySummary?.driverName}</span>
                            </p>
                            <p>
                              <i class="bi bi-telephone"></i>

                              {this.props.daySummary?.mobileno}
                            </p>
                            <p>
                              <i class="bi bi-broadcast-pin"></i>{" "}
                              {this.getGPSStatus(this.props.info.GPS)}
                            </p>
                          </div>
                        </div>

                        <div className="vehicle-info-address">
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <h6>Location</h6>
                            <Tooltip title="Copy Location" color="green">
                              <span style={{ marginLeft: "auto" }}>
                                <i
                                  class="bi bi-files"
                                  onClick={this.copyTextToClipboard}
                                ></i>
                              </span>
                            </Tooltip>
                          </p>
                          <p>
                            <span>
                              <i class="bi bi-geo-alt-fill"></i>
                            </span>

                            {this.props.info.formatted_address}
                          </p>
                        </div>

                        <div className="lat-long-block">
                          <p>
                            <span>
                              <i class="bi bi-globe2"></i>
                            </span>
                            {this.props.info.latitude
                              ? parseFloat(this.props.info.latitude).toFixed(7)
                              : ""}
                            ,
                            {this.props.info.longitude
                              ? parseFloat(this.props.info.longitude).toFixed(7)
                              : ""}
                          </p>
                        </div>

                        <ul className="vehicle-info-list">
                          <li>
                            <span>{this.context.translate("_VEHICLES_")}:</span>
                            <span>{this.props.info.vehicleNo}</span>
                          </li>

                          <li>
                            <span>
                              {this.context.translate("_CURRENT_STATUS_")}:
                            </span>
                            <span>
                              <i
                                class={this.getColor(
                                  this.props.info.currentStatus
                                )}
                              ></i>{" "}
                              &nbsp;
                              {this.props.info.currentStatus}
                              &nbsp;
                              {this.props.info.currentStatus === "Running"
                                ? "(" +
                                Math.floor(this.props.info.speed) +
                                " Km/h)"
                                : ""}
                            </span>
                          </li>

                          <li>
                            <span>
                              {" "}
                              {this.context.translate("_STATE_DURATION_")}:
                            </span>
                            <span>{this.calculateDays()}</span>
                          </li>

                          <li>
                            <span>
                              {this.context.translate("_LAST_SIGNAL_SYNC_")}:
                            </span>
                            <span> {this.props.info.updatedate}</span>
                          </li>

                          <li>
                            <span>{this.context.translate("_FUEL_")}:</span>
                            <span>{this.props.info.fuelHeight1} Liters</span>
                          </li>

                          <li>
                            <span>
                              {this.context.translate(
                                "_FLEET_REPORTS_EVENTREPOR_ODOMETER_"
                              )}
                              :
                            </span>
                            <span>{this.props.info.odometer}</span>
                          </li>
                        </ul>

                        <div className="share-box">
                          <i
                            class="bi bi-share"
                            onClick={() => {
                              let data = [];
                              data.push(
                                `${Number(this.props.info.latitude)},${Number(
                                  this.props.info.longitude
                                )}`
                              );

                              this.setState({ shareModalVisible: data });
                            }}
                          ></i>

                          <i
                            class="bi bi-heptagon"
                            onClick={() =>
                              this.props?.This.setState({
                                drawGeofence: true,
                                editing: true,
                                liveTrackingMainModal: false,
                              })
                            }
                          ></i>
                          <i
                            class="bi bi-fuel-pump"
                            onClick={async () => {
                              await this.props.This.setState({
                                fuelStationCenterAreaLatLong: [
                                  this.props?.info?.latitude,
                                  this.props?.info?.longitude,
                                ],
                              });
                              await this.props?.This.SearchMenuItemClick(
                                "fuelstation"
                              );
                            }}
                          ></i>
                        </div>
                      </>
                    ),
                  },
                  {
                    label: (
                      <div
                        onClick={async () => {
                          this.vehicleOtherInfoStore.timeWisePolylineArray = [];

                          this.vehicleOtherInfoStore.offlineDataNewObj = [];
                          this.vehicleOtherInfoStore.idleAndStopPointsArray =
                            [];

                          await this.clearFuelIcons();

                          this.props.This.setIntervals();
                          await this.getVehicleHistInfo();

                          await this.getVehicleOffilneInfo(0);
                          await this.showPolyline();
                        }}
                      >
                        {this.context.translate("_HISTORY_")}
                      </div>
                    ),
                    key: "2",
                    children: (
                      <div className="hist-datepicker">
                        <RangePicker
                          allowClear={false}
                          format="YYYY-MM-DD"
                          size="small"
                          showTime={false}
                          presets={this.rangePresets.items}
                          onChange={this.onRangeChange}
                          style={{ width: "100%" }}
                          defaultValue={[dayjs().startOf("day"), dayjs()]}
                        // defaultValue={[this.state.fromDate,]}
                        />
                        <Spin spinning={this.vehicleOtherInfoStore.loading}>
                          <div className="summary-info-scroll">
                            {this.state.showFullSummary && (
                              <div className="summary-info-block">
                                {" "}
                                <div className="title">
                                  {this.context.translate("_FULL_SUMMARY_")}
                                </div>
                                <div className="summary-content-block">
                                  <p>
                                    <span className="loc-title">
                                      {this.context.translate(
                                        "_START_LOCATION_"
                                      )}
                                      :
                                    </span>
                                    <small>
                                      {
                                        this.vehicleOtherInfoStore
                                          .vehiclesHistData.startLocation
                                      }
                                    </small>
                                  </p>
                                  <p>
                                    <span className="loc-title">
                                      {" "}
                                      {this.context.translate(
                                        "_FINISH_LOCATION_"
                                      )}
                                      :
                                    </span>
                                    <small>
                                      {
                                        this.vehicleOtherInfoStore
                                          .vehiclesHistData.stopLocation
                                      }
                                    </small>
                                  </p>
                                  <p>
                                    <span>
                                      {this.context.translate("_WORK_TIME_")}:
                                    </span>
                                    <small>
                                      {this.converteToDaysFormat(
                                        this.vehicleOtherInfoStore
                                          ?.vehiclesHistData?.travelduration
                                      )}
                                    </small>
                                  </p>
                                  <p>
                                    <span>
                                      {this.context.translate(
                                        "_FLEET_STOP_TIME_"
                                      )}
                                      :
                                    </span>
                                    <small>
                                      {this.converteToDaysFormat(
                                        this.vehicleOtherInfoStore
                                          ?.vehiclesHistData?.stopduration
                                      )}
                                    </small>
                                  </p>
                                  <p>
                                    <span>
                                      {this.context.translate("_MILEAGE_")}:
                                    </span>
                                    <small>
                                      {this.vehicleOtherInfoStore?.vehiclesHistData?.distanceTravel?.toFixed(
                                        2
                                      )}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            )}
                            <div className="summary-info-block">
                              {this.vehicleOtherInfoStore?.vehicleSummaryData?.map(
                                (val) => (
                                  <>
                                    <div
                                      onClick={async () => {
                                        // await this.clearMap();
                                        this.vehicleOtherInfoStore.histTrackingClickPolylineObj =
                                          [];
                                        this.vehicleOtherInfoStore.timeWisePolylineArray =
                                          [];
                                        this.vehicleOtherInfoStore.idleAndStopPointsArray =
                                          [];
                                        if (this.polyline2) {
                                          this.props.This.mapRef.current.removeLayer(
                                            this.polyline2
                                          );
                                        }
                                        await this.vehicleOtherInfoStore.getHistoryTrackingData(
                                          {
                                            vehicleId:
                                              this.props.info.vehicleId,
                                            companyId:
                                              this.props.info.companyId,
                                            fromDate: val.startTime,
                                            toDate: val.stopTime,
                                          }
                                        );

                                        await this.showPolyline();
                                        await this.setState({ update: 1 });
                                      }}
                                    >
                                      <div className="title">
                                        {this.context.translate("_SUMMARY_")}
                                      </div>
                                      <div className="summary-content-block">
                                        <p>
                                          <span className="loc-title">
                                            {this.context.translate(
                                              "_START_LOCATION_"
                                            )}
                                            :
                                          </span>
                                          <small>{val.startLocation}</small>
                                        </p>
                                        <p>
                                          <span className="loc-title">
                                            {this.context.translate(
                                              "_FINISH_LOCATION_"
                                            )}
                                            :
                                          </span>
                                          <small>{val.stopLocation}</small>
                                        </p>

                                        <p>
                                          <span>
                                            {this.context.translate(
                                              "_WORK_TIME_"
                                            )}
                                            :
                                          </span>
                                          <small>
                                            {val.travelduration >= 3
                                              ? val.travelduration.toFixed(2)
                                              : val?.travelduration}
                                          </small>
                                        </p>

                                        <p>
                                          <span>
                                            {this.context.translate(
                                              "_FLEET_STOP_TIME_"
                                            )}
                                            :
                                          </span>
                                          <small>{val?.stopduration}</small>
                                        </p>
                                        <p>
                                          <span>
                                            {this.context.translate(
                                              "_MILEAGE_"
                                            )}
                                            :
                                          </span>
                                          <small>{val.distanceTravel}</small>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="summary-info-block">
                                      <div className="title">{val?.sTime}</div>
                                    </div>

                                    {val?.tripData &&
                                      val?.tripData?.map((trip, ind) => (
                                        <div>
                                          <div className="summary-duration-block">
                                            {ind === 0 ? (
                                              <div
                                                onClick={async () => {
                                                  // await this.clearMap();
                                                  this.vehicleOtherInfoStore.timeWisePolylineArray =
                                                    [];
                                                  const resp =
                                                    await this.vehicleOtherInfoStore.getHistoryTrackingData2(
                                                      {
                                                        vehicleId:
                                                          this.props.info
                                                            .vehicleId,
                                                        companyId:
                                                          this.props.info
                                                            .companyId,
                                                        fromDate:
                                                          trip.startTime,
                                                        toDate: trip.stopTime,
                                                      }
                                                    );

                                                  resp > 0 &&
                                                    (await this.showTrackingPolyline());
                                                  // await this.showTrackingPolyline();
                                                  await this.setState({
                                                    update: 1,
                                                  });
                                                }}
                                              >
                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-stop">
                                                      00:00
                                                      <br />
                                                      {this.context.translate(
                                                        "_STOP_"
                                                      )}
                                                    </b>
                                                    <span>
                                                      {this.context.translate(
                                                        "_STOP_DURATION_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.stopSince
                                                    )}
                                                  </small>
                                                </div>

                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-start">
                                                      {trip.startTime
                                                        .split(" ")[1]
                                                        .slice(0, 5)}
                                                      <br />
                                                      {this.context.translate(
                                                        "_START_"
                                                      )}
                                                    </b>

                                                    <span>
                                                      {this.context.translate(
                                                        "_DRIVING_DURATION_"
                                                      )}
                                                      <br />
                                                      {this.context.translate(
                                                        "_MILEAGE_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.startTill
                                                    )}
                                                    <br />
                                                    {trip.distanceTravelled}Km
                                                  </small>
                                                </div>

                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-stop">
                                                      {trip.stopTime
                                                        ?.split(" ")[1]
                                                        .slice(0, 5)}
                                                      <br />
                                                      {this.context.translate(
                                                        "_STOP_"
                                                      )}
                                                    </b>
                                                    <span>
                                                      {this.context.translate(
                                                        "_STOP_DURATION_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.stopTill
                                                    )}
                                                  </small>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                onClick={async () => {
                                                  this.vehicleOtherInfoStore.timeWisePolylineArray =
                                                    [];
                                                  await this.props.This.setState(
                                                    {
                                                      update: 1,
                                                    }
                                                  );
                                                  // await this.showTrackingPolyline();
                                                  const resp =
                                                    await this.vehicleOtherInfoStore.getHistoryTrackingData2(
                                                      {
                                                        vehicleId:
                                                          this.props.info
                                                            .vehicleId,
                                                        companyId:
                                                          this.props.info
                                                            .companyId,
                                                        fromDate:
                                                          trip.startTime,
                                                        toDate: trip.stopTime,
                                                      }
                                                    );

                                                  resp > 0
                                                    ? await this.showTrackingPolyline()
                                                    : (this.vehicleOtherInfoStore.timeWisePolylineArray =
                                                      []);
                                                }}
                                              >
                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-start">
                                                      {trip.startTime
                                                        ?.split(" ")[1]
                                                        .slice(0, 5)}
                                                      <br />
                                                      {this.context.translate(
                                                        "_START_"
                                                      )}
                                                    </b>
                                                    <span>
                                                      {this.context.translate(
                                                        "_DRIVING_DURATION_"
                                                      )}
                                                      <br />
                                                      {this.context.translate(
                                                        "_MILEAGE_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.startTill
                                                    )}
                                                    <br />
                                                    {trip?.distanceTravelled}Km
                                                  </small>
                                                </div>

                                                <div className="duration-row">
                                                  <p>
                                                    <b className="status-stop">
                                                      {trip.stopTime
                                                        ?.split(" ")[1]
                                                        .slice(0, 5)}
                                                      <br />
                                                      {this.context.translate(
                                                        "_STOP_"
                                                      )}
                                                    </b>
                                                    <span>
                                                      {this.context.translate(
                                                        "_STOP_DURATION_"
                                                      )}
                                                    </span>
                                                  </p>

                                                  <small>
                                                    {this.convertToFormattedTime(
                                                      trip.stopTill
                                                    )}
                                                  </small>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        </Spin>
                      </div>
                    ),
                  },
                  {
                    label: (
                      <div
                        onClick={async () => {
                          await this.getVehicleAlertInfo();
                        }}
                      >
                        {this.context.translate("_ALERTS_")}
                      </div>
                    ),
                    key: "3",
                    children: (
                      <>
                        {this.vehicleOtherInfoStore.ruleCount == 0 ? (
                          <div className="no-data-block">
                            {this.context.translate("_NO_DATA_AVAILABLE_")}
                          </div>
                        ) : (
                          <div className="summary-info-scroll">
                            {this.vehicleOtherInfoStore.vehiclesTriggerData.map(
                              (val) => (
                                <>
                                  <div className="summary-info-block">
                                    <div className="summary-content-block">
                                      <p>
                                        <span className="loc-title">
                                          {this.context.translate(
                                            "_EVENT_NAME_"
                                          )}
                                          :
                                        </span>
                                        <small>{val.ruleselector}</small>
                                      </p>
                                      <p>
                                        <span className="loc-title">
                                          {this.context.translate("_DATE_")}:
                                        </span>
                                        <small>{val.Time}</small>
                                      </p>

                                      <p>
                                        <span>
                                          {this.context.translate("_LOCATION_")}
                                          :
                                        </span>
                                        <small>{val.Address}</small>
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                        )}{" "}
                      </>
                    ),
                  },
                  {
                    label: (
                      <div
                        onClick={() => this.setState({ selectedIndex: null })}
                      >
                        {" "}
                        {this.context.translate("_ACTIVITY_")}
                      </div>
                    ),
                    key: "4",
                    children: (
                      <div className="activityTab">
                        <div className="white-row total-activity">
                          <h3>{this.context.translate("_TODAY_ACTIVITY_")}</h3>
                          <p className="kms">
                            <i class="bi bi-geo"></i>{" "}
                            {this.props.daySummary?.distanceTravel} Km
                            <i class="bi bi-car-front"></i>
                          </p>

                          <p>
                            <span>{this.context.translate("_RUNNING_")}</span>
                            <span>{this.props.daySummary?.travelduration}</span>
                            <span>Hrs</span>
                          </p>
                          <p>
                            <span>{this.context.translate("_IDLE_")} </span>
                            <span>{this.props.daySummary?.idleduration} </span>
                            <span> Hrs</span>
                          </p>
                          <p>
                            <span> {this.context.translate("_STOP_")} </span>
                            <span>{this.props.daySummary?.stopduration} </span>
                            <span> Hrs</span>
                          </p>
                          <p>
                            <span>
                              {this.context.translate(
                                "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                              )}{" "}
                            </span>
                            <span>
                              {" "}
                              {this.props.daySummary?.exidleduration}{" "}
                            </span>
                            <span> Hrs</span>
                          </p>
                        </div>

                        <div className="white-row driver-performance">
                          <h3>
                            {this.context.translate("_DRIVER_PERFORMANCE_")} (
                            {new Date().toLocaleDateString()})
                          </h3>

                          <p className="performance-status harsh">
                            {this.context.translate("_HARSH_BRACKING_")}{" "}
                            <span>
                              {this.props.driverPerformanceObj?.harshbreak}{" "}
                            </span>
                          </p>
                          <p className="performance-status exidle">
                            {this.context.translate("_OVER_SPEED_")}{" "}
                            <span>
                              {this.props.driverPerformanceObj?.overspeedCount}
                            </span>
                          </p>
                          <p className="performance-status exidle">
                            {this.context.translate("_EXIDLE_")}{" "}
                            <span>
                              {this.props.driverPerformanceObj?.exIdleCount}
                            </span>
                          </p>
                          <p className="performance-status harsh-acceleration">
                            {this.context.translate("_HARSH_ACCELERATION_")}{" "}
                            <span>
                              {this.props.driverPerformanceObj?.harshAcc}
                            </span>
                          </p>
                          <p className="performance-status accident-count">
                            {this.context.translate("_ACCIDENT_COUNT_")}{" "}
                            <span>-</span>
                          </p>
                        </div>
                      </div>
                    ),
                  },

                  {
                    label: (
                      <div
                        onClick={async () => {
                          this.vehicleOtherInfoStore.timeWisePolylineArray = [];

                          this.vehicleOtherInfoStore.offlineDataNewObj = [];
                          this.vehicleOtherInfoStore.idleAndStopPointsArray =
                            [];
                          this.setState({ selectedIndex: null });
                          this.props.This.clearIntervals();

                          await this.getVehicleHistInfo();

                          await this.getVehicleOffilneInfo(1);

                          await this.showFuelIcons();

                          await this.showPolyline();
                        }}
                      >
                        {this.context.translate("_FUEL_")}
                      </div>
                    ),

                    key: "5",
                    children: (
                      <div className="fuelTab">
                        <div
                          className="white-row total-activity"
                          width="100%"
                          height="100%"
                        >
                          <FuelGraphLivetracking
                            fuelGraphData={this.state.graphData}
                            graphData={this.state.fuelRefillData}
                            selectedFuelData={this.state.selectedData}
                            mapRef={this.props.This.mapRef}
                          />
                        </div>
                        <RangePicker
                          allowClear={false}
                          format="YYYY-MM-DD"
                          size="small"
                          showTime={false}
                          presets={this.rangePresets.items}
                          onChange={this.onRangeChange}
                          style={{ width: "100%" }}
                          defaultValue={[dayjs().startOf("day"), dayjs()]}
                        // defaultValue={[this.state.fromDate,]}
                        />
                        <div className="BlueTitle">
                          {this.context.translate("_REFUELS_")} :&nbsp;
                          {this.state.fuelRefillData
                            ? this.state.fuelRefillData.filter(
                              (val) => val.refillfuel
                            ).length
                            : 0}
                          {/* {this.state.fuelRefillData.length} */}
                        </div>
                        <div style={{ display: "flex", alignItems: "center", top: "10px" }}>

                          <Select
                            placeholder="Interval"
                            style={{top: "10px"}}
                            onChange={async (e) => {
                              await this.setState({ interval: e });
                            }}
                            options={[
                              { value: "15", label: "15 Mins" },
                              { value: "30", label: "30 Mins" },
                              { value: "45", label: "45 Mins" },
                              { value: "60", label: "60 Mins" },
                              { value: "75", label: "75 Mins" },
                              { value: "90", label: "90 Mins" },
                              { value: "105", label: "105 Mins" },
                              { value: "120", label: "120 Mins" },
                            ]}
                          />


                          <Button
                            type="primary"
                            style={{height: "45px", width: "350px",marginLeft: "10px",top: "10px"}}
                            onClick={async () => {
                              console.log("Button clicked", this.state.interval);
                              const data = await this.fuelGraphStore.downloadFuelGraph({
                                companyId: this.props.info.companyId,
                                vehicleId: this.props.info.vehicleId,
                                fromDate: this.state.fromDate,
                                toDate: this.state.toDate,
                                interval: this.state.interval

                                // pageNo: this.state.pageNo,
                              });
                            }}
                          >
                            {this.context.translate("_DOWNLOAD_PDF_")}
                          </Button>


                        </div>
                        <div className="fuel-info-scroll">
                          <div className="fuel-info-block">
                            {this.state.fuelRefillData?.map((val, index) =>
                              val.refillfuel ? (
                                <div
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      this.state.selectedIndex === index
                                        ? "#d0d3d4"
                                        : "white",
                                  }}
                                  className="white-row fuel-content"
                                  onClick={async () => {
                                    this.setState({
                                      selectedIndex: index,
                                      selectedData: val,
                                    });

                                    this.props.This.mapRef.current.setView(
                                      [val.latitude, val.longitude],
                                      16
                                    );
                                  }}
                                >
                                  <p>
                                    <span> Location:</span>{" "}
                                    <small>
                                      {" "}
                                      {val.location ? val.location : "-"}
                                    </small>
                                  </p>

                                  <p>
                                    <span> Date Time:</span>{" "}
                                    <small>
                                      {" "}
                                      {val.timestamp ? val.timestamp : "-"}
                                    </small>
                                  </p>
                                  <p>
                                    <span> Refill Fuel:</span>{" "}
                                    <small>
                                      {val.refillfuel ? val.refillfuel : "-"} L
                                    </small>
                                  </p>
                                  <p>
                                    <span>Before Level:</span>
                                    <small>
                                      {val.beforLevel ? val.beforLevel : "-"} L
                                    </small>
                                  </p>
                                  <p>
                                    <span>Refill Fuel Level:</span>
                                    <small>
                                      {val.refillfuellevel
                                        ? val.refillfuellevel
                                        : "-"}{" "}
                                      L
                                    </small>
                                  </p>
                                </div>
                              ) : null
                            )}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </Card>
        </Draggable>

        {this.state.shareModalVisible && (
          <ShareModalWindow
            shareModalCloseFunc={(resp) => {
              this.setState({ shareModalVisible: resp });
            }}
            shareSingleLocation={this.state.shareModalVisible}
          />
        )}
      </>
    );
  }
}
VehicleOtherInfo.contextType = StoreContext;
export default observer(withRouter(VehicleOtherInfo));
